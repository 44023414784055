define('nypr-publisher-lib/utils/wrap-ajax', ['exports', 'ember-fetch/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (options) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      (0, _ajax.default)(options).done(function (d) {
        return resolve(d);
      }).fail(function (jqXHR) {
        var error = new Error(jqXHR.statusText);
        error.response = jqXHR;
        reject(error);
      });
    });
  };
});