define('nypr-account-settings/components/nypr-account-modal/close-button', ['exports', 'nypr-account-settings/templates/components/nypr-account-modal/close-button'], function (exports, _closeButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Button = Ember.Component.extend({
    tagName: 'button',
    classNames: ['nypr-account-modal-close'],
    attributeBindings: ['data-category', 'data-action', 'data-label', 'data-value'],
    layout: _closeButton.default
  });

  Button.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Button;
});