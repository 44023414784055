define('nypr-ui/components/nypr-tabs', ['exports', 'ivy-tabs/components/ivy-tabs', 'nypr-ui/templates/components/nypr-tabs'], function (exports, _ivyTabs, _nyprTabs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ivyTabs.default.extend({
    layout: _nyprTabs.default,
    classNames: ['nypr-tabs']
  });
});