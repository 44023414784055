define('ember-waypoints/components/way-point', ['exports', 'waypoints'], function (exports, _waypoints) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    contextElement: null,
    offset: null,
    triggerOnce: null,
    continuous: null,
    horizontal: null,

    waypoint: function waypoint(options) {
      if (typeof document === 'undefined') {
        return;
      }
      var waypoint = void 0;

      if (options.sticky) {
        waypoint = new _waypoints.default.Sticky(options);
      } else {
        waypoint = new _waypoints.default(options);
      }

      this.set('_waypoint', waypoint);
    },
    didInsertElement: function didInsertElement() {
      this.waypoint(this.buildOptions());
    },
    willDestroyElement: function willDestroyElement() {
      this.get('_waypoint').destroy();
    },


    buildOptions: function buildOptions() {
      var options = Ember.getProperties(this, ['contextElementId', 'offset', 'triggerOnce', 'continuous', 'horizontal', 'sticky', 'wrapper', 'element', 'stuckClass']);
      options.handler = Ember.run.bind(this, this.waypointTriggered);

      for (var option in options) {
        if (Ember.isNone(options[option])) {
          delete options[option];
        }
      }

      if (options.contextElementId) {
        options.context = document.getElementById(options.contextElementId);
        delete options.contextElementId;
      }

      return options;
    },

    waypointTriggered: function waypointTriggered(direction) {
      this.sendAction('on-' + direction, this); // eslint-disable-line
      this.sendAction('action', direction, this); // eslint-disable-line
    }
  });
});