define('ember-changeset/utils/is-object', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = isObject;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    function isObject(val) {
        return (typeof val === 'undefined' ? 'undefined' : _typeof(val)) === 'object' || Ember.typeOf(val) === 'instance';
    }
});