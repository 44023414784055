define('nypr-ui/components/animated-loading-icon', ['exports', 'nypr-ui/utils/animated-loading-icon', 'nypr-ui/templates/components/animated-loading-icon'], function (exports, _animatedLoadingIcon, _animatedLoadingIcon2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _animatedLoadingIcon2.default,
    classNames: ['animated-loading-icon'],

    width: 68,
    height: 68,
    color: "#FFF",

    /* Not the most intuitive for tweaking, but that's how the thing was built */
    radius: 6, // Radius of the ring before it's exploded.
    // Inner ring gets scaled x 12, outer ring x 24

    dotRadius: 10, // Radius of the middle dot
    lineWidth: 8,
    outerScale: 24,
    innerScale: 12,

    didRender: function didRender() {
      this.loadingIcon = new _animatedLoadingIcon.default(this.$('#loading-anim')[0], this.getProperties('radius', 'width', 'height', 'lineWidth', 'color', 'dotRadius', 'innerScale', 'outerScale'));
      this.animate();
    },
    animate: function animate() {
      var _this = this;

      this.loadingIcon.animate(3500, function () {
        _this.animate();
      });
    }
  });
});