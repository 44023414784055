define('nypr-django-for-ember/services/html-parser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    parse: function parse(string) {
      return this.get('_parser')(string);
    },


    _parser: Ember.computed(function () {
      // Browsers back through IE9 support DOMParser, although not
      // necessarily with html support.
      var parser = new DOMParser();

      // Firefox/Opera/IE throw errors on unsupported types
      try {
        // WebKit returns null on unsupported types
        if (parser.parseFromString("", "text/html")) {
          // text/html parsing is natively supported
          return function (htmlString) {
            return parser.parseFromString(htmlString, 'text/html');
          };
        }
      } catch (ex) {
        // noop
      }

      return function (htmlString) {
        var doc = document.implementation.createHTMLDocument("");
        if (htmlString.toLowerCase().indexOf('<!doctype') > -1) {
          doc.documentElement.innerHTML = htmlString;
        } else {
          doc.body.innerHTML = htmlString;
        }
        return doc;
      };
    })
  });
});