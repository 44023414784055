define('nypr-account-settings/components/nypr-accounts/membership-card/donation-button', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/membership-card/donation-button'], function (exports, _donationButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _donationButton.default,
    pledgePrefix: Ember.computed(function () {
      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration('config:environment'),
          environment = _Ember$getOwner$resol.environment;

      return environment === 'development' ? 'pledge-demo' : 'pledge3';
    })
  });
});