define('nypr-ui/components/support-stripe', ['exports', 'nypr-ui/templates/components/support-stripe'], function (exports, _supportStripe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _supportStripe.default,
    tagName: 'a',
    classNames: ['support-stripe'],
    classNameBindings: ['theme'],
    attributeBindings: ['href', 'target', 'data-action', 'data-label'],
    href: '',
    target: '_blank',

    image: Ember.computed('background', function () {
      var bg = this.background;
      if (bg) {
        return Ember.String.htmlSafe('background-image: url(' + bg + ');');
      }
    })
  });
});