define('ivy-tabs/components/ivy-tabs-tablist', ['exports', 'ivy-tabs/templates/components/ivy-tabs-tablist'], function (exports, _ivyTabsTablist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.UP_ARROW = exports.RIGHT_ARROW = exports.LEFT_ARROW = exports.DOWN_ARROW = undefined;
  var DOWN_ARROW = exports.DOWN_ARROW = 40;
  var LEFT_ARROW = exports.LEFT_ARROW = 37;
  var RIGHT_ARROW = exports.RIGHT_ARROW = 39;
  var UP_ARROW = exports.UP_ARROW = 38;

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabListComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  exports.default = Ember.Component.extend({
    _registerWithTabsContainer: function _registerWithTabsContainer() {
      this.get('tabsContainer').registerTabList(this);
      Ember.run.once(this, this.selectTab);
    },
    _unregisterWithTabsContainer: function _unregisterWithTabsContainer() {
      this.get('tabsContainer').unregisterTabList(this);
    },


    /**
     * The label of the tablist for screenreaders to use.
     *
     * @property aria-label
     * @type String
     * @default ''
     */
    'aria-label': '',

    /**
     * Tells screenreaders to notify the user during DOM modifications.
     *
     * @property aria-live
     * @type String
     * @default 'polite'
     */
    'aria-live': 'polite',

    /**
     * Tells screenreaders that only one tab can be selected at a time.
     *
     * @property aria-multiselectable
     * @type String
     * @default 'false'
     */
    'aria-multiselectable': Ember.computed('isEmpty', function () {
      if (!this.get('isEmpty')) {
        return 'false';
      }
    }).readOnly(),

    /**
     * Tells screenreaders which DOM modification activites to monitor for user
     * notification.
     *
     * @property aria-relevant
     * @type String
     * @default 'all'
     */
    'aria-relevant': 'all',

    /**
     * The `role` attribute of the tab list element.
     *
     * See http://www.w3.org/TR/wai-aria/roles#tablist
     *
     * @property ariaRole
     * @type String
     * @default 'tablist'
     */
    ariaRole: Ember.computed('isEmpty', function () {
      if (!this.get('isEmpty')) {
        return 'tablist';
      } else {
        // FIXME: Ember 3.1.0-beta.1 introduced a bug which does not bind/watch
        // ariaRole changes if it's initially falsy. This sets a non-falsy,
        // "safe" value for ariaRole until it can be a "tablist" when Tabs are
        // added.
        return 'presentation';
      }
    }).readOnly(),

    attributeBindings: ['aria-label', 'aria-live', 'aria-multiselectable', 'aria-relevant'],

    classNames: ['ivy-tabs-tablist'],

    /**
     * Gives focus to the selected tab.
     *
     * @method focusSelectedTab
     */
    focusSelectedTab: function focusSelectedTab() {
      this.get('selectedTab').element.focus();
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.once(this, this._registerWithTabsContainer);
    },


    isEmpty: Ember.computed.empty('tabs'),

    /**
     * Event handler for navigating tabs via arrow keys. The left (or up) arrow
     * selects the previous tab, while the right (or down) arrow selects the next
     * tab.
     *
     * @method keyDown
     * @param {Event} event
     */
    keyDown: function keyDown(event) {
      switch (event.keyCode) {
        case LEFT_ARROW:
        case UP_ARROW:
          this.selectPreviousTab();
          break;
        case RIGHT_ARROW:
        case DOWN_ARROW:
          this.selectNextTab();
          break;
        default:
          return;
      }

      event.preventDefault();
      Ember.run.scheduleOnce('afterRender', this, this.focusSelectedTab);
    },


    layout: _ivyTabsTablist.default,

    /**
     * Adds a tab to the `tabs` array.
     *
     * @method registerTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    registerTab: function registerTab(tab) {
      this.get('tabs').pushObject(tab);
      Ember.run.once(this, this.selectTab);
    },


    /**
     * Selects the next tab in the list, if any.
     *
     * @method selectNextTab
     */
    selectNextTab: function selectNextTab() {
      var selectedTab = this.get('selectedTab');
      var tabs = this.get('tabs');
      var length = tabs.get('length');

      var idx = selectedTab.get('index');
      var tab = void 0;

      do {
        idx++;
        // Next from the last tab should select the first tab.
        if (idx === length) {
          idx = 0;
        }

        tab = tabs.objectAt(idx);
      } while (tab && tab.isDestroying && tab !== selectedTab);

      if (tab) {
        tab.select();
      }
    },


    /**
     * Selects the previous tab in the list, if any.
     *
     * @method selectPreviousTab
     */
    selectPreviousTab: function selectPreviousTab() {
      var selectedTab = this.get('selectedTab');
      var tabs = this.get('tabs');
      var length = tabs.get('length');

      var idx = selectedTab.get('index');
      var tab = void 0;

      do {
        idx--;
        // Previous from the first tab should select the last tab.
        if (idx < 0) {
          idx = length - 1;
        }
        // This would only happen if there are no tabs, so stay at 0.
        if (idx < 0) {
          idx = 0;
        }

        tab = tabs.objectAt(idx);
      } while (tab && tab.isDestroying && tab !== selectedTab);

      if (tab) {
        tab.select();
      }
    },
    selectTab: function selectTab() {
      var selection = this.get('selection');

      if (Ember.isNone(selection) || this.get('tabs.length') === 1) {
        this.selectTabByIndex(0);
      } else {
        this.selectTabByModel(selection);
      }
    },


    /**
     * Select the tab at `index`.
     *
     * @method selectTabByIndex
     * @param {Number} index
     */
    selectTabByIndex: function selectTabByIndex(index) {
      var tab = this.get('tabs').objectAt(index);

      if (tab) {
        tab.select();
      }
    },
    selectTabByModel: function selectTabByModel(model) {
      var tab = this.get('tabs').findBy('model', model);

      if (tab) {
        tab.select();
      }
    },


    /**
     * The currently-selected `ivy-tabs-tab` instance.
     *
     * @property selectedTab
     * @type IvyTabs.IvyTabComponent
     */
    selectedTab: Ember.computed('selection', 'tabs.@each.model', function () {
      return this.get('tabs').findBy('model', this.get('selection'));
    }),

    tabs: Ember.computed(function () {
      return Ember.A();
    }).readOnly(),

    /**
     * The `ivy-tabs` component.
     *
     * @property tabsContainer
     * @type IvyTabs.IvyTabsComponent
     * @default null
     */
    tabsContainer: null,

    /**
     * Removes a tab from the `tabs` array.
     *
     * @method unregisterTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    unregisterTab: function unregisterTab(tab) {
      var index = tab.get('index');

      if (tab.get('isSelected')) {
        if (index === 0) {
          this.selectNextTab();
        } else {
          this.selectPreviousTab();
        }
      }

      this.get('tabs').removeObject(tab);
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      Ember.run.once(this, this._unregisterWithTabsContainer);
    }
  });
});