define('nypr-ui/components/nypr-floating-banner', ['exports', 'nypr-ui/templates/components/nypr-floating-banner'], function (exports, _nyprFloatingBanner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprFloatingBanner.default,
    tagName: '',

    arrow: true
  });
});