define('nypr-ui/components/hero/top/right', ['exports', 'nypr-ui/templates/components/hero/top/right'], function (exports, _right) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _right.default,
    classNames: ['hero-top__right']
  });
});