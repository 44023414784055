define('nypr-publisher-lib/components/nav-links', ['exports', 'ember-get-config', 'nypr-publisher-lib/templates/components/nav-links'], function (exports, _emberGetConfig, _navLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _navLinks.default,
    tagName: 'nav',
    classNames: ['nav-links'],
    classNameBindings: ['xScrollable'],
    didChangeIndex: function didChangeIndex() {},
    parsedLinks: Ember.computed('links', function () {
      var links = Ember.A(Ember.get(this, 'links'));
      return links.map(function (i) {
        if (typeof i.href === 'string' && i.href.indexOf(_emberGetConfig.default.webRoot) === 0) {
          // make sure the parsed path has a leading slash
          i.href = i.href.replace(_emberGetConfig.default.webRoot, '').replace(/^([^/]+)/, '/$1');
          return i;
        } else {
          return i;
        }
      });
    }),
    init: function init() {
      this._super.apply(this, arguments);
      // fallback to null if defaultSlug is undefined because a linkrolLlink without
      // a navSlug key will match on `undefined` in `findBy` below
      var defaultSlug = Ember.get(this, 'defaultSlug') || null;
      var links = Ember.A(Ember.get(this, 'links'));

      this.links = links;

      var defaultIndex = links.indexOf(links.findBy('nav-slug', defaultSlug));
      Ember.set(this, 'activeTabIndex', defaultIndex === -1 ? 0 : defaultIndex);
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, 'handleResize');

      // so we can explicitly remove this at destroy-time
      Ember.set(this, 'boundResizeHandler', Ember.run.bind(this, 'handleResize'));
      Ember.$(window).on('resize', Ember.get(this, 'boundResizeHandler'));
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$(window).off('resize', Ember.get(this, 'boundResizeHandler'));
    },
    handleResize: function handleResize() {
      var list = Array.from(this.$('.nav-links__list-item'));
      var el = this.element;
      var listWidth = list.map(function (n) {
        return Ember.$(n).outerWidth(true);
      }).reduce(function (a, b) {
        return a + b;
      }, 0);

      if (listWidth > el.getBoundingClientRect().width) {
        Ember.set(this, 'xScrollable', true);
      } else {
        Ember.set(this, 'xScrollable', false);
      }
    },


    actions: {
      moveBar: function moveBar(index, slug) {
        var currentIndex = this.get('activeTabIndex', index);
        if (slug && index !== currentIndex) {
          this.set('activeTabIndex', index);
          this.get('didChangeIndex')(index);
        }
      }
    }
  });
});