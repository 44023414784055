define('nypr-ui/components/nypr-svg', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    svgPartial: Ember.computed(function () {
      var icon = this.get('icon');
      return icon ? 'components/nypr-svg/' + icon : false;
    })
  });
});