define('nypr-publisher-lib/serializers/show', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    extractId: function extractId(modelClass, _ref) {
      var attributes = _ref.attributes;
      return attributes.slug;
    }
  });
});