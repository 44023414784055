define('nypr-audio-services/components/player-notification/autoplay-message', ['exports', 'nypr-audio-services/templates/components/player-notification/autoplay-message'], function (exports, _autoplayMessage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _autoplayMessage.default,
    tagName: '',

    duration: null,
    position: null,
    audioType: null,

    remaining: Ember.computed('{duration,position}', function () {
      var difference = Ember.get(this, 'duration') - Ember.get(this, 'position');
      return Math.floor(difference / 1000);
    }),

    store: Ember.inject.service(),
    bumperState: Ember.inject.service(),

    streamEnabled: Ember.computed('bumperState.autoplayPref', function () {
      var pref = Ember.get(this, 'bumperState.autoplayPref');
      return pref === 'default_stream';
    }),

    preferredStream: Ember.computed('bumperState.autoplaySlug', function () {
      var slug = Ember.get(this, 'bumperState.autoplaySlug');
      return Ember.get(this, 'store').peekRecord('stream', slug);
    }),

    timeRemaining: Ember.computed.gte('remaining', 0),
    bumperPlaying: Ember.computed.equal('audioType', 'bumper'),
    preSwitch: Ember.computed.and('bumperPlaying', 'timeRemaining'),
    didAnimate: false,

    notificationMessage: Ember.computed('preSwitch', function () {
      if (Ember.get(this, 'preSwitch')) {
        return Ember.get(this, 'notificationMessagePreSwitch');
      } else {
        return Ember.get(this, 'notificationMessagePostSwitch');
      }
    }),

    notificationMessagePreSwitch: Ember.computed('streamEnabled', 'preferredStream.name', 'remaining', function () {
      var remaining = Ember.get(this, 'remaining');

      if (Ember.get(this, 'streamEnabled')) {
        var streamName = Ember.get(this, 'preferredStream.name');
        return 'Your episode is over. In ' + remaining + ' seconds, we\'ll tune you to ' + streamName + '.';
      } else {
        return 'Your episode is over. In ' + remaining + ' seconds, your audio queue will begin to play.';
      }
    }),

    notificationMessagePostSwitch: Ember.computed('streamEnabled', 'preferredStream.name', function () {
      if (Ember.get(this, 'streamEnabled')) {
        var streamName = Ember.get(this, 'preferredStream.name');
        return 'We tuned you to ' + streamName + ' after your episode ended.';
      } else {
        return 'We began playing your audio queue after your episode ended.';
      }
    })

  });
});