define('nypr-publisher-lib/utils/to-social-links', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (social) {
    return social.filter(function (item) {
      return item['contact-string'] && item['service'];
    }).map(function (item) {
      switch (item['service']) {
        case 'facebook':
          return {
            title: 'facebook',
            href: 'https://www.facebook.com/' + item['contact-string']
          };
        case 'twitter':
          return {
            title: 'twitter',
            href: 'https://twitter.com/' + item['contact-string'].replace('@', '')
          };
        case 'instagram':
          return {
            title: 'instagram',
            href: 'https://www.instagram.com/' + item['contact-string']
          };
        default:
          return {
            title: item['service'],
            href: item['contact-string']
          };
      }
    }).sort(function (a, b) {
      var sortOrder = ["facebook", "twitter", "instagram", "youtube"];
      return sortOrder.indexOf(a.title) - sortOrder.indexOf(b.title);
    });
  };
});