define('nypr-ui/helpers/image-sizes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageSizes = imageSizes;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function imageSizes(_ref) {
    var _ref2 = _toArray(_ref),
        sizes = _ref2.slice(0);

    return sizes.map(function (_ref3) {
      var _ref4 = _slicedToArray(_ref3, 2),
          mq = _ref4[0],
          width = _ref4[1];

      return '(' + mq + ') ' + width + 'px';
    }).join(', ');
  }

  exports.default = Ember.Helper.helper(imageSizes);
});