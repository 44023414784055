define('nypr-ui/components/nypr-social-icons', ['exports', 'nypr-ui/templates/components/nypr-social-icons'], function (exports, _nyprSocialIcons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["nypr-social-icons"],
    layout: _nyprSocialIcons.default
  });
});