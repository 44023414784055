define('nypr-ui/components/support-stripe/label', ['exports', 'nypr-ui/templates/components/support-stripe/label'], function (exports, _label) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Link = Ember.Component.extend({
    layout: _label.default,
    classNames: ['support-stripe__label']
  });

  Link.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Link;
});