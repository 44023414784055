define("nypr-election-countdown/components/election-promo", ["exports", "nypr-election-countdown/templates/components/election-promo"], function (exports, _electionPromo) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _electionPromo.default,
    chunkContent: null,
    chunkSlug: "election-promo",
    classNames: ["election-promo"],
    classNameBindings: ['alreadyShown:hidden'],

    countdownTo: "2018-11-06T00:00:00.000-05:00",
    electionDayEveStart: '2018-11-05T00:00:00.000-05:00',
    electionDayStart: '2018-11-06T00:00:00.000-05:00',
    electionPollsClose: '2018-11-06T21:00:00.000-05:00',
    electionDayEnd: '2018-11-07T00:00:00.000-05:00',

    store: Ember.inject.service(),
    cookies: Ember.inject.service(),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var cookieService = this.get("cookies");
      if (cookieService.read("hasSeenElectionPromo")) {
        this.set("alreadyShown", true);
      }

      this.get('store').findRecord("chunk", this.chunkSlug).then(function (chunkContent) {
        _this.set("chunkContent", chunkContent.get('content'));
      });
    },

    actions: {
      closeModule: function closeModule() {
        var cookieService = this.get("cookies");
        cookieService.write("hasSeenElectionPromo", true, { path: '/' });
        this.set('alreadyShown', true);
      }
    }
  });
});