define('nypr-ui/components/site-chrome/top', ['exports', 'nypr-ui/templates/components/site-chrome/top'], function (exports, _top) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _top.default,
    classNames: ['site-chrome__top']
  });
});