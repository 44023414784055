define('nypr-ui/components/nypr-input', ['exports', 'nypr-ui/templates/components/nypr-input'], function (exports, _nyprInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprInput.default,

    classNames: ['nypr-input-container'],
    classNameBindings: ['hasError'],
    attributeBindings: ['data-action', 'data-label', 'data-category', 'data-value'],
    type: 'text',
    focused: false,
    blurred: false,
    submitted: false,
    showError: Ember.computed.or('blurred', 'submitted'),
    showAdvice: Ember.computed.and('focused', 'clue'),
    hasError: Ember.computed.and('errors', 'showError'),
    showValidMark: Ember.computed('validMark', 'hasError', 'disabled', function () {
      if (!this.get('validMark')) {
        return false;
      }
      return !this.get('hasError') && !this.get('disabled');
    })
  });
});