define('nypr-publisher-lib/components/show-tease', ['exports', 'nypr-publisher-lib/templates/components/show-tease'], function (exports, _showTease) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _showTease.default,
    attributeBindings: ['data-category', 'data-action', 'data-label', 'data-value']
  });
});