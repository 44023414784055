define('nypr-account-settings/components/nypr-accounts/membership-card/giving-history', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/membership-card/giving-history'], function (exports, _givingHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _givingHistory.default,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('orderDateSorting', ["orderDate:desc"]);
    },

    orderDateSorting: null,
    sortedPledges: Ember.computed.sort("pledges", "orderDateSorting"),
    sortedSustainingPledges: Ember.computed.filterBy("sortedPledges", "orderType", "sustainer"),
    sustainingPayments: Ember.computed.filterBy("sortedSustainingPledges", "isPayment", true),
    sortedSustainingPayments: Ember.computed.sort("sustainingPayments", "orderDateSorting"),
    sortedOneTimePledges: Ember.computed.filterBy("sortedPledges", "orderType", "onetime")
  });
});