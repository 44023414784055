define('nypr-ui/components/nypr-brick-item', ['exports', 'nypr-ui/templates/components/nypr-brick-item', 'nypr-ui/helpers/image-template'], function (exports, _nyprBrickItem, _imageTemplate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprBrickItem.default,
    classNames: ['brick__item'],
    classNameBindings: ['item.attributes.template'],
    attributeBindings: ['style'],

    style: Ember.computed.reads('backgroundImage'),
    vertical: Ember.computed.equal('template', 'vertical'),
    backgroundImage: Ember.computed('item.attributes.imageMain.url', function () {
      var imageMain = Ember.get(this, 'item.attributes.imageMain');
      var urlString;
      if (imageMain) {
        // just in case we don't get a template and crop from the API, fallback to url
        if (imageMain.template && imageMain.crop) {
          urlString = (0, _imageTemplate.imageTemplate)([imageMain.template, 800, 0, imageMain.crop]);
        } else {
          urlString = imageMain.url;
        }
        return Ember.String.htmlSafe('background-image: url(' + urlString + ');');
      } else {
        return Ember.String.htmlSafe('');
      }
    })
  });
});