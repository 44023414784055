define('nypr-django-for-ember/services/wnyc-routing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Service.extend({
    router: Ember.inject.service(),
    transitionTo: function transitionTo(routeName, models, queryParams) {
      var _get;

      (_get = this.get('router')).transitionTo.apply(_get, [routeName].concat(_toConsumableArray(models), [queryParams]));
    },
    recognize: function recognize(url) {
      var handlers = this.get('router')._router._routerMicrolib.recognizer.recognize(url);
      var _handlers = handlers,
          queryParams = _handlers.queryParams;

      var routeName = handlers[handlers.length - 1].handler;

      handlers = Array.from(handlers);
      handlers.shift();
      // create an array of all the params found in the url for each route
      var params = handlers.map(function (_ref) {
        var params = _ref.params;
        return Object.values(params);
      }).reduce(function (params, vals) {
        return params.concat(vals);
      }, []);

      return { routeName: routeName, params: params, queryParams: { queryParams: queryParams } };
    }
  });
});