define('nypr-ui/components/nypr-share-button/button', ['exports', 'nypr-ui/templates/components/nypr-share-button/button'], function (exports, _button) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _button.default,
    tagName: 'li',
    classNames: ['nypr-sharebutton-listitem'],

    closePopup: function closePopup() {},
    // passed in from context

    open: function open(href) {
      var _getProperties = this.getProperties('openPopup', 'closePopup', 'type'),
          openPopup = _getProperties.openPopup,
          closePopup = _getProperties.closePopup,
          type = _getProperties.type;

      if (openPopup) {
        openPopup(type, href);
      }
      if (closePopup) {
        closePopup();
      }
    },


    actions: {
      closePopup: function closePopup() {
        if (this.get('closePopup')) {
          this.get('closePopup')();
        }
      }
    }
  });
});