define('nypr-ui/components/hero/blurb', ['exports', 'nypr-ui/templates/components/hero/blurb'], function (exports, _blurb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _blurb.default,
    classNames: ['hero-blurb']
  });
});