define('nypr-publisher-lib/models/bucket', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // BEGIN-SNIPPET bucket-fields
    slug: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    editLink: (0, _attr.default)('string'),
    bucketItems: (0, _attr.default)()
    // END-SNIPPET
  });
});