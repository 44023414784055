define('nypr-publisher-lib/components/social-iconlist', ['exports', 'nypr-publisher-lib/templates/components/social-iconlist'], function (exports, _socialIconlist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _socialIconlist.default,
    tagName: "ul",
    classNames: ["social-iconlist"],
    attributeBindings: ['data-action', 'data-label']
  });
});