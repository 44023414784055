define('nypr-account-settings/validations/nypr-accounts/password-change', ['exports', 'ember-changeset-validations/validators', 'nypr-account-settings/validations/nypr-accounts/custom-messages'], function (exports, _validators, _customMessages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    currentPassword: (0, _validators.validateLength)({ min: 8 }),
    newPassword: [(0, _validators.validateFormat)({ regex: /^(?=[\S]*?[0-9]).{8,}$/, allowBlank: true, message: _customMessages.default.passwordRules }), (0, _validators.validatePresence)({ presence: true, message: _customMessages.default.passwordRequired })]
  };
});