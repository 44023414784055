define('nypr-publisher-lib/components/x-marquee', ['exports', 'nypr-ui/helpers/image-template', 'nypr-publisher-lib/templates/components/x-marquee'], function (exports, _imageTemplate, _xMarquee) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _xMarquee.default,
    classNames: ['marquee'],
    bgColor: Ember.computed.reads('model.bgColor'),
    marquee: Ember.computed.reads('model.marqueeImage'),
    marqueeURL: Ember.computed('marquee', function () {
      var marquee = Ember.get(this, 'marquee');
      if (!marquee) {
        return;
      }
      return (0, _imageTemplate.imageTemplate)([Ember.get(marquee, 'template'), 1200, 200, Ember.get(marquee, 'crop'), 99]);
    }),
    marqueeImageCSS: Ember.computed('bgColor', 'marqueeURL', function () {
      var marqueeURL = Ember.get(this, 'marqueeURL');
      if (!marqueeURL) {
        return;
      }
      var bgColor = Ember.get(this, 'bgColor') || 'white';
      return Ember.String.htmlSafe('background: url(' + marqueeURL + ') no-repeat center center ' + bgColor + ';');
    }),
    marqueeGradientCSS: Ember.computed('bgColor', function () {
      var bgColor = Ember.get(this, 'bgColor') || 'white';
      return Ember.String.htmlSafe('background-image: radial-gradient(rgba(255, 255, 255,0) 200px, ' + bgColor + ' 620px);');
    })
  });
});