define('nypr-ads/services/google-ads', ['exports', 'googletag'], function (exports, _googletag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    doTargeting: function doTargeting(targets) {
      _googletag.default.cmd.push(function () {
        _googletag.default.pubads().setTargeting('url', window.location.pathname);
        _googletag.default.pubads().setTargeting('host', window.location.host);
        _googletag.default.pubads().setTargeting('fullurl', window.location.host + window.location.pathname);

        if (targets) {
          Object.keys(targets).forEach(function (key) {
            if (!Ember.isEmpty(targets[key])) {
              _googletag.default.pubads().setTargeting(key, targets[key]);
            }
          });
        }
      });
    },
    clearTarget: function clearTarget(target) {
      _googletag.default.cmd.push(function () {
        _googletag.default.pubads().clearTargeting(target);
      });
    }
  });
});