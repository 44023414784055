define('nypr-ui/components/site-chrome/body', ['exports', 'nypr-ui/templates/components/site-chrome/body'], function (exports, _body) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _body.default,
    classNames: ['site-chrome__body']
  });
});