define('nypr-publisher-lib/serializers/link-roll', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    extractId: function extractId(modelClass, _ref) {
      var attributes = _ref.attributes;
      return attributes.slug;
    }
  });
});