define('nypr-ui/components/site-chrome/nav/link', ['exports', 'nypr-ui/templates/components/site-chrome/nav/link'], function (exports, _link) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LinkComponent = Ember.Component.extend({
    layout: _link.default,
    tagName: ''
  });

  LinkComponent.reopenClass({
    positionalParams: ['text', 'route']
  });

  exports.default = LinkComponent;
});