define('nypr-publisher-lib/components/stream-playlist', ['exports', 'nypr-publisher-lib/templates/components/stream-playlist'], function (exports, _streamPlaylist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var HOMEPAGES = {
    // These don't seem to be part of an api
    // Putting them here for now since this is the only component that uses them
    'wqxr': 'http://www.wqxr.org',
    'q2': 'http://www.wqxr.org/series/q2/',
    'wqxr-special': 'http://www.wqxr.org/blogs/operavore/',
    'jonathan-channel': 'http://www.wnyc.org/series/jonathan-channel'
  };

  exports.default = Ember.Component.extend({
    layout: _streamPlaylist.default,
    stream: null,
    homepage: Ember.computed('stream', function () {
      return HOMEPAGES[Ember.get(this, 'stream.id')];
    }),
    currentItem: Ember.computed.readOnly('stream.currentPlaylistItem.playlistEntryId'),
    historyItems: Ember.computed('stream.playlist.items.[]', 'currentItem', function () {
      var currentId = this.get('currentItem');
      var playlist = this.get('stream.playlist.items');
      if (!playlist) {
        return [];
      }

      // ember's A does not return an ember array
      var historyItems = Ember.A(playlist.filter(function (i) {
        return Date.now() / 1000 > i.startTimeTs;
      })).rejectBy('playlistEntryId', currentId);

      return Ember.A(historyItems).sortBy('startTimeTs').reverse();
    })
  });
});