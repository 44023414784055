define('nypr-audio-services/components/stream-banner/controls', ['exports', 'nypr-audio-services/templates/components/stream-banner/controls'], function (exports, _controls) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _controls.default,
    classNames: ['stream-banner-controls']
  });
});