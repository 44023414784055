define('nypr-ui/components/hero/image', ['exports', 'nypr-ui/templates/components/hero/image'], function (exports, _image) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _image.default,
    tagName: '',

    didRender: function didRender() {
      this._super.apply(this, arguments);
      var sendImage = this.get('sendImage');
      if (sendImage) {
        sendImage(this.getProperties('src', 'source', 'caption', 'credit'));
      }
    }
  });
});