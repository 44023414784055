define('nypr-publisher-lib/components/appearance-credit', ['exports', 'nypr-publisher-lib/templates/components/appearance-credit'], function (exports, _appearanceCredit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AppearanceCreditComponent = Ember.Component.extend({
    layout: _appearanceCredit.default,
    tagName: ''
  });

  AppearanceCreditComponent.reopenClass({
    positionalParams: ['creditText', 'appearances']
  });

  exports.default = AppearanceCreditComponent;
});