define('nypr-auth/torii-providers/facebook-connect', ['exports', 'torii/providers/facebook-connect'], function (exports, _facebookConnect) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _facebookConnect.default.extend({
    init: function init() {
      if (typeof document === 'undefined') {
        // don't run in fastboot
        return;
      }
      this._super.apply(this, arguments);
    }
  });
});