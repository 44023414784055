define('nypr-player/helpers/numeric-duration', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numericDuration = numericDuration;
  function numericDuration(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(numericDuration);
});