define('nypr-account-settings/components/nypr-account-modal/title', ['exports', 'nypr-account-settings/templates/components/nypr-account-modal/title'], function (exports, _title) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Title = Ember.Component.extend({
    tagName: 'span',
    layout: _title.default
  });

  Title.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Title;
});