define('nypr-ui/components/nypr-brick-grid', ['exports', 'nypr-ui/templates/components/nypr-brick-grid'], function (exports, _nyprBrickGrid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprBrickGrid.default,

    classNames: ['brick-grid']
  });
});