define('nypr-publisher-lib/components/stream-list', ['exports', 'nypr-publisher-lib/templates/components/stream-list'], function (exports, _streamList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _streamList.default,
    tagName: 'ul',
    classNames: ['stream-list']
  });
});