define('nypr-ui/components/hero/top/left', ['exports', 'nypr-ui/templates/components/hero/top/left'], function (exports, _left) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _left.default,
    classNames: ['hero-top__left']
  });
});