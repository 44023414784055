define('nypr-audio-services/components/nypr-player-integration/track-info', ['exports', 'nypr-audio-services/templates/components/nypr-player-integration/track-info', 'ember-diff-attrs'], function (exports, _trackInfo, _emberDiffAttrs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _trackInfo.default,
    tagName: '',

    showTitle: null,
    showUrl: null,

    storyTitle: null,
    storyUrl: null,

    audioId: null,
    songDetails: null,

    didReceiveAttrs: (0, _emberDiffAttrs.default)('showTitle', function (changedAttrs) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, args);
      var isInitialRender = changedAttrs === null;
      var isBumper = this.get('currentSound.metadata.playContext') === 'audio-bumper';

      var showTitleChanged = changedAttrs && changedAttrs.showTitle && changedAttrs.showTitle[0] !== changedAttrs.showTitle[1];

      if (this.get('currentAudio') && isInitialRender || showTitleChanged && !isBumper) {
        if (this.get('titleDidChange')) {
          this.get('titleDidChange')();
        }
      }
    })
  });
});