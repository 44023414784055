define('nypr-ads/services/leaderboard', ['exports', 'googletag'], function (exports, _googletag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    install: function install() {
      var _this = this;

      window.addEventListener('message', this._resizeLeaderboard);
      _googletag.default.cmd.push(function () {
        return _googletag.default.pubads().addEventListener('slotRenderEnded', _this.adSpaceCleanup);
      });
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      window.removeEventListener('message', this._resizeLeaderboard);
    },
    adSpaceCleanup: function adSpaceCleanup(e) {
      if (/leaderboard/.test(e.slot.getAdUnitPath())) {
        if (e.isEmpty) {
          Ember.$('#leaderboard').addClass('is-collapsed');
        } else {
          Ember.$('#leaderboard').removeClass('is-collapsed');
        }
      }
    },
    _resizeLeaderboard: function _resizeLeaderboard(e) {
      var data = void 0;
      var shouldOpen = void 0;
      var $leaderboard = Ember.$('#leaderboard > div > iframe, #leaderboard > div');

      // lots of things on a page can send a postMessage, but not all of that
      // data is parsable as JSON. this prevents nasty console messages.
      try {
        data = JSON.parse(e.data);
      } catch (err) {
        return false;
      }

      // just a little sanity check that we're receiving a message from our ad
      if (!data.wnyc) {
        return false;
      }

      if (data.msg === 'init') {
        Ember.$('#leaderboard').css('max-height', 'none');
        shouldOpen = false;
      } else {
        shouldOpen = data.msg === 'open';
      }

      if ($leaderboard.length !== 1) {
        $leaderboard = Ember.$('#leaderboard > div > iframe, #leaderboard > div');
      }
      $leaderboard.css('max-height', shouldOpen ? 415 : 90);
    }
  });
});