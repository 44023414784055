define('nypr-publisher-lib/components/playlist-credit', ['exports', 'nypr-publisher-lib/templates/components/playlist-credit'], function (exports, _playlistCredit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _playlistCredit.default,
    tagName: 'span',
    unquotedTitle: Ember.computed('musicEntry.title', function () {
      var title = this.get('musicEntry.title');
      if (title) {
        return title.replace(/^"|"$/g, '');
      } else {
        return null;
      }
    }),
    amazonAssociatesId: 'wnycorg-20',
    amazonUrl: Ember.computed('title', function () {
      var asin = this.get('musicEntry.asin');
      var associatesId = this.get('amazonAssociatesId');
      if (asin) {
        return 'http://www.amazon.com/exec/obidos/ASIN/' + asin + '/' + associatesId;
      } else {
        return null;
      }
    })
  });
});