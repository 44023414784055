define('nypr-audio-services/components/text-crawl', ['exports', 'nypr-audio-services/templates/components/text-crawl'], function (exports, _textCrawl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Promise = Ember.RSVP.Promise;
  exports.default = Ember.Component.extend({
    layout: _textCrawl.default,
    classNames: ['text-crawl'],
    classNameBindings: ['isScrolling'],
    didRender: function didRender() {
      var _this = this;

      var watch = this.get('watch');
      Promise.resolve(watch).then(function (watch) {
        return _this.measure(watch);
      });
    },
    measure: function measure(watch) {
      if (this._lastWatch !== watch) {
        this._lastWatch = watch;

        Ember.run.scheduleOnce('afterRender', this, function () {
          var _this2 = this;

          var toScroll = this.$('.text-crawl-scroll');
          var width = toScroll[0].scrollWidth;
          // stop a running animation if there is one
          toScroll.velocity('stop').css('left', 0);

          // sometimes this.$().width will come out as a 591.65525 px and
          // width will be 592. We don't need to scroll in that case.

          if (width > Math.ceil(this.$().width())) {
            this.set('isScrolling', true);

            toScroll.velocity({ left: ['-' + (width - 50) + 'px', 0] }, { duration: 4500, delay: 750 }).velocity({ left: 0 }, { duration: 500,
              complete: Ember.run.bind(this, function () {
                if (_this2.isDestroyed || _this2.isDestroying) {
                  return;
                }
                _this2.set('isScrolling', false);
              })
            });
          }
        });
      }
    }
  });
});