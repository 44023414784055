define('nypr-ui/components/nypr-ui/brand-header/header', ['exports', 'nypr-ui/templates/components/nypr-ui/brand-header/header'], function (exports, _header) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _header.default,

    tagName: 'header',
    classNames: ['nypr-brand-header']
  });
});