define('nypr-publisher-lib/models/image', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // BEGIN-SNIPPET image-fields
    caption: _emberData.default.attr('string'),
    creditsUrl: _emberData.default.attr('string'),
    creditsName: _emberData.default.attr('string'),
    crop: _emberData.default.attr('string'),
    h: _emberData.default.attr('number'),
    isDisplay: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    template: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    w: _emberData.default.attr('number')
    // END-SNIPPET
  });
});