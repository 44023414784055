define('nypr-ui/components/nypr-loading-templates', ['exports', 'nypr-ui/templates/components/nypr-loading-templates'], function (exports, _nyprLoadingTemplates) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprLoadingTemplates.default,
    classNames: ['l-full'],
    classNameBindings: ['cssOverrides'],
    attributeBindings: ['style'],
    pageTemplate: Ember.computed('type', function () {
      var type = this.get('type');
      return 'components/nypr-loading-templates/' + type;
    }),
    cssOverrides: Ember.computed('type', function () {
      var type = this.get('type');
      return type + '-loading';
    }),
    didRender: function didRender() {
      if (!Ember.testing && this.get('type') !== 'channel-page') {
        window.scrollTo(0, 0);
      }
    }
  });
});