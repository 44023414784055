define('nypr-publisher-lib/adapters/chunk', ['exports', 'ember-data', 'ember-get-config', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _emberGetConfig, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v3',
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url + '/';
    }
  });
});