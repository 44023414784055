define('nypr-ui/components/site-chrome/top/button', ['exports', 'nypr-ui/templates/components/site-chrome/top/button'], function (exports, _button) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _button.default,
    tagName: 'button',
    classNames: ['site-chrome__top-button']
  });
});