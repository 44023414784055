define('nypr-publisher-lib/models/api-response', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // BEGIN-SNIPPET api-response-fields
    teaseList: _emberData.default.hasMany('bucketItem', { async: false, polymorphic: true, inverse: null }),
    // ^inverse:null here because bucketItem children don't keep track of their containers.
    story: _emberData.default.belongsTo('story', { async: false }),
    appearances: Ember.computed.readOnly('appearance'),
    appearance: _emberData.default.hasMany('appearance', { async: false }),
    aboutPage: _emberData.default.belongsTo('about-page', { async: false }),
    contentType: Ember.computed(function () {
      var teaseList = Ember.get(this, 'teaseList.length');
      var appearances = Ember.get(this, 'appearances.length');
      var story = Ember.get(this, 'story');
      var id = Ember.get(this, 'id');

      if (teaseList) {
        return 'story-list';
      }

      if (/\/about\/1/.test(id)) {
        return 'about-page';
      }

      if (story) {
        return 'story-detail';
      }

      if (appearances) {
        return 'appearance-list';
      }
    }),
    page: Ember.computed('contentType', function () {
      if (['story-detail', 'about-page'].includes(this.get('contentType'))) {
        return this.store.createRecord('django-page', {
          text: this.get('aboutPage.escapedBody') || this.get('story.escapedBody')
        });
      }
    }),
    totalCount: _emberData.default.attr('number'),
    totalPages: _emberData.default.attr('number'),
    pageSize: _emberData.default.attr('number')
    // END-SNIPPET
  });
});