define('nypr-player/components/nypr-player/time-label', ['exports', 'nypr-player/templates/components/nypr-player/time-label'], function (exports, _timeLabel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _timeLabel.default,
    classNames: ['nypr-player-timelabel'],
    tagName: 'span',
    position: 0,
    duration: 0
  });
});