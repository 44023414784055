define('nypr-audio-services/services/listen-queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    actionQueue: Ember.inject.service(),
    hifi: Ember.inject.service(),
    dj: Ember.inject.service(),
    items: Ember.computed.reads('session.data.queue'),
    isPlayingFromQueue: Ember.computed.match('hifi.currentSound.metadata.playContext', /queue/),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('pending', []);
      var actionQueue = Ember.get(this, 'actionQueue');
      var hifi = Ember.get(this, 'hifi');

      actionQueue.addAction(hifi, 'audio-ended', { priority: 2, name: 'queue' }, Ember.run.bind(this, this.onTrackFinished));

      hifi.on('audio-played', function (sound) {
        var playContext = Ember.get(sound, 'metadata.playContext');
        if (/queue/.test(playContext)) {
          _this.removeFromQueueById(Ember.get(sound, 'metadata.contentId'));
        }
      });
    },
    onTrackFinished: function onTrackFinished(sound) {
      if (/queue/.test(Ember.get(sound, 'metadata.playContext'))) {
        var nextItem = this.nextItem();
        if (nextItem) {
          Ember.get(this, 'dj').play(nextItem, { playContext: 'queue' });
          return true; // stop the following action queues from running
        }
      }
    },
    findRecord: function findRecord(id) {
      return Ember.get(this, 'store').findRecord('story', id);
    },
    addToQueueById: function addToQueueById(id) {
      var _this2 = this;

      var pending = this.get('pending');
      pending.push(id);

      var findPromise = this.findRecord(id);
      findPromise.then(function (story) {
        if (!pending.includes(id)) {
          // story was removed from the queue before it could be resolved
          return;
        } else {
          _this2._removePending(id);
        }
        var session = Ember.get(_this2, 'session');
        var queue = Ember.A(session.getWithDefault('data.queue', []).slice());

        queue.pushObject(story);
        session.set('data.queue', queue);

        return story;
      });

      return findPromise;
    },
    removeFromQueueById: function removeFromQueueById(id) {
      var session = Ember.get(this, 'session');
      var queue = Ember.A(session.getWithDefault('data.queue', []));
      var newQueue = Ember.A(queue.rejectBy('id', id));

      this._removePending(id);

      if (newQueue.length !== queue.length) {
        session.set('data.queue', newQueue);
      }
    },
    reset: function reset(newQueue) {
      var session = Ember.get(this, 'session');
      session.set('data.queue', newQueue);
    },
    nextItem: function nextItem() {
      var items = this.get('items') || [];

      if (items.length > 0) {
        return Ember.get(items, 'firstObject');
      } else {
        return null;
      }
    },
    _removePending: function _removePending(id) {
      var pending = Ember.A(this.get('pending'));
      var pendingIndex = pending.indexOf(id);
      if (pendingIndex !== -1) {
        pending.removeAt(pendingIndex);
      }
    }
  });
});