define('nypr-player/components/nypr-player/backdrop', ['exports', 'nypr-player/templates/components/nypr-player/backdrop'], function (exports, _backdrop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _backdrop.default,
    classNames: ['nypr-player-backdrop']
  });
});