define('nypr-account-settings/components/nypr-account-forms/validate', ['exports', 'nypr-account-settings/templates/components/nypr-account-forms/validate', 'ember-changeset', 'ember-changeset-validations', 'nypr-account-settings/validations/nypr-accounts/email', 'fetch', 'nypr-account-settings/utils/fetch-utils'], function (exports, _validate, _emberChangeset, _emberChangesetValidations, _email, _fetch, _fetchUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FLASH_MESSAGES = {
    validated: 'Your email has been verified and your online account is now active.'
  };

  exports.default = Ember.Component.extend({
    layout: _validate.default,
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    authAPI: null,
    accountValidated: false,
    codeExpired: false,
    allowedKeys: null,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'fields', {
        email: ''
      });
      Ember.set(this, 'allowedKeys', ['email']);
      Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'fields'), (0, _emberChangesetValidations.default)(_email.default), _email.default));
      Ember.get(this, 'changeset').validate();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.validateNewAccount(Ember.get(this, 'username'), Ember.get(this, 'confirmation')).then(function () {
        Ember.set(_this, 'accountValidated', true);
        _this.showFlash('validated');
      }).catch(function (e) {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        if (Ember.get(e, 'errors.code') === "AliasExistsException") {
          // Account was already validated
          Ember.set(_this, 'accountValidated', true);
          _this.showFlash('validated');
        } else {
          Ember.set(_this, 'codeExpired', true);
        }
      });
    },
    validateNewAccount: function validateNewAccount(username, confirmation) {
      var url = Ember.get(this, 'authAPI') + '/v1/confirm/sign-up?confirmation=' + confirmation + '&username=' + username;
      var method = 'GET';
      var mode = 'cors';
      return (0, _fetch.default)(url, { method: method, mode: mode }).then(_fetchUtils.rejectUnsuccessfulResponses);
    },
    resendValidationEmail: function resendValidationEmail(email) {
      var url = Ember.get(this, 'authAPI') + '/v1/confirm/resend?email=' + email;
      var method = 'GET';
      var mode = 'cors';
      return (0, _fetch.default)(url, { method: method, mode: mode }).then(_fetchUtils.rejectUnsuccessfulResponses);
    },
    showFlash: function showFlash(type) {
      this.get('flashMessages').add({
        message: FLASH_MESSAGES[type],
        type: 'success'
      });
    },

    actions: {
      // Form for resending the validation email
      onSubmit: function onSubmit() {
        return this.resendValidationEmail(Ember.get(this, 'fields.email'));
      },
      onFailure: function onFailure() {
        var changeset = Ember.get(this, 'changeset');
        changeset.validate('email');
        changeset.pushErrors('email', 'There was a problem sending a confirmation email to that address.');
      }
    }
  });
});