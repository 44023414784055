define('nypr-publisher-lib/adapters/playlist', ['exports', 'ember-get-config', 'ember-data', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberGetConfig, _emberData, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v1',
    pathForType: function pathForType() {
      return 'stream_playlist';
    },
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '/';
    }
  });
});