define('nypr-django-for-ember/services/script-loader', ['exports', 'fetch', 'nypr-django-for-ember/utils/compat-hooks', 'ember-get-config'], function (exports, _fetch, _compatHooks, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.canonicalize = canonicalize;
  exports.default = Ember.Service.extend({
    asyncWriter: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.stack = [];
    },
    load: function load(scriptTags, containerElement) {
      var sources = Array.from(scriptTags).map(function (tag) {
        return loadSource(tag).then(function (src) {
          return {
            src: src,
            tag: tag
          };
        });
      });

      this.stack.unshift({ sources: sources, containerElement: containerElement });
      if (this.stack.length === 1) {
        this._evalNext();
      }
    },
    _evalNext: function _evalNext() {
      var _this = this;

      if (this.stack.length === 0) {
        return Ember.RSVP.Promise.resolve();
      }

      var _stack$ = this.stack[0],
          sources = _stack$.sources,
          containerElement = _stack$.containerElement;


      if (sources.length === 0) {
        this.stack.shift();
        return this._evalNext();
      }

      var asyncWriter = this.get('asyncWriter');

      return sources.shift().then(function (_ref) {
        var src = _ref.src,
            tag = _ref.tag;

        var postMangled = (0, _compatHooks.mangleJavascript)(tag, src);
        if (postMangled) {
          var script = document.createElement('script');
          var cursor = placeholderFor(tag);
          script.textContent = postMangled;
          script.type = 'text/javascript';
          // Since we have already preloaded and inlined the source,
          // these appended scripts will run synchronously.
          // If there's a cursor for this script, use that so it can be added
          // to the same spot in the DOM it is expecting.
          if (cursor) {
            asyncWriter.cursorTo(cursor);
            cursor.parentNode.insertBefore(script, cursor);
          } else {
            containerElement.appendChild(script);
          }

          // Make sure any document.writes get their place at the head
          // of the stack before we move on
          asyncWriter.flush();
        }
      }).finally(function () {
        return _this._evalNext();
      });
    }
  });
  function canonicalize(url) {
    return new URL(url, location.href).toString();
  }

  // In order to fetch all the scripts via XHR without tripping CORs
  // violations, we are proxying them through our own server.
  function scriptURL(tag) {
    var origin = canonicalize(_emberGetConfig.default.webRoot);
    var url = canonicalize(tag.attributes.src.value);
    if (url.indexOf(origin) === 0) {
      return url;
    } else {
      return _emberGetConfig.default.publisherAPI + '/v1/dynamic-script-loader/?url=' + encodeURIComponent(canonicalize(url));
    }
  }

  function loadSource(scriptTag) {
    if (scriptTag.hasAttribute('src')) {
      return (0, _fetch.default)(scriptURL(scriptTag)).then(function (response) {
        return response.text();
      });
    } else {
      return Ember.RSVP.Promise.resolve(scriptTag.textContent);
    }
  }

  function placeholderFor(tag) {
    if (!tag.hasAttribute('data-script-id')) {
      return null;
    }
    var id = tag.getAttribute('data-script-id');
    return document.querySelector('[data-script-id="' + id + '"]');
  }
});