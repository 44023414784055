define('nypr-publisher-lib/models/show', ['exports', 'ember-data', 'nypr-publisher-lib/models/channel'], function (exports, _emberData, _channel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _channel.default.extend({
    // BEGIN-SNIPPET show-fields
    image: _emberData.default.belongsTo('image'),
    producingOrganizations: _emberData.default.hasMany('producing-organization'),
    contactEmail: _emberData.default.attr('string')
    // END-SNIPPET
  });
});