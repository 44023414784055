define('ember-component-css/initializers/route-styles', ['exports', 'ember-component-css/utils/init-route-styles'], function (exports, _initRouteStyles) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // This file is removed from the build in Ember 3.6+
  Ember.Router.reopen({
    didTransition: function didTransition(routes) {
      this._super.apply(this, arguments);
      (0, _initRouteStyles.default)(Ember.getOwner(this), routes);
    }
  });

  function initialize() {}

  exports.default = {
    initialize: initialize
  };
});