define("nypr-ui/helpers/url-encode", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.urlEncode = urlEncode;
  function urlEncode(s) {
    return encodeURIComponent(s).replace(/'/g, "%27").replace(/"/g, "%22");
  }

  exports.default = Ember.Helper.helper(urlEncode);
});