define('nypr-audio-services/components/queue-button/embedded', ['exports', 'nypr-audio-services/components/queue-button'], function (exports, _queueButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function wnycEmbeddedAttr() {
    return Ember.computed('embeddedAttrs', {
      get: function get(k) {
        return Ember.get(this, 'embeddedAttrs.' + k);
      },
      set: function set(k, v) {
        return v;
      }
    });
  }

  exports.default = _queueButton.default.extend({
    itemPK: wnycEmbeddedAttr(),
    itemTitle: wnycEmbeddedAttr(),
    itemShow: wnycEmbeddedAttr(),
    type: wnycEmbeddedAttr(),
    playContext: wnycEmbeddedAttr()
  });
});