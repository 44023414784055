define('nypr-ui/components/buttons/red', ['exports', 'nypr-ui/templates/components/buttons/red'], function (exports, _red) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _red.default,
    tagName: 'button',
    classNames: ['nypr-ui__button-red'],
    classNameBindings: ['tall'],
    attributeBindings: ['disabled', 'href', 'data-category', 'data-action', 'data-label', 'data-value']
  });
});