define('nypr-publisher-lib/components/download-link', ['exports', 'nypr-publisher-lib/templates/components/download-link'], function (exports, _downloadLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _downloadLink.default,
    story: null,
    icon: 'download-arrow',
    linkText: 'Download',
    shouldHide: Ember.computed.alias('deviceIsIos'),

    didInsertElement: function didInsertElement() {
      var isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      this.set('deviceIsIos', isIos);
    }
  });
});