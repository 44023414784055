define("ember-changeset/utils/wrap", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = wrapInArray;
    /**
     * Wraps a value in an Ember.Array.
     *
     * @public
     * @param  {Any} value
     * @return {Ember.Array}
     */
    function wrapInArray(value) {
        if (Array.isArray(value)) {
            return value;
        }
        return [value];
    }
});