define('ember-query-method/mixins/query-method', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    targetController: 'application',
    factory: '',
    method: '',

    onDidTransition: function onDidTransition() {
      var factory = Ember.getOwner(this).lookup(Ember.get(this, 'factory'));
      factory[Ember.get(this, 'method')].apply(factory, arguments);
    },
    onWillTransition: function onWillTransition() {
      var method = Ember.get(this, 'method');
      this.controllerFor(this.get('targetController')).set(method, null);
    },


    // check return value of super objects and respect if they don't
    //  want the event to bubble
    actions: {
      didTransition: function didTransition() {
        var methodKey = Ember.get(this, 'method');
        var targetController = this.controllerFor(this.get('targetController'));

        // if methodKey is left as an empty string, this look up will return the controller itself, which is not what we want
        if (methodKey) {
          var methodArg = targetController.get(methodKey);
          if (methodArg) {
            Ember.run.bind(this, Ember.get(this, 'onDidTransition'))(methodArg);
          }
        }

        var ret = this._super.apply(this, arguments);
        return ret === false ? ret : true;
      },
      willTransition: function willTransition() {
        Ember.run.bind(this, Ember.get(this, 'onWillTransition'))();

        var ret = this._super.apply(this, arguments);
        return ret === false ? ret : true;
      }
    }
  });
});