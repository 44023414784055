define('nypr-account-settings/components/nypr-account-modal/header', ['exports', 'nypr-account-settings/templates/components/nypr-account-modal/header'], function (exports, _header) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['nypr-account-modal-title'],
    layout: _header.default,

    closeAction: function closeAction() {}
  });
});