define('nypr-account-settings/components/nypr-account-forms/forgot', ['exports', 'nypr-account-settings/templates/components/nypr-account-forms/forgot', 'ember-changeset', 'ember-changeset-validations', 'nypr-account-settings/validations/nypr-accounts/email', 'fetch', 'nypr-account-settings/utils/fetch-utils'], function (exports, _forgot, _emberChangeset, _emberChangesetValidations, _email, _fetch, _fetchUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _forgot.default,
    authAPI: null,
    triedUnverifiedAccount: false,
    resendUrl: Ember.computed('authAPI', function () {
      return Ember.get(this, 'authAPI') + '/v1/password/forgot';
    }),
    allowedKeys: null,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'fields', {
        email: ''
      });
      Ember.set(this, 'allowedKeys', ['email']);
      Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'fields'), (0, _emberChangesetValidations.default)(_email.default), _email.default));
      Ember.get(this, 'changeset').validate();
    },

    actions: {
      onSubmit: function onSubmit() {
        return this.requestPasswordResetEmail(Ember.get(this, 'fields.email'));
      },
      onFailure: function onFailure(e) {
        if (e) {
          if (Ember.get(e, 'errors.code') === 'UserNotConfirmedException') {
            Ember.set(this, 'triedUnverifiedAccount', true);
          } else {
            this.applyErrorToChangeset(e.errors, Ember.get(this, 'changeset'));
          }
        }
      }
    },
    requestPasswordResetEmail: function requestPasswordResetEmail(email) {
      var url = Ember.get(this, 'authAPI') + '/v1/password/forgot?email=' + email;
      var method = 'GET';
      var mode = 'cors';
      return (0, _fetch.default)(url, { method: method, mode: mode }).then(_fetchUtils.rejectUnsuccessfulResponses);
    },
    applyErrorToChangeset: function applyErrorToChangeset(error, changeset) {
      if (error) {
        if (error.code === "UserNotFoundException") {
          changeset.validate('email');
          changeset.pushErrors('email', 'We cannot find an account for the email ' + changeset.get('email') + '. <a href="/signup">Sign up?</a>');
        }
      }
    }
  });
});