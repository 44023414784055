define('nypr-publisher-lib/adapters/channel', ['exports', 'ember-data', 'ember-get-config', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _emberGetConfig, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v3',
    pathForType: function pathForType() {
      return 'channel';
    },
    ajaxOptions: function ajaxOptions(urlToDecode, type) {
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      options = this._super.apply(this, arguments);
      var url = decodeURIComponent(urlToDecode);

      return Ember.assign(options, {
        url: url.slice(-1) === '/' ? url + '/' : url
      });
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var baseUrl = this.buildURL(modelName, id, snapshot);
      if (snapshot.adapterOptions) {
        var limit = snapshot.adapterOptions.limit;
        if (limit) {
          baseUrl += '?limit=' + limit;
        }
      }
      return baseUrl;
    }
  });
});