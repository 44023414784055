define('nypr-ui/helpers/link-or-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.linkOrText = linkOrText;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function linkOrText(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        target = _ref3[0];

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$textKey = _ref2.textKey,
        textKey = _ref2$textKey === undefined ? 'name' : _ref2$textKey,
        _ref2$urlKey = _ref2.urlKey,
        urlKey = _ref2$urlKey === undefined ? 'url' : _ref2$urlKey;

    if (!target) {
      return '';
    } else if (typeof target === 'string') {
      return target;
    }

    var text = Ember.get(target, textKey);
    var url = Ember.get(target, urlKey);
    if (url) {
      return '<a href="' + url + '">' + text + '</a>';
    } else {
      return text;
    }
  }

  exports.default = Ember.Helper.helper(linkOrText);
});