define('nypr-ui/mixins/image-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didRender: function didRender() {
      this.$().imagesLoaded().progress(function (i, image) {
        Ember.run(function () {
          image.img.classList.add('is-loaded');
        });
      });
    }
  });
});