define('nypr-publisher-lib/components/story-list', ['exports', 'ember-get-config', 'nypr-publisher-lib/templates/components/story-list'], function (exports, _emberGetConfig, _storyList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _storyList.default,
    classNames: ['story-list'],
    session: Ember.inject.service(),
    tagName: 'section',
    adminURL: _emberGetConfig.default.adminRoot + '/admin'
  });
});