define('nypr-audio-services/services/listen-history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    items: Ember.computed.readOnly('session.data.listens'),
    hifi: Ember.inject.service(),

    init: function init() {
      this.listenForTrackChanges();
      this._super.apply(this, arguments);
    },
    listenForTrackChanges: function listenForTrackChanges() {
      var _this = this;

      this.get('hifi').on('current-sound-changed', function (currentSound) {
        var contentModelType = Ember.get(currentSound, 'metadata.contentModelType');
        var contentModel = Ember.get(currentSound, 'metadata.contentModel');

        if (contentModel && contentModelType === 'story') {
          _this.addListen(contentModel);
        }
      });
    },
    addListen: function addListen(story) {
      var session = this.get('session');
      var listens = Ember.A(session.getWithDefault('data.listens', []).slice());

      var listen = {
        id: 'listen' + Date.now() + '-' + (Math.random() * 100).toFixed(),
        story: story
      };

      listens.unshiftObject(listen);
      session.set('data.listens', listens);
    },
    removeListenByListenId: function removeListenByListenId(id) {
      var session = this.get('session');
      var listens = Ember.A(session.getWithDefault('data.listens', []).slice());

      var listen = listens.findBy('id', id);
      listens.removeObject(listen);
      session.set('data.listens', listens);
    },
    removeListenByStoryPk: function removeListenByStoryPk(pk) {
      var session = this.get('session');
      var listens = Ember.A(session.getWithDefault('data.listens', []).slice());

      var listen = listens.findBy('story.id', pk);
      listens.removeObject(listen);
      session.set('data.listens', listens);
    },
    clearHistory: function clearHistory() {
      var session = this.get('session');
      session.set('data.listens', []);
    },
    hasListenedTo: function hasListenedTo(id) {
      return this.historyFor(id).length > 0;
    },
    historyFor: function historyFor(id) {
      var session = this.get('session');
      var listens = Ember.A(session.getWithDefault('data.listens', []));
      return listens.filterBy('story.id', id);
    },
    indexByStoryPk: function indexByStoryPk(pk) {
      var session = this.get('session');
      var listens = Ember.A(session.getWithDefault('data.listens', []));
      var listen = listens.findBy('story.id', pk);

      return listens.indexOf(listen);
    }
  });
});