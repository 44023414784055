define('nypr-ui/mixins/sticky-header', ['exports', 'waypoints'], function (exports, _waypoints) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('sticky')) {
        (false && !(_waypoints.default.Sticky) && Ember.assert("Waypoint.Sticky is not defined. Did you include `useWaypoints: true` in your application config?", _waypoints.default.Sticky));

        var waypoint = new _waypoints.default.Sticky({
          element: this.element,
          offset: this.get('offset')
        });
        this.set('waypoint', waypoint);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.get('sticky')) {
        this.get('waypoint').destroy();
      }
    },
    refresh: function refresh() {
      _waypoints.default.refreshAll();
    }
  });
});