define('nypr-player/components/content-for', ['exports', 'nypr-player/templates/components/content-for'], function (exports, _contentFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ContentFor = Ember.Component.extend({
    layout: _contentFor.default,
    tagName: '',

    hasContentForYield: Ember.computed('contentName', 'yieldName', function () {
      return this.get('contentName') === this.get('yieldName');
    }),

    yieldContent: Ember.computed('hasContentForYield', 'yieldArguments', function () {
      if (this.get('hasContentForYield')) {
        return this.get('yieldArguments');
      }
    })
  });

  ContentFor.reopenClass({
    positionalParams: ['contentName']
  });

  exports.default = ContentFor;
});