define('nypr-publisher-lib/components/social-button', ['exports', 'nypr-publisher-lib/templates/components/social-button'], function (exports, _socialButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _socialButton.default,
    tagName: '',
    isTwitter: Ember.computed.equal('item.service', 'twitter'),
    iconname: Ember.computed('item.service', function () {
      var svc = Ember.get(this, 'item.service');
      return svc === 'email' ? 'envelope' : svc;
    }),
    url: Ember.computed('item.{service,contactString}', function () {
      var svc = Ember.get(this, 'item.service');
      var user = Ember.get(this, 'item.contactString');

      switch (svc) {
        case 'facebook':
          return 'http://facebook.com/' + user;
        case 'instagram':
          return 'http://instagram.com/' + user;
        case 'email':
          return 'mailto:' + user;
        default:
          return null;
      }
    }),

    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'isTwitter')) {
        this._insertTwitter();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.get('_script').remove();
    },
    _insertTwitter: function _insertTwitter() {
      var $s = Ember.$('<script/>', { id: 'twitter-wjs', src: '//platform.twitter.com/widgets.js' });
      Ember.$(document).find('head').prepend($s);
      this.set('_script', $s);
    }
  });
});