define('nypr-account-settings/components/nypr-account-forms/signup', ['exports', 'nypr-account-settings/templates/components/nypr-account-forms/signup', 'ember-changeset', 'nypr-account-settings/validations/nypr-accounts/signup', 'nypr-account-settings/validations/nypr-accounts/custom-messages', 'ember-changeset-validations', 'fetch', 'nypr-account-settings/utils/fetch-utils', 'ember-get-config'], function (exports, _signup, _emberChangeset, _signup2, _customMessages, _emberChangesetValidations, _fetch, _fetchUtils, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _signup.default,
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    showSocialSignup: false,
    authApi: null,
    session: Ember.inject.service(),
    allowedKeys: null,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'newUser', this.get('store').createRecord('user', {
        'givenName': Ember.get(this, 'givenName'),
        'familyName': Ember.get(this, 'familyName')
      }));
      Ember.set(this, 'allowedKeys', ['email', 'emailConfirmation', 'givenName', 'familyName', 'typedPassword']);
      Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'newUser'), (0, _emberChangesetValidations.default)(_signup2.default), _signup2.default));
      Ember.get(this, 'changeset').validate();
      Ember.set(this, 'changeset.email', Ember.get(this, 'email'));
      Ember.set(this, 'changeset.emailConfirmation', Ember.get(this, 'email'));

      // Ignore the captcha during testing
      if (_emberGetConfig.default.environment === "test") {
        Ember.set(this, 'newUser.captchaKey', "test_captcha_key");
      }
    },
    click: function click() {
      Ember.get(this, 'flashMessages').clearMessages();
    },

    actions: {
      onSubmit: function onSubmit() {
        Ember.set(this, 'signupError', null);
        return this.signUp();
      },
      onFailure: function onFailure(e) {
        if (e) {
          if (e.errors && e.errors.code === 'UserNoPassword') {
            Ember.set(this, 'signupError', _customMessages.default.signupNoPassword);
          } else if (e.errors && e.errors.code === 'BadAccess') {
            Ember.set(this, 'signupError', _customMessages.default.badAccess);
          } else {
            this.applyErrorToChangeset(e.errors, Ember.get(this, 'changeset'));
          }
        }
      },
      signupWithFacebook: function signupWithFacebook() {
        var _this = this;

        var authOptions = {};
        if (Ember.get(this, 'emailWasDeclined')) {
          //we need to send this option with the fb login request to re-request the email permission
          authOptions = { authType: 'rerequest' };
          //reset emailWasDeclined on a new attempt
          Ember.set(this, 'emailWasDeclined', false);
        }
        Ember.get(this, 'session').authenticate('authenticator:torii', 'facebook-connect', authOptions).catch(function (e) {
          if (e && Ember.get(e, 'errors.code') === 'MissingAttributeException') {
            Ember.set(_this, 'emailWasDeclined', true);
            return _this.onFacebookLoginFailure(_customMessages.default.socialAuthNoEmail);
          } else {
            return _this.onFacebookLoginFailure(_customMessages.default.socialAuthCancelled);
          }
        });
      },
      hasCompletedCaptcha: function hasCompletedCaptcha(reCaptchaResponse) {
        Ember.set(this, 'newUser.captchaKey', reCaptchaResponse);
      }
    },
    onFacebookLoginFailure: function onFacebookLoginFailure(message) {
      var _this2 = this;

      // because we clear flash messages when clicking this form,
      // wait a tick when we add one in an action that can
      // be triggered with a click
      Ember.run.next(function () {
        if (message) {
          _this2.get('flashMessages').add({
            message: message,
            type: 'warning'
          });
        }
      });
    },
    signUp: function signUp() {
      return Ember.get(this, 'newUser').save();
    },
    onEmailInput: function onEmailInput() {
      if (Ember.get(this, 'changeset.emailConfirmation')) {
        Ember.get(this, 'changeset').validate('emailConfirmation');
      }
    },
    applyErrorToChangeset: function applyErrorToChangeset(error, changeset) {
      if (error) {
        changeset.rollback(); // so errors don't stack up
        if (error.code === "AccountExists") {
          changeset.validate('email');
          changeset.pushErrors('email', _customMessages.default.signupAccountExists(changeset.get('email')));
        } else if (error.message === 'User is disabled') {
          changeset.pushErrors('email', _customMessages.default.userDisabled);
        }
      }
    },
    resendConfirmationEmail: function resendConfirmationEmail(email) {
      var url = Ember.get(this, 'authAPI') + '/v1/confirm/resend?email=' + email;
      var method = 'GET';
      var mode = 'cors';
      return (0, _fetch.default)(url, { method: method, mode: mode }).then(_fetchUtils.rejectUnsuccessfulResponses);
    }
  });
});