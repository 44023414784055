define('nypr-audio-services/components/player-queue', ['exports', 'nypr-audio-services/templates/components/player-queue'], function (exports, _playerQueue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _playerQueue.default,
    dj: Ember.inject.service(),
    queue: Ember.inject.service('listen-queue'),
    isSortingEnabled: true,
    playingFromQueue: Ember.computed.reads('queue.isPlayingFromQueue'),

    classNames: ['player-queue'],

    sortHandle: Ember.computed('isSortingEnabled', function () {
      if (Ember.get(this, 'isSortingEnabled')) {
        return '.queueitem';
      }
      return '.dontdrag';
    }),

    didInsertElement: function didInsertElement() {
      // if this is a touchscreen, disable dragging until we
      // implement hold-to-drag, because dragging blocks
      // scrolling on touch screens.

      /*globals DocumentTouch*/
      var isTouchScreen = 'ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch;
      if (isTouchScreen) {
        Ember.set(this, 'isSortingEnabled', false);
      }
    },


    actions: {
      removeFromQueue: function removeFromQueue(id) {
        Ember.get(this, 'queue').removeFromQueueById(id);
      },
      reorderItems: function reorderItems(reorderedItems /*, droppedItem*/) {
        Ember.get(this, 'queue').reset(reorderedItems);
      }
    }
  });
});