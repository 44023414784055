define('nypr-publisher-lib/components/comments-form', ['exports', 'ember-get-config', 'fetch', 'nypr-publisher-lib/templates/components/comments-form'], function (exports, _emberGetConfig, _fetch, _commentsForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _commentsForm.default,
    tagName: 'form',
    classNames: ['comment-form'],
    classNameBindings: ['isSaved:is-fadeout'],

    name: Ember.computed.reads('userName'),
    securityURL: Ember.computed('story', 'browserId', function () {
      var story = this.get('story');
      var browserId = this.get('browserId');
      return story.commentSecurityURL(browserId);
    }),

    didInsertElement: function didInsertElement() {
      this.auth = (0, _fetch.default)(this.get('securityURL'), {
        credentials: 'include'
      }).then(function (response) {
        return response.json();
      });
    },
    hasErrors: function hasErrors() {
      var requiredFields = ['name', 'comment'];

      if (!this.get('isStaff')) {
        requiredFields.push('email');
      }

      this.set('errors', {});

      requiredFields.forEach(function (field) {
        var val = this.get(field);
        if (!val) {
          this.set('errors.' + field, 'This field is required');
        }
      }, this);

      return Object.keys(this.get('errors')).length !== 0;
    },


    actions: {
      sendComment: function sendComment() {
        var _this = this;

        this.set('isDisabled', true);
        if (this.hasErrors()) {
          this.set('isDisabled', false);
          return false;
        }
        var data = this.$().serialize();
        this.auth.then(function (_ref) {
          var security_hash = _ref.security_hash,
              timestamp = _ref.timestamp;

          var url = _emberGetConfig.default.adminRoot + '/comments/post/?bust_cache=' + Math.random() + '&id=' + _this.get('browserId');
          var story = _this.get('story');
          var metaData = {
            content_type: 'cms.' + story.get('itemType'),
            object_pk: story.get('cmsPK'),
            timestamp: timestamp,
            security_hash: security_hash,
            site: 1,
            honeypot: 'Dave'
          };
          var options = {
            type: 'PUT',
            url: url,
            xhrFields: { withCredentials: true },
            data: data + '&' + Ember.$.param(metaData)
          };
          Ember.$.ajax(options).always(function (response) {
            if (response.errors) {
              this.set('errors', response.errors);
              this.set('isDisabled', false);
            } else {
              this.set('isSaved', true);
              var onSave = this.get('onSave');
              if (onSave) {
                onSave();
              }
            }
          }.bind(_this));
        });
      }
    }
  });
});