define('nypr-publisher-lib/components/channel-header', ['exports', 'nypr-publisher-lib/templates/components/channel-header'], function (exports, _channelHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _channelHeader.default,

    classNames: ['channel-header'],
    subscribeOptions: Ember.computed.readOnly('model.podcastLinks'),
    donationOption: Ember.computed.readOnly('model.donate'),
    airings: Ember.computed.readOnly('model.scheduleSummary')
  });
});