define('nypr-ui/components/nypr-multi-card/panel', ['exports', 'nypr-ui/templates/components/nypr-multi-card/panel'], function (exports, _panel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _panel.default,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var notify = this.get('notify');
      if (notify) {
        this.get('notify')(this);
      }
    },


    actions: {
      goTo: function goTo(to) {
        this.get('setActive')(to);
      }
    }
  });
});