define('nypr-publisher-lib/components/story-tease', ['exports', 'moment', 'nypr-ui/helpers/image-template', 'nypr-publisher-lib/templates/components/story-tease'], function (exports, _moment, _imageTemplate, _storyTease) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var STATUSES = {
    LIVE: 'On Air Now',
    LATEST: 'Latest Episode',
    UPCOMING: 'Upcoming Episode'
  };

  exports.default = Ember.Component.extend({
    layout: _storyTease.default,
    whatsOn: Ember.inject.service(),

    status: null,
    streamSlug: null,
    streamName: null,
    isFeatured: false,
    allowQueueing: false,

    isLive: Ember.computed.equal('status', STATUSES.LIVE),
    isLatest: Ember.computed.readOnly('item.isLatest'),
    isStreamable: Ember.computed.and('item.audioAvailable', 'item.audioMayStream'),
    isListenableNow: Ember.computed.or('isStreamable', 'isLive'),
    // BEGIN-SNIPPET is-fancy-featured-cp
    isFancyFeatured: Ember.computed.and('item.largeTeaseLayout', 'item.imageMain', 'isFeatured'),
    // END-SNIPPET


    tagName: 'article',
    classNameBindings: ['featuredClasses', 'fullScreen:story-tease--fullscreen', 'isFeatured:story-tease--featured', 'isFancyFeatured:story-tease--fancyfeatured', 'flipped:story-tease--flipped'],
    classNames: ['story-tease'],

    itemId: Ember.computed('isLive', 'streamSlug', 'item.id', function () {
      return Ember.get(this, 'isLive') ? Ember.get(this, 'streamSlug') : Ember.get(this, 'item.id');
    }),
    isListenableEventually: Ember.computed('status', function () {
      var status = Ember.get(this, 'status');
      var audioEventually = Ember.get(this, 'item.audioEventually');
      return status !== STATUSES.LIVE && status !== null && audioEventually;
    }),
    featuredClasses: Ember.computed('isFeatured', 'item.largeTeaseLayout', 'media.isSmallAndUp', function () {
      var isFeatured = Ember.get(this, 'isFeatured');
      var lgTease = Ember.get(this, 'item.largeTeaseLayout');
      var isSmallAndUp = Ember.get(this, 'media.isSmallAndUp');
      if (isFeatured && lgTease && isSmallAndUp) {
        return 'box--dark box--featured';
      } else if (isFeatured) {
        return 'box--nearwhite box--featured';
      }
    }),
    showParent: Ember.computed('parentTitle', 'item.headers.brand.title', function () {
      var parentTitle = Ember.get(this, 'parentTitle');
      var brandTitle = Ember.get(this, 'item.headers.brand.title');
      return parentTitle !== brandTitle;
    }),
    playButton: Ember.computed('isFeatured', 'flipped', function () {
      if (this.get('isFeatured') || this.get('flipped')) {
        return 'blue-boss';
      } else {
        return 'blue-minion';
      }
    }),
    endtimeLabel: Ember.computed('endtime', function () {
      var endtime = Ember.get(this, 'endtime');
      var timeObj = (0, _moment.default)(endtime);

      if (timeObj.minutes() === 0) {
        return 'until ' + timeObj.format('h A');
      } else {
        return 'until ' + timeObj.format('h:mm A');
      }
    }),
    fancyFeaturedBackground: Ember.computed('item.imageMain', function () {
      var image = Ember.get(this, 'item.imageMain');

      var _Ember$getProperties = Ember.getProperties(image, 'template', 'crop'),
          template = _Ember$getProperties.template,
          crop = _Ember$getProperties.crop;

      return Ember.String.htmlSafe('background-image: url(' + (0, _imageTemplate.imageTemplate)([template, 0, 400, crop]) + ');');
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this._checkWhatsOn();
    },
    _checkWhatsOn: function _checkWhatsOn() {
      var _this = this;

      var story = Ember.get(this, 'item');
      var pk = Ember.get(story, 'cmsPK');
      var whatsOn = Ember.get(this, 'whatsOn');

      if (!Ember.get(this, 'isLatest')) {
        return;
      }

      whatsOn.isLive(pk).then(function (isLive) {
        return _this._updateStatus(isLive);
      });
    },
    _updateStatus: function _updateStatus(results) {
      var _results = _slicedToArray(results, 4),
          isLive = _results[0],
          endtime = _results[1],
          streamSlug = _results[2],
          streamName = _results[3];

      if (isLive) {
        Ember.set(this, 'status', STATUSES.LIVE);
        Ember.set(this, 'endtime', endtime);
        Ember.set(this, 'streamSlug', streamSlug);
        Ember.set(this, 'streamName', streamName);
      } else if (this._isUpcoming()) {
        Ember.set(this, 'status', STATUSES.UPCOMING);
      } else {
        Ember.set(this, 'status', STATUSES.LATEST);
      }
    },
    _isUpcoming: function _isUpcoming() {
      var datetime = Ember.get(this, 'item.newsdate');
      var itemdate = new Date(datetime);
      var itemdateEpoch = itemdate.getTime();
      var now = new Date();
      var nowEpoch = now.getTime();

      return nowEpoch < itemdateEpoch;
    }
  });
});