define('nypr-ui/components/hero/headline', ['exports', 'nypr-ui/templates/components/hero/headline'], function (exports, _headline) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _headline.default,
    classNames: ['hero-headline']
  });
});