define('ember-lifeline/utils/get-task', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = getTask;

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    function getTask(obj, taskOrName, taskName) {
        var type = typeof taskOrName === 'undefined' ? 'undefined' : _typeof(taskOrName);
        var task = void 0;
        if (type === 'function') {
            task = taskOrName;
        } else if (type === 'string') {
            task = obj[taskOrName];
            if (typeof task !== 'function') {
                throw new TypeError('The method name \'' + taskOrName + '\' passed to ' + taskName + ' does not resolve to a valid function.');
            }
        } else {
            throw new TypeError('You must pass a task function or method name to \'' + taskName + '\'.');
        }
        return task;
    }
});