define('nypr-ui/components/nypr-share-button', ['exports', 'nypr-ui/templates/components/nypr-share-button'], function (exports, _nyprShareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprShareButton.default,
    classNames: ['nypr-sharebutton'],

    actions: {
      popupShareWindow: function popupShareWindow(destination, href) {
        var heights = {
          'Twitter': 433,
          'Facebook': 620
        };
        if (href) {
          var features = 'titlebar=no,close=no,width=600,height=' + heights[destination];
          window.open(href, '_blank', features);
        }
      }
    }
  });
});