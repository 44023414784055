define('nypr-ads/helpers/array', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.array = array;
  function array(params /*, hash*/) {
    return params;
  }

  exports.default = Ember.Helper.helper(array);
});