define('nypr-publisher-lib/models/chunk', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // BEGIN-SNIPPET chunk-fields
    slug: (0, _attr.default)(),
    content: (0, _attr.default)(),
    pagecontent: Ember.computed('content', function () {
      var text = this.get('content');
      try {
        return this.store.createRecord('django-page', { text: text });
      } catch (e) {
        console.warn('Could not find django-page model. Please install the nypr-django-for-ember addon'); // eslint-disable-line
        return text;
      }
    })
    // END-SNIPPET
  });
});