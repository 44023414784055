define("nypr-account-settings/components/google-recaptcha", ["exports", "ember-get-config", "nypr-account-settings/utils/get-script"], function (exports, _emberGetConfig, _getScript) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Component based off ember-g-recaptcha ember component:
     * https://github.com/algonauti/ember-g-recaptcha
     */
    classNames: ["g-recaptcha"],
    sitekey: _emberGetConfig.default.googleCaptchaKey,
    renderReCaptcha: function renderReCaptcha() {
      var _this = this;

      if (Ember.isNone(window.grecaptcha) || Ember.isNone(window.grecaptcha.render)) {
        Ember.run.later(function () {
          _this.renderReCaptcha();
        }, 500);
      } else {
        var container = this.element;
        if (container) {
          var widgetId = window.grecaptcha.render(container, {
            sitekey: this.get("sitekey"),
            callback: Ember.run.bind(this, "successCallback"),
            "expired-callback": Ember.run.bind(this, "resetReCaptcha")
          });
          this.set("widgetId", widgetId);
        }
      }
    },
    resetReCaptcha: function resetReCaptcha() {
      window.grecaptcha.reset(this.get("widgetId"));
    },
    successCallback: function successCallback(recaptchaKey) {
      this.get("reCaptchaResponse")(recaptchaKey);
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      // If testing, bypass the getScript call to avoid pulling a live file on
      // each test run. This jumps straight to the successCallback.
      this._super.apply(this, arguments);
      if (_emberGetConfig.default.googleCaptchaEndpoint) {
        (0, _getScript.default)(_emberGetConfig.default.googleCaptchaEndpoint, function () {
          Ember.run.next(function () {
            _this2.renderReCaptcha();
          });
        });
      } else {
        Ember.run.next(function () {
          _this2.successCallback("non-working key");
        });
      }
    }
  });
});