define('nypr-ui/components/support-stripe/heading', ['exports', 'nypr-ui/templates/components/support-stripe/heading'], function (exports, _heading) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Heading = Ember.Component.extend({
    layout: _heading.default,
    tagName: 'h3',
    classNames: ['support-stripe__heading']
  });

  Heading.reopenClass({
    positionalParams: ['text']
  });

  exports.default = Heading;
});