define('ember-hifi/utils/promise-race', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  /**
   * Given an array of params, this will go through the list one-by-one and call your
   * callback function until your function calls returnSuccess, at which point the
   * main Promise will resolve with that thing you passed it.
   *
   * The callback should have the following arguments (nextParam, returnSuccess, markAsFailure)
  
   * Your callback should do what it needs to do and if that thing is good, pass it to
   * returnSuccess. If that thing is bad call markAsFailure
   *
   * @class PromiseRace
   * @method start
   * @param {Array} params
   * @param {Function} callback(nextParam, returnSuccess, markAsFailure)
   * @return {Promise  {result, failures} }
   */

  function start(params, callback) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var paramsToTry = (0, _emberCopy.copy)(params);
      var failures = [];

      (function tryNext(tryThis) {
        tryThis.then(function (success) {
          resolve({ success: success, failures: failures });
        }).catch(function (failure) {
          if (failure) {
            failures.push(failure);
          }
          var nextParam = paramsToTry.shift();
          if (!nextParam) {
            var error = new Error('All given promises failed.');
            error.failures = failures;
            reject(error);
          } else {
            return tryNext(promisifyCallback(callback, nextParam));
          }
        });
      })(promisifyCallback(callback, paramsToTry.shift()));
    });
  }

  function promisifyCallback(callback, nextParam) {
    return new Ember.RSVP.Promise(function (returnSuccess, markFailure) {
      callback(nextParam, returnSuccess, markFailure);
    });
  }

  exports.default = {
    start: start
  };
});