define('nypr-auth/services/session', ['exports', 'ember-simple-auth/services/session', 'ember-get-config', 'fetch'], function (exports, _session, _emberGetConfig, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({
    syncBrowserId: function syncBrowserId() {
      var _this = this;

      var legacyId = void 0;
      try {
        legacyId = window.localStorage.getItem('browserId');
        if (legacyId) {
          window.localStorage.removeItem('browserId');
        }
      } catch (e) {
        if (e.name === "SecurityError") {
          console.warn("Cookies are disabled. No local settings allowed."); // eslint-disable-line no-console
          return Ember.RSVP.Promise.resolve(null);
        }
      }

      return (0, _fetch.default)(_emberGetConfig.default.etagAPI, { credentials: 'include' }).then(checkStatus).then(function (response) {
        return response.json();
      }).then(function (_ref) {
        var browser_id = _ref.browser_id;
        return _this.set('data.browserId', browser_id);
      });
    },
    staffAuth: function staffAuth() {
      var _this2 = this;

      return (0, _fetch.default)(_emberGetConfig.default.adminRoot + '/api/v1/is_logged_in/?bust_cache=' + Math.random(), {
        credentials: 'include'
      }).then(checkStatus).then(function (r) {
        return r.json();
      }).then(function (data) {
        var is_staff = data.is_staff,
            name = data.name;

        _this2.setProperties({
          'data.isStaff': is_staff,
          'data.staffName': name
        });
        return data;
      });
    },
    verify: function verify(email, password) {
      var authenticator = Ember.getOwner(this).lookup('authenticator:nypr');
      return authenticator.authenticate(email, password);
    },
    authorize: function authorize(header) {
      var _get = this.get('data.authenticated'),
          provider = _get.provider,
          access_token = _get.access_token;

      try {
        header['Authorization'] = 'Bearer ' + access_token;
        if (!Ember.isEmpty(provider)) {
          header['X-Provider'] = provider;
        }
      } catch (error) {
        console.warn(error); // eslint-disable-line no-console
      }
      return header;
    }
  });


  function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
});