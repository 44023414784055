define('nypr-account-settings/validations/nypr-accounts/signup', ['exports', 'ember-changeset-validations/validators', 'nypr-account-settings/validations/nypr-accounts/custom-messages'], function (exports, _validators, _customMessages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    givenName: [(0, _validators.validatePresence)({ presence: true, message: _customMessages.default.firstNameRequired }), (0, _validators.validateLength)({ max: 20, message: _customMessages.default.firstNameMaxLength }), (0, _validators.validateFormat)({ regex: /^\S.*$/, allowBlank: true, message: _customMessages.default.noLeadingSpace }), (0, _validators.validateFormat)({ regex: /^.*\S$/, allowBlank: true, message: _customMessages.default.noTrailingSpace })],
    familyName: [(0, _validators.validatePresence)({ presence: true, message: _customMessages.default.lastNameRequired }), (0, _validators.validateLength)({ max: 40, message: _customMessages.default.lastNameMaxLength }), (0, _validators.validateFormat)({ regex: /^\S.*$/, allowBlank: true, message: _customMessages.default.noLeadingSpace }), (0, _validators.validateFormat)({ regex: /^.*\S$/, allowBlank: true, message: _customMessages.default.noTrailingSpace })],
    email: [(0, _validators.validateFormat)({ type: 'email', allowBlank: true, message: _customMessages.default.emailFormat }), (0, _validators.validatePresence)({ presence: true, message: _customMessages.default.emailRequired })],
    emailConfirmation: [(0, _validators.validateConfirmation)({ on: 'email', allowBlank: true, message: _customMessages.default.emailConfirmation })],
    typedPassword: [(0, _validators.validateFormat)({ regex: /^(?=[\S]*?[0-9]).{8,}$/, allowBlank: true, message: _customMessages.default.passwordRules }), (0, _validators.validatePresence)({ presence: true, message: _customMessages.default.passwordRequired })]
  };
});