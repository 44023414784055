define('nypr-ui/components/nypr-story/main-image', ['exports', 'nypr-ui/templates/components/nypr-story/main-image', 'nypr-ui/mixins/image-loader'], function (exports, _mainImage, _imageLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_imageLoader.default, {
    layout: _mainImage.default,
    captionOpen: false,

    actions: {
      toggleCaption: function toggleCaption() {
        this.toggleProperty("captionOpen");
      }
    }

  });
});