define('nypr-account-settings/utils/fetch-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.rejectUnsuccessfulResponses = rejectUnsuccessfulResponses;


  /*  fetch() doesn't reject promises for requests that
      succeed but have http error codes. If we want that behavior
      we can use this `rejectUnsuccessfulResponse` function.
  
      fetch(url)
      .then(rejectUnsuccessfulResponse)
      .then(doSomethingElseWithResponse)
      .catch(dealWithBadResponsesAndOtherErrors);
  */
  function rejectUnsuccessfulResponses(response) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (response && response.ok) {
        resolve(response);
      } else {
        if (response.json) {
          response.json().then(reject);
        } else {
          reject(response);
        }
      }
    });
  }
});