define('nypr-ui/components/nypr-tabs/nypr-tabs-tablist', ['exports', 'nypr-ui/templates/components/nypr-tabs/nypr-tabs-tablist', 'ivy-tabs/components/ivy-tabs-tablist'], function (exports, _nyprTabsTablist, _ivyTabsTablist) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ivyTabsTablist.default.extend({
    layout: _nyprTabsTablist.default,
    classNames: ['nypr-tabs-tablist']
  });
});