define('ember-changeset/utils/validator-lookup', ['exports', 'ember-changeset/utils/wrap', 'ember-changeset/utils/handle-multiple-validations', 'ember-changeset/utils/is-promise'], function (exports, _wrap, _handleMultipleValidations, _isPromise) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = lookupValidator;

    /**
     * returns a closure to lookup and validate k/v pairs set on a changeset
     *
     * @method lookupValidator
     * @param validationMap
     */
    function lookupValidator(validationMap) {
        return function (_ref) {
            var key = _ref.key,
                newValue = _ref.newValue,
                oldValue = _ref.oldValue,
                changes = _ref.changes,
                content = _ref.content;

            var validator = validationMap[key];
            if (Ember.isEmpty(validator)) {
                return true;
            }
            if (Ember.isArray(validator)) {
                return (0, _handleMultipleValidations.default)(validator, { key: key, newValue: newValue, oldValue: oldValue, changes: changes, content: content });
            }
            var validation = validator(key, newValue, oldValue, changes, content);
            return (0, _isPromise.default)(validation) ? validation.then(_wrap.default) : [validation];
        };
    }
});