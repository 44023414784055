define('nypr-audio-services/components/player-history', ['exports', 'nypr-audio-services/templates/components/player-history'], function (exports, _playerHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _playerHistory.default,
    dj: Ember.inject.service(),
    listenHistory: Ember.inject.service(),

    listens: Ember.computed.readOnly('listenHistory.items'),

    classNames: ['player-history'],

    actions: {
      removeFromHistory: function removeFromHistory(pk) {
        this.get('listenHistory').removeListenByStoryPk(pk);
      }
    }
  });
});