define('nypr-account-settings/components/nypr-accounts/social-connect-button', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/social-connect-button'], function (exports, _socialConnectButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _socialConnectButton.default,
    classNames: ['nypr-social-connect'],
    buttonAction: function buttonAction() {}
  });
});