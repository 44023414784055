define('nypr-publisher-lib/serializers/story', ['exports', 'ember-data', 'nypr-publisher-lib/helpers/camelize-object'], function (exports, _emberData, _camelizeObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.serializeStoryAttributes = serializeStoryAttributes;


  // BEGIN-SNIPPET story-serializer-fields
  //dasherized versions of names in model bc they haven't been processsed yet
  var propertiesWithChildren = ['appearances', 'chunks', 'headers', 'image-main', 'playlist', 'producing-organizations', 'segments', 'series', 'show-producing-orgs', 'slideshow'];
  // END-SNIPPET

  // BEGIN-SNIPPET story-serializer
  function serializeStoryAttributes(attributes) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = propertiesWithChildren[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var prop = _step.value;

        //if we have the property, process it
        if (attributes && attributes.hasOwnProperty(prop)) {
          attributes[prop] = (0, _camelizeObject.camelizeObject)(attributes[prop]);
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }
  // END-SNIPPET

  exports.default = _emberData.default.JSONAPISerializer.extend({
    extractId: function extractId(modelClass, _ref) {
      var attributes = _ref.attributes;
      return attributes.slug;
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return 'story';
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      serializeStoryAttributes(payload.data.attributes);
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});