define('nypr-django-for-ember/components/django-page', ['exports', 'ember-get-config', 'nypr-django-for-ember/utils/alien-dom', 'nypr-django-for-ember/templates/components/django-page'], function (exports, _emberGetConfig, _alienDom, _djangoPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _djangoPage.default,
    loadingType: Ember.computed('page', function () {
      var id = Ember.get(this, 'page.id') || '';
      var firstPart = id.split('/')[0];

      switch (firstPart) {
        case '':
          return 'index';
        case 'shows':
        case 'articles':
        case 'series':
        case 'tags':
        case 'blogs':
          return 'channel';
        case 'story':
          return 'story';
        default:
          return 'legacy';
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      // If we have a new page model, we want to clear any overlaid
      // content when we rerender.
      var page = this.get('page');
      if (page !== this._lastPage) {
        this.set('showingOverlay', false);
      }
    },


    // BEGIN-SNIPPET component-did-render
    didRender: function didRender() {
      var _this = this;

      var page = this.get('page');
      if (page !== this._lastPage) {
        this._lastPage = page;
        var elt = this.$('.django-content');
        elt.empty();

        if ((0, _alienDom.isInDom)(page.get('id'))) {
          (0, _alienDom.clearAlienDom)();
        }

        this.get('page').appendTo(elt).then(function () {
          // After the server-rendered page has been inserted, we
          // re-enable any overlaid content so that it can wormhole
          // itself into the server-rendered DOM.
          _this.set('showingOverlay', true);

          if (_this.get('session.data.isStaff')) {
            _this.revealStaffLinks(_this.$(), _emberGetConfig.default.adminRoot);
          }
          _this.$().imagesLoaded().progress(function (i, image) {
            Ember.run(function () {
              image.img.classList.add('is-loaded');
            });
          });
        });
      }
    },
    revealStaffLinks: function revealStaffLinks($element, adminRoot) {
      $element.find('.stf').each(function () {
        var $elt,
            $this = Ember.$(this);
        if (this.tagName.toLowerCase() === 'a') {
          $elt = $this;
        } else {
          $this.append($elt = Ember.$("<a/>").addClass(this.className));
        }
        $elt.html($elt.html() || 'Edit This').attr("target", '_blank');
        $elt.attr("href", adminRoot + '/admin/' + $this.attr('data-url'));
        $this.show();
        $this.parent().show();
      });
    }
  }
  // END-SNIPPET
  );
});