define('nypr-publisher-lib/adapters/bucket', ['exports', 'ember-data', 'ember-get-config', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _emberGetConfig, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v3',
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);
      if (requestType !== 'findRecord') {
        return url;
      }

      url += '/?site=' + _emberGetConfig.default.siteSlug;
      if (query && Object.keys(query).length) {
        var qp = Object.keys(query).map(function (k) {
          return k + '=' + query[k];
        });
        url += qp.join('&');
      }
      return url;
    }
  });
});