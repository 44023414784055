define('nypr-account-settings/validations/nypr-accounts/login', ['exports', 'ember-changeset-validations/validators', 'nypr-account-settings/validations/nypr-accounts/custom-messages'], function (exports, _validators, _customMessages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    email: [(0, _validators.validatePresence)({ presence: true, message: _customMessages.default.emailRequired }), (0, _validators.validateFormat)({ type: 'email', allowBlank: true, message: _customMessages.default.emailFormat })],
    password: [(0, _validators.validatePresence)({ presence: true, message: _customMessages.default.passwordRequired })]
  };
});