define('nypr-audio-services/components/queue-listitem', ['exports', 'nypr-audio-services/templates/components/queue-listitem'], function (exports, _queueListitem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _queueListitem.default,
    attributeBindings: ['data-id'],
    'data-id': Ember.computed.readOnly('dataId'),
    state: Ember.computed('isCurrent', 'playState', function () {
      return this.get('isCurrent') ? this.get('playState') : null;
    })
  });
});