define('nypr-ui/components/network-stripe', ['exports', 'nypr-ui/templates/components/network-stripe'], function (exports, _networkStripe) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _networkStripe.default,
    classNames: ['network-stripe']
  });
});