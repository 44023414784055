define('nypr-account-settings/components/nypr-account-modal/footer', ['exports', 'nypr-account-settings/templates/components/nypr-account-modal/footer'], function (exports, _footer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['nypr-account-modal-footer'],
    layout: _footer.default
  });
});