define('nypr-publisher-lib/components/appearance-list', ['exports', 'nypr-publisher-lib/templates/components/appearance-list'], function (exports, _appearanceList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _appearanceList.default,
    classNames: ['appearance-list'],
    attributeBindings: ['data-test-selector'],
    'data-test-selector': 'appearance-list'
  });
});