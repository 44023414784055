define('nypr-ui/components/hero', ['exports', 'nypr-ui/templates/components/hero', 'nypr-ui/mixins/sticky-header'], function (exports, _hero, _stickyHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_stickyHeader.default, {
    layout: _hero.default,
    classNames: ['nypr-ui__hero'],
    breakpoint: '(min-width: 801px)',

    init: function init() {
      this._super.apply(this, arguments);
      var breakpoint = this.get('breakpoint');
      if (this.get('noHeaderImage')) {
        this.set('gradient', false);
        this.set('no-image', true);
      }
      if (window.matchMedia && breakpoint) {
        var mql = window.matchMedia(breakpoint);
        this.set('mql', mql);
        this.set('boundListener', Ember.run.bind(this, 'toggleImage'));
        mql.addListener(this.get('boundListener'));

        this.toggleImage(mql);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('mql').removeListener(this.get('boundListener'));
    },
    toggleImage: function toggleImage(_ref) {
      var matches = _ref.matches;

      this.set('useBackgroundImage', matches);
    },


    backgroundImage: Ember.computed('src', 'useBackgroundImage', function () {
      if (this.get('useBackgroundImage') && !this.get('noHeaderImage')) {
        var image = this.get('src');
        return Ember.String.htmlSafe(image ? 'background-image: url(' + image + ');' : '');
      } else {
        return Ember.String.htmlSafe('');
      }
    }),

    showCredits: Ember.computed('noHeaderImage', 'source', 'credit', 'caption', function () {
      var _getProperties = this.getProperties('noHeaderImage', 'source', 'credit', 'caption'),
          noHeaderImage = _getProperties.noHeaderImage,
          source = _getProperties.source,
          credit = _getProperties.credit,
          caption = _getProperties.caption;

      return !noHeaderImage && (source || credit || caption);
    }),

    setImage: function setImage(_ref2) {
      var src = _ref2.src,
          source = _ref2.source,
          caption = _ref2.caption,
          credit = _ref2.credit;

      this.setProperties({ src: src, source: source, caption: caption, credit: credit });
    },
    offset: function offset() {
      return -this.element.clientHeight + 155;
    },
    measure: function measure() {
      this.refresh();
    }
  });
});