define('nypr-metrics/services/data-pipeline', ['exports', 'fetch', 'ember-get-config'], function (exports, _fetch, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LISTEN_ACTIONS = {
    START: 'start',
    PAUSE: 'pause',
    RESUME: 'resume',
    FORWARD_15: 'skip_15_forward',
    BACK_15: 'skip_15_back',
    CLOSE: 'window_close',
    FINISH: 'finish',
    POSITION: 'set_position',
    INTERRUPT: 'interrupt'
  };

  exports.default = Ember.Service.extend({
    itemViewPath: 'v1/events/viewed',
    listenActionPath: 'v1/events/listened',
    currentReferrer: null,
    poll: Ember.inject.service(),

    init: function init() {
      Ember.set(this, '_delta', 0);
      this._super.apply(this, arguments);
    },


    browserId: null,
    authorize: function authorize(fetchOptions) {
      /* Client app should override this method and modify
      fetchOptions for authentication */
      return fetchOptions;
    },
    reportItemView: function reportItemView() {
      var _this = this;

      var incoming = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      Ember.run.next(function () {
        if (typeof document === 'undefined') {
          return; // don't run in fastboot
        }

        var data = _this._generateData(incoming);
        _this._send(data, _this.itemViewPath);

        if (data.cms_id) {
          _this._legacySend('most/view/managed_item/' + data.cms_id + '/');
        }
      });
    },
    reportListenAction: function reportListenAction(type) {
      var incoming = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (typeof document === 'undefined') {
        return; // don't run in fastboot
      }

      incoming.delta = this.updateDelta(type);
      var data = this._generateData(incoming, LISTEN_ACTIONS[type.toUpperCase()]);
      this._send(data, this.listenActionPath);

      if (/start|resume/.test(data.action) && data.cms_id) {
        this._legacySend('most/listen/managed_item/' + data.cms_id + '/');
        this._legacySend('v1/listenaction/create/' + data.cms_id + '/play/');
      } else if (data.action === 'finish' && data.cms_id) {
        this._legacySend('v1/listenaction/create/' + data.cms_id + '/complete/');
      }
    },
    updateDelta: function updateDelta(type) {
      var delta = void 0;
      if (/start|resume/.test(type)) {
        this._lastMarker = Date.now();
        this._didPause = false;
        delta = 0;
      } else if (this._didPause) {
        delta = 0;
      } else {
        var oldMarker = this._lastMarker;
        this._lastMarker = Date.now();
        delta = Date.now() - oldMarker;
      }

      if (type === 'pause') {
        this._didPause = true;
      }
      return delta;
    },
    _send: function _send(data, path) {
      var fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetchOptions = this.authorize(fetchOptions);

      (0, _fetch.default)(_emberGetConfig.default.platformEventsAPI + '/' + path, fetchOptions);
    },
    _legacySend: function _legacySend(path) {
      var browser_id = Ember.get(this, 'browserId');
      var fetchOptions = {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ browser_id: browser_id })
      };

      fetchOptions = this.authorize(fetchOptions);

      (0, _fetch.default)(_emberGetConfig.default.publisherAPI + '/' + path, fetchOptions);
    },
    _generateData: function _generateData(incoming, action) {
      var data = Object.assign({
        action: action,
        browser_id: Ember.get(this, 'browserId'),
        client: _emberGetConfig.default.clientSlug,
        referrer: Ember.get(this, 'currentReferrer'),
        external_referrer: document.referrer || '',
        url: location.toString(),
        site_id: _emberGetConfig.default.siteId
      }, incoming);
      if (!action) {
        delete data.action;
      }
      return data;
    }
  });
});