define('nypr-ui/helpers/image-template', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageTemplate = imageTemplate;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function imageTemplate(_ref) {
    var _ref2 = _slicedToArray(_ref, 5),
        template = _ref2[0],
        x = _ref2[1],
        y = _ref2[2],
        crop = _ref2[3],
        _ref2$ = _ref2[4],
        quality = _ref2$ === undefined ? 85 : _ref2$;

    function replaceFn(originalString, base, path) {
      return base + '/' + x + '/' + y + '/' + crop + '/' + quality + '/' + path;
    }

    return template && template.replace(/(.*\/i)\/%s\/%s\/%s\/%s\/(.*)/, replaceFn);
  }

  exports.default = Ember.Helper.helper(imageTemplate);
});