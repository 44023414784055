define('nypr-account-settings/components/nypr-account-forms/reset', ['exports', 'nypr-account-settings/templates/components/nypr-account-forms/reset', 'ember-changeset', 'ember-changeset-validations', 'nypr-account-settings/validations/nypr-accounts/password', 'fetch', 'nypr-account-settings/utils/fetch-utils'], function (exports, _reset, _emberChangeset, _emberChangesetValidations, _password, _fetch, _fetchUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var FLASH_MESSAGES = {
    reset: 'Your password has been successfully updated.'
  };

  exports.default = Ember.Component.extend({
    layout: _reset.default,
    store: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    authAPI: null,
    session: Ember.inject.service(),
    resendUrl: Ember.computed('authAPI', function () {
      return Ember.get(this, 'authAPI') + '/v1/password/forgot';
    }),
    allowedKeys: null,
    codeExpired: false,
    passwordWasReset: false,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'fields', {
        password: ''
      });
      Ember.set(this, 'allowedKeys', ['password']);
      Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'fields'), (0, _emberChangesetValidations.default)(_password.default), _password.default));
      Ember.get(this, 'changeset').validate();
    },
    resetPassword: function resetPassword(email, newPassword, confirmation) {
      var _this = this;

      var url = Ember.get(this, 'authAPI') + '/v1/confirm/password-reset';
      var method = 'POST';
      var headers = { "Content-Type": "application/json" };
      var body = JSON.stringify({ email: email, new_password: newPassword, confirmation: confirmation });
      return (0, _fetch.default)(url, { method: method, headers: headers, body: body }).then(_fetchUtils.rejectUnsuccessfulResponses).catch(function (e) {
        if (Ember.get(e, 'errors.code') === 'ExpiredCodeException') {
          Ember.set(_this, 'codeExpired', true);
        } else {
          Ember.get(_this, 'changeset').validate('password');
          Ember.get(_this, 'changeset').pushErrors('password', 'There was a problem changing your password.');
        }
        return Ember.RSVP.Promise.reject(e);
      });
    },
    showFlash: function showFlash(type) {
      this.get('flashMessages').add({
        message: FLASH_MESSAGES[type],
        type: 'success'
      });
    },

    actions: {
      onSubmit: function onSubmit() {
        return this.resetPassword(Ember.get(this, 'email'), Ember.get(this, 'fields.password'), Ember.get(this, 'confirmation'));
      },
      onSuccess: function onSuccess() {
        this.set('passwordWasReset', true);
        return this.showFlash('reset');
      }
    }
  });
});