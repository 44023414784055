define('nypr-publisher-lib/serializers/channel', ['exports', 'ember-data', 'nypr-publisher-lib/serializers/api-response'], function (exports, _emberData, _apiResponse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, typeClass, _ref, id, requestType) {
      var _ref$included = _ref.included,
          included = _ref$included === undefined ? [] : _ref$included,
          data = _ref.data;

      var featuredStory = data.attributes.featured;
      delete data.attributes.featured;

      included.push({
        type: 'api-response',
        id: id + '/about/1',
        relationships: {
          'about-page': {
            data: {
              type: 'about-page', id: id + '/about'
            }
          }
        }
      }, {
        type: 'about-page',
        id: id + '/about',
        attributes: data.attributes.about
      });

      included = included.map(function (r) {
        if (r.type === 'api-response') {
          r.relationships = (0, _apiResponse.serializeApiResponseRelationships)(r.relationships, included);
          return r;
        }
        return r;
      });

      if (featuredStory) {
        var story = {
          type: 'story',
          id: featuredStory.slug,
          attributes: featuredStory
        };

        included.push(story);

        data.relationships = {
          featured: {
            data: {
              type: 'story',
              id: featuredStory.slug
            }
          }
        };
      }
      return this._super(store, typeClass, { data: data, included: included }, id, requestType);
    }
  });
});