define('nypr-auth/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    attrs: {
      facebookId: 'custom:facebook_id'
    },
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    serialize: function serialize(snapshot, options) {
      var _super = this._super(snapshot, options),
          json = _super.data.attributes;

      var typedPassword = snapshot.record.get('typedPassword');

      if (typedPassword) {
        json = Object.assign(json, { password: snapshot.record.get('typedPassword') });
      }

      var captchaKey = snapshot.record.get('captchaKey');
      if (captchaKey) {
        json = Object.assign(json, { captcha_key: snapshot.record.get('captchaKey') });
      }
      return json;
    }
  });
});