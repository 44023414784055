define('nypr-publisher-lib/adapters/flat-page', ['exports', 'ember-data', 'ember-get-config', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _emberGetConfig, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v3',
    pathForType: function pathForType() {
      return 'flatpages';
    },
    urlForQuery: function urlForQuery(query) {
      if (query) {
        if (!query.filter) {
          query.filter = {};
        }
        if (query.filter.url && query.filter.url.substr(-1) !== '/') {
          query.filter.url += '/';
        }
        query.filter.site = _emberGetConfig.default.siteId;
      }
      return this._super.apply(this, arguments);
    },
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return url.replace(/([^/])$/, '$1/');
    }
  });
});