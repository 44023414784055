define('nypr-ui/components/nypr-card', ['exports', 'nypr-ui/templates/components/nypr-card'], function (exports, _nyprCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprCard.default,
    classNames: ['nypr-card']
  });
});