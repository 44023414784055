define('nypr-publisher-lib/adapters/api-response', ['exports', 'ember-data', 'ember-get-config', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberData, _emberGetConfig, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v3',
    pathForType: function pathForType() {
      return 'channel';
    },
    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments);
      return decodeURIComponent(url);
    },
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var baseUrl = this.buildURL(modelName, id, snapshot);
      if (snapshot.adapterOptions) {
        var limit = snapshot.adapterOptions.limit;
        if (limit) {
          baseUrl += '?limit=' + limit;
        }
      }
      return baseUrl;
    },

    // ember 2.0 deprecation
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    }
  });
});