define('nypr-publisher-lib/components/story-credits', ['exports', 'nypr-publisher-lib/templates/components/story-credits'], function (exports, _storyCredits) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _storyCredits.default,
    classNames: ['story-credits']
  });
});