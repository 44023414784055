define("nypr-publisher-lib/utils/math-util", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.totalPages = totalPages;
  exports.calculateOffset = calculateOffset;
  exports.firstLessThan = firstLessThan;
  function totalPages(totalItems) {
    var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;

    var value = totalItems / offset;
    var pages = Math.ceil(value);

    return isNaN(pages) ? 0 : pages;
  }

  function calculateOffset(page, limitPerPage) {
    var offset = (page - 1) * limitPerPage;
    return offset;
  }

  function firstLessThan(ary, num) {
    for (var i = 0; i < ary.length; i++) {
      if (num < ary[i]) {
        return String(ary[i]);
      }
    }
    return "greater than " + ary[ary.length - 1];
  }
});