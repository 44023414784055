define('nypr-player/components/nypr-player/rewind-button', ['exports', 'nypr-player/templates/components/nypr-player/rewind-button'], function (exports, _rewindButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _rewindButton.default,
    tagName: 'button',
    classNames: ['nypr-player-button', 'mod-rewind', 'gtm__click-tracking'],
    attributeBindings: ['aria-text', 'aria-labelled-by', 'data-action'],
    'aria-text': "rewind 15 seconds",
    'aria-labelled-by': "rewind-label",
    'data-action': 'Clicked Skip Backward: persistent-player'
  });
});