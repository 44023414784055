define('nypr-account-settings/components/nypr-accounts/membership-card', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/membership-card', 'moment'], function (exports, _membershipCard, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    layout: _membershipCard.default,
    session: Ember.inject.service(),
    pledgeManagerEnabled: true,
    customStatusMessage: "",
    customStatusUrl: "",
    customStatusUrlText: "",
    previousYear: Ember.computed(function () {
      return (0, _moment.default)().subtract(1, "year").year();
    }),
    pledgesFromLastYear: Ember.computed.filter("pledges", function (pledge) {
      var previousYear = (0, _moment.default)().subtract(1, "year").year();

      var pledgeYear = null;
      if (pledge["get"] && pledge.get("orderDate")) {
        pledgeYear = (0, _moment.default)(pledge.get("orderDate")).year();
      } else if (_typeof(pledge.orderDate) === "object") {
        pledgeYear = (0, _moment.default)(pledge.orderDate).year();
      }

      return pledgeYear == previousYear;
    }),
    hasPledgesInPastTaxYear: Ember.computed('pledgesFromLastYear', function () {
      var lastYearsPledges = this.get('pledgesFromLastYear');
      return !!lastYearsPledges.length;
    })
  });
});