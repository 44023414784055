define('nypr-publisher-lib/serializers/comment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      return {
        data: payload.results.map(function (result) {
          var id = result.pk,
              author = result.user_name,
              comment = result.comment,
              publishDate = result.submit_date;

          return {
            id: id,
            type: 'comment',
            attributes: {
              author: author,
              comment: comment,
              publishDate: publishDate,
              location: result.location
            }
          };
        })
      };
    }
  });
});