define('nypr-countdown/components/count-down', ['exports', 'nypr-countdown/templates/components/count-down', 'ember-inflector', 'moment'], function (exports, _countDown, _emberInflector, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['count-down'],
    tagName: 'span',
    layout: _countDown.default,

    timezone: 'America/New_York',
    from: (0, _moment.default)(),

    diff: Ember.computed('from', 'to', 'unit', function () {
      var from = this.from,
          to = this.to,
          unit = this.unit,
          timezone = this.timezone;

      from = _moment.default.tz(from, timezone);
      to = _moment.default.tz(to, timezone);

      if (isNaN(from) || isNaN(to)) {
        return;
      }

      var value = _moment.default.duration(to.diff(from)).as(unit);
      return Math.ceil(value);
    }),

    humanizedTime: Ember.computed('from', 'to', 'unit', function () {
      var from = this.from,
          to = this.to,
          unit = this.unit,
          timezone = this.timezone;

      from = _moment.default.tz(from, timezone);
      to = _moment.default.tz(to, timezone);

      if (isNaN(from) || isNaN(to)) {
        return;
      }

      return to.calendar(from, {
        sameDay: function sameDay() {
          return '[is today at ' + to.format('LT') + ']';
        },
        nextDay: function nextDay() {
          return '[is tomorrow]';
        },
        nextWeek: function nextWeek() {
          return '[is ' + to.format('dddd') + ']';
        },
        lastDay: function lastDay() {
          return '[was yesterday]';
        },
        lastWeek: function lastWeek() {
          return '[was ' + to.format('dddd') + ']';
        },
        sameElse: function sameElse(from) {
          var value = _moment.default.duration(to.diff(from)).as(unit);
          value = Math.abs(Math.round(value));
          if (value === 1) {
            unit = (0, _emberInflector.singularize)(unit);
          } else {
            unit = (0, _emberInflector.pluralize)(unit);
          }
          if (from.isBefore(to)) {
            return '[is in ' + value + ' ' + unit + ']';
          } else {
            return '[was ' + value + ' ' + unit + ' ago]';
          }
        }
      });
    })
  });
});