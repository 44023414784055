define('nypr-ui/components/nypr-story/segments', ['exports', 'nypr-ui/templates/components/nypr-story/segments'], function (exports, _segments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _segments.default,
    accordionOpen: true,

    actions: {
      toggleAccordion: function toggleAccordion() {
        this.toggleProperty('accordionOpen');
      }
    }
  });
});