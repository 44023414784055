define('nypr-auth/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    email: attr('string'),
    givenName: attr('string'),
    familyName: attr('string'),
    preferredUsername: attr('string'),
    picture: attr('string'),
    facebookId: attr('string'),
    status: attr('string'),

    socialOnly: Ember.computed.equal('status', 'FORCE_CHANGE_PASSWORD'),
    hasPassword: Ember.computed.not('socialOnly')
  });
});