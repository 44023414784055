define('nypr-ui/components/dropdown-panel', ['exports', 'nypr-ui/templates/components/dropdown-panel'], function (exports, _dropdownPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _dropdownPanel.default,
    classNames: ['dropdown', 'dropdown-fade'],
    classNameBindings: ['directionClass', 'colorClass'],
    directionClass: Ember.computed('direction', function () {
      var direction = this.get('direction');
      switch (direction) {
        case 'br':
          return 'dropdown--br';
        case 'tl':
          return 'dropdown--tl';
        default:
          return 'dropdown--bl';
      }
    }),
    colorClass: Ember.computed('color', function () {
      var color = this.get('color');
      switch (color) {
        case 'white':
          return 'dropdown--white';
        default:
          return 'dropdown--nearwhite';
      }
    })
  });
});