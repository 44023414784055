define('nypr-ui/components/nypr-multi-card', ['exports', 'nypr-ui/templates/components/nypr-multi-card'], function (exports, _nyprMultiCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprMultiCard.default,

    children: null,
    lookupKey: 'name',

    init: function init() {
      this._super.apply(this, arguments);
      this.set('children', Ember.A([]));
    },
    activate: function activate(to) {
      this.get('children').setEach('active', false);
      if (typeof to === 'number') {
        this.get('children').objectAt(to).set('active', true);
      } else {
        this.get('children').findBy('name', to).set('active', true);
      }
    },

    actions: {
      notify: function notify(child) {
        this.get('children').pushObject(child);
        if (this.get('children.length') === 1) {
          this.get('children.firstObject').set('active', true);
        }
      }
    }
  });
});