define('nypr-ui/components/nypr-brick-row-blowout', ['exports', 'nypr-ui/templates/components/nypr-brick-row-blowout'], function (exports, _nyprBrickRowBlowout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprBrickRowBlowout.default,
    classNames: ['brick-row', 'brick-row--blowout']
  });
});