define('nypr-player/helpers/can-change-volume', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.canChangeVolume = canChangeVolume;

  // A test to see if we can change volume on HTML5 Audio Elements.
  // Mostly for iOS
  // https://developer.apple.com/library/content/documentation/AudioVideo/Conceptual/Using_HTML5_Audio_Video/Device-SpecificConsiderations/Device-SpecificConsiderations.html#//apple_ref/doc/uid/TP40009523-CH5-SW11

  function canChangeVolume() {
    if (typeof document === 'undefined') {
      return false;
    }
    var audio = document.createElement('audio');
    if (audio.volume !== undefined) {
      audio.volume = 0.99; //arbitrary value
      return audio.volume === 0.99;
    }
    // this browser doesn't seem to support HTML5 <audio>
    return false;
  }

  exports.default = Ember.Helper.helper(canChangeVolume);
});