define('nypr-account-settings/helpers/lowercase', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.lowercase = lowercase;
  function lowercase(params /*, hash*/) {
    var myString = params[0];
    return myString.toLowerCase();
  }

  exports.default = Ember.Helper.helper(lowercase);
});