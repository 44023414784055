define('nypr-publisher-lib/components/tabbed-list', ['exports', 'nypr-publisher-lib/templates/components/tabbed-list'], function (exports, _tabbedList) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _tabbedList.default,
    defaultTab: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this.tabTitles = Ember.getWithDefault(this, 'tabTitles', []);
      this.childComponents = Ember.getWithDefault(this, 'childComponents', []);
      Ember.set(this, 'activeTabIndex', Ember.get(this, 'defaultTab'));
    },


    activeComponent: Ember.computed('activeTabIndex', 'childComponents', function () {
      return Ember.get(this, 'childComponents')[Ember.get(this, 'activeTabIndex')];
    }),

    actions: {
      chooseTab: function chooseTab(index) {
        Ember.set(this, 'activeTabIndex', index);
        var tabTitle = this.get('tabTitles')[index];
        var titleMap = {
          "My Queue": "Queue",
          "My Listening History": "History"
        };
        var tabLabel = titleMap[tabTitle] || tabTitle;
        if (Ember.get(this, 'onChooseTab')) {
          this.send('onChooseTab', tabLabel);
        }
      }
    }
  });
});