define('nypr-ui/components/nypr-form', ['exports', 'nypr-ui/templates/components/nypr-form'], function (exports, _nyprForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprForm.default,

    init: function init() {
      this._super.apply(this, arguments);
      Ember.getWithDefault(this, 'allowedKeys', []);
    },


    // pass these in
    changeset: null,
    onSubmit: function onSubmit() {},
    onFailure: function onFailure() {},
    onSuccess: function onSuccess() {},
    onInvalid: function onInvalid() {},

    // yields these
    tried: false,
    success: false,
    failure: false,
    invalid: false,
    processing: false,
    actions: {
      submit: function submit() {
        var _this = this;

        Ember.set(this, 'tried', true);
        Ember.set(this, 'processing', true);
        var changeset = Ember.get(this, 'changeset');
        var snapshot = changeset.snapshot();
        return changeset.cast(Ember.get(this, 'allowedKeys')).validate().then(function () {
          if (Ember.get(changeset, 'isValid')) {
            changeset.execute();
            Ember.RSVP.Promise.resolve(Ember.get(_this, 'onSubmit')()).then(function () {
              Ember.set(_this, 'success', true);
              Ember.get(_this, 'onSuccess')();
              Ember.set(_this, 'processing', false);
            }).catch(function (e) {
              changeset.restore(snapshot);
              Ember.set(_this, 'failure', true);
              Ember.get(_this, 'onFailure')(e);
              Ember.set(_this, 'processing', false);
            });
          } else {
            changeset.restore(snapshot);
            Ember.set(_this, 'invalid', true);
            Ember.get(_this, 'onInvalid')();
            Ember.set(_this, 'processing', false);
          }
        });
      }
    }
  });
});