define('ember-holygrail-layout/components/holygrail-layout/center/component', ['exports', 'ember-holygrail-layout/components/holygrail-layout/center/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['ember-holygrail-middle', 'ember-holygrail-centered'],
    layout: _template.default
  });
});