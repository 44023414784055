define('nypr-publisher-lib/services/whats-on', ['exports', 'ember-get-config', 'fetch'], function (exports, _emberGetConfig, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    endPoint: 'v1/whats_on/',
    isLive: function isLive(pk) {
      var _this = this;

      var endPoint = Ember.get(this, 'endPoint');
      var url = _emberGetConfig.default.publisherAPI + '/' + endPoint;

      return (0, _fetch.default)(url).then(function (r) {
        return r.json();
      }).then(function (d) {
        return _this._extractStatus(d, pk);
      });
    },
    _extractStatus: function _extractStatus(data, pk) {
      var stations = Object.keys(data);
      for (var i = 0; i < stations.length; i++) {
        var stationSlug = stations[i];
        var station = data[stationSlug];
        var stationName = Ember.get(station, 'name');
        // for some reason if the what's on story is an EPISODE, it's under episode_pk,
        // but if it's a SEGMENT, that pk is just on pk
        var onAirPk = Ember.get(station, 'current_show.episode_pk') || Ember.get(station, 'current_show.pk');
        var endtime = Ember.get(station, 'current_show.end');

        if (String(onAirPk) === pk) {
          return [true, endtime, stationSlug, stationName];
        }
      }

      return [false, false];
    }
  });
});