define('nypr-audio-services/components/stream-banner', ['exports', 'nypr-audio-services/templates/components/stream-banner'], function (exports, _streamBanner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['stream-banner'],
    layout: _streamBanner.default,

    activeStream: Ember.computed.reads('streams.firstObject'),
    style: Ember.computed('background', function () {
      return Ember.String.htmlSafe('background-image: url(' + this.get('background') + ');');
    })
  });
});