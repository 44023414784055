define('nypr-audio-services/components/stream-banner/dropdown', ['exports', 'nypr-audio-services/templates/components/stream-banner/dropdown'], function (exports, _dropdown) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _dropdown.default,
    tagName: ''
  });
});