define('nypr-audio-services/services/action-queue', ['exports', 'ember-hifi/utils/promise-race'], function (exports, _promiseRace) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    init: function init() {
      this.set('queues', {});
      this._super.apply(this, arguments);
    },


    /* eslint-disable */
    debug: function debug(message) {
      // TODO: set up a better nypr-audio-services debugger.
      // console.log(message);
    },

    /* eslint-enable */

    addAction: function addAction(thing, eventName, info, callback) {
      if (typeof thing === 'string') {
        thing = Ember.getOwner(this).lookup(thing);
      }

      (false && !(thing && thing.on && thing.trigger) && Ember.assert("passed in object is not Ember.Evented", thing && thing.on && thing.trigger));


      var queueName = this._queueName(thing, eventName);
      var queues = Ember.get(this, 'queues');
      var queue = Ember.get(queues, queueName) || [];
      queue.push(Object.assign(info, { callback: callback }));

      if (!Ember.get(queues, queueName)) {
        var _this = this;
        thing.on(eventName, function (data) {
          _this._runQueue(queueName, data);
        });
      }

      Ember.set(queues, queueName, queue);
      return { queueName: queueName };
    },
    _relayEvent: function _relayEvent(eventName, sound) {
      this.trigger(eventName, sound);
    },
    afterRun: function afterRun(thing, eventName, callback) {
      var queueName = this._queueName(thing, eventName);
      this.on('after:' + queueName, callback);
    },
    _queueName: function _queueName(thing, eventName) {
      var objectName = Ember.String.dasherize('' + thing);
      return objectName + ':' + eventName;
    },
    _queueExists: function _queueExists(queueName) {
      return !!Ember.get(this, 'queues.' + queueName);
    },
    _runQueue: function _runQueue(queueName) {
      var _this2 = this;

      var eventData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var queues = Ember.get(this, 'queues');
      var orderedQueue = Ember.A(Ember.get(queues, queueName) || []).sortBy('priority').slice(); // copy, bro

      this.debug('[action-queue] Trying action queue of ' + orderedQueue.length);
      var _this = this;
      var actionIndex = 0;
      var runPromise = new Ember.RSVP.Promise(function (resolve) {
        _promiseRace.default.start(orderedQueue, function (nextAction, returnSuccess, markFailure) {

          try {
            return Ember.RSVP.Promise.resolve(nextAction.callback(eventData)).then(function (result) {
              if (result) {
                _this.debug('[action-queue] [\u2713] ' + nextAction.name + ' @priority ' + nextAction.priority);
                returnSuccess(result);
              } else {
                _this.debug('[action-queue] [x] ' + nextAction.name + ' @priority ' + nextAction.priority);
                markFailure(nextAction);
              }
              actionIndex = actionIndex + 1;
            }).catch(function (e) {
              _this.debug('[action-queue] [!] ' + nextAction.name + ' @priority ' + nextAction.priority + ' ' + e);
              markFailure(e);
            });
          } catch (e) {
            _this.debug('[action-queue] [!] ' + nextAction.name + ' @priority ' + nextAction.priority + ' ' + e);
          }
        }).then(function (results) {
          return resolve(results);
        }).catch(function (results) {
          return resolve(results);
        });
      });

      runPromise.then(function (results) {
        orderedQueue.slice(actionIndex).forEach(function (nextAction) {
          _this2.debug('[action-queue] [ ] ' + nextAction.name + ' @priority ' + nextAction.priority);
        });
        _this2.trigger('after:' + queueName, results);
      });

      return runPromise;
    }
  });
});