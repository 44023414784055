define('nypr-ui/components/site-chrome/nav/links', ['exports', 'nypr-ui/templates/components/site-chrome/nav/links'], function (exports, _links) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _links.default,
    classNames: ['site-chrome__links']
  });
});