define('nypr-account-settings/components/nypr-account-forms/thanks', ['exports', 'nypr-account-settings/templates/components/nypr-account-forms/thanks'], function (exports, _thanks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _thanks.default,
    authAPI: null,
    resendUrl: Ember.computed('authAPI', function () {
      return Ember.get(this, 'authAPI') + '/v1/confirm/resend';
    })
  });
});