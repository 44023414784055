define('nypr-publisher-lib/adapters/story', ['exports', 'ember-get-config', 'ember-data', 'fetch', 'ember-fetch/mixins/adapter-fetch'], function (exports, _emberGetConfig, _emberData, _fetch, _adapterFetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DRAFT_TOKENS = ['content_type_id', 'object_id', 'token', '_'];

  exports.default = _emberData.default.JSONAPIAdapter.extend(_adapterFetch.default, {
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v3',
    pathForType: function pathForType() {
      return 'story';
    },
    buildURL: function buildURL() {
      return this._super.apply(this, arguments) + '/';
    },
    buildQuery: function buildQuery(_ref) {
      var _Object$keys;

      var _ref$adapterOptions = _ref.adapterOptions;
      _ref$adapterOptions = _ref$adapterOptions === undefined ? {} : _ref$adapterOptions;
      var _ref$adapterOptions$q = _ref$adapterOptions.queryParams,
          queryParams = _ref$adapterOptions$q === undefined ? [] : _ref$adapterOptions$q;

      var query = this._super.apply(this, arguments);
      if ((_Object$keys = Object.keys(queryParams)).includes.apply(_Object$keys, DRAFT_TOKENS)) {
        Object.keys(queryParams).forEach(function (k) {
          return query[k] = queryParams[k];
        });
      }
      return query;
    },
    urlForFindRecord: function urlForFindRecord(id) {
      if (/^\d+$/.test(id)) {
        return this.host + '/' + this.namespace + '/story-pk/' + id;
      } else {
        return this._super.apply(this, arguments);
      }
    },
    query: function query(store, type, _ref2) {
      var related = _ref2.related;

      if (related) {
        var url = this.host + '/' + this.namespace + '/story/related/?limit=' + related.limit + '&related=' + related.itemId;
        return (0, _fetch.default)(url, {
          mode: 'cors',
          credentials: 'include'
        }).then(function (r) {
          return r.json();
        });
      } else {
        return this._super.apply(this, arguments);
      }
    },
    ajaxOptions: function ajaxOptions(url, method, hash) {
      hash = hash || {};
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      return this._super(url, method, hash);
    }
  });
});