define('nypr-metrics/services/listen-analytics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TEN_MINUTES = 1000 * 60 * 10;

  exports.default = Ember.Service.extend({
    hifi: Ember.inject.service(),
    dataPipeline: Ember.inject.service(),
    dataLayer: Ember.inject.service('nypr-metrics/data-layer'),
    poll: Ember.inject.service(),
    currentSound: Ember.computed.reads('hifi.currentSound'),
    sessionPing: TEN_MINUTES,

    init: function init() {
      var _this = this;

      if (typeof document === 'undefined') {
        return; // don't run in fastboot
      }

      Ember.get(this, 'poll').addPoll({
        interval: Ember.get(this, 'sessionPing'),
        callback: Ember.run.bind(this, '_onPlayerPing'),
        label: 'playerPing'
      });

      if (!Ember.testing) {
        Ember.$(window).on('beforeunload', function () {
          if (_this.get('currentSound')) {
            _this._sendListenAction(_this.get('currentSound'), 'close');
          }
        });
      }

      this.set('listenActionQueue', Ember.A());
      this.set('dataLayerQueue', Ember.A());

      Ember.get(this, 'hifi').on('audio-played', Ember.run.bind(this, '_onAudioPlayed'));
      Ember.get(this, 'hifi').on('audio-paused', Ember.run.bind(this, '_onAudioPaused'));
      Ember.get(this, 'hifi').on('audio-ended', Ember.run.bind(this, '_onAudioEnded'));
      Ember.get(this, 'hifi').on('audio-position-will-change', Ember.run.bind(this, '_onAudioPositionWillChange'));
      Ember.get(this, 'hifi').on('audio-will-rewind', Ember.run.bind(this, '_onAudioWillRewind'));
      Ember.get(this, 'hifi').on('audio-will-fast-forward', Ember.run.bind(this, '_onAudioWillFastForward'));
      Ember.get(this, 'hifi').on('current-sound-interrupted', Ember.run.bind(this, '_onCurrentSoundInterrupted'));
      Ember.get(this, 'hifi').on('current-sound-changed', Ember.run.bind(this, '_onCurrentSoundChanged'));

      this._super.apply(this, arguments);
    },


    /* Monitoring hifi events and then logging analytics -------------------------
      ---------------------------------------------------------------------------*/

    /* Internal actions for logging events. Don't call these from the outside
      ----------------------------------------------------------------------------------*/

    _onCurrentSoundChanged: function _onCurrentSoundChanged(currentSound, previousSound) {
      var currentType = Ember.get(currentSound, 'metadata.contentModelType');

      var previousType = void 0;
      if (previousSound) {
        previousType = Ember.get(previousSound, 'metadata.contentModelType');
      }

      if (previousType === 'stream' && currentType === 'stream' && currentSound !== previousSound) {
        this._onStreamSwitch(previousSound, currentSound);
      }
    },
    _onCurrentSoundInterrupted: function _onCurrentSoundInterrupted(currentSound) {
      this._sendListenAction(currentSound, 'interrupt');
    },
    _onAudioPlayed: function _onAudioPlayed(sound) {
      var type = Ember.get(sound, 'metadata.contentModelType');

      if (type === 'story') {
        // on demand
        this._onDemandPlay(sound);
      } else if (type === 'stream') {
        this._onStreamPlay(sound);
      } else if (type === 'bumper') {
        Ember.get(this, 'dataLayer').push({
          event: 'Audio Bumper',
          'Audio Playback State': 'play'
        });
      }
    },
    _onAudioEnded: function _onAudioEnded(sound) {
      var contentType = Ember.get(sound, 'metadata.contentModelType');

      this._sendListenAction(sound, 'finish');
      this._pushToDataLayer({ sound: sound, type: 'end', bumper: contentType === 'bumper' });
    },
    _onAudioPaused: function _onAudioPaused(sound) {
      var contentType = Ember.get(sound, 'metadata.contentModelType');

      this._sendListenAction(sound, 'pause');
      this._pushToDataLayer({ sound: sound, type: 'pause', bumper: contentType === 'bumper' });
    },
    _onDemandPlay: function _onDemandPlay(sound) {
      var action = Ember.get(sound, 'hasPlayed') ? 'resume' : 'start';
      this._sendListenAction(sound, action);
      this._pushToDataLayer({ sound: sound, type: Ember.get(sound, 'hasPlayed') ? 'resume' : 'play' });
    },
    _onStreamPlay: function _onStreamPlay(sound) {
      var previousId = Ember.get(this, 'currentSound.metadata.contentId');
      var stream = Ember.get(sound, 'metadata.contentModel');
      var streamId = Ember.get(stream, 'id');

      if (streamId !== previousId) {
        this._pushToDataLayer({ sound: sound, type: 'play' });
      } else {
        this._pushToDataLayer({ sound: sound, type: 'resume' });
      }

      this._sendListenAction(sound, 'start');
    },
    _onStreamSwitch: function _onStreamSwitch(previousSound, currentSound) {
      var previousStream = previousSound.get('metadata.contentModel');
      var currentStream = currentSound.get('metadata.contentModel');
      var prevStreamName = Ember.get(previousStream, 'name');
      var currentStreamName = Ember.get(currentStream, 'name');

      Ember.get(this, 'dataLayer').push({
        event: 'Audio Stream Change',
        'Audio Previous Stream': prevStreamName,
        'Audio Stream Title': currentStreamName
      });
    },
    _onPlayerPing: function _onPlayerPing() {
      if (Ember.get(this, 'hifi.isPlaying')) {
        Ember.get(this, 'dataLayer').trigger('playerPing');
      }
    },
    _onAudioPositionWillChange: function _onAudioPositionWillChange(sound) {
      this._sendListenAction(sound, 'position');
    },
    _onAudioWillRewind: function _onAudioWillRewind(sound) {
      this._sendListenAction(sound, 'back_15');
    },
    _onAudioWillFastForward: function _onAudioWillFastForward(sound) {
      this._sendListenAction(sound, 'forward_15');
    },


    /*  Called externally -------------------------------------------------------
      --------------------------------------------------------------------------*/

    trackAllCodecFailures: function trackAllCodecFailures(failures, sound) {
      var _this2 = this;

      if (failures && failures.length) {
        failures.forEach(function (failed) {
          _this2._trackCodecFailure(failed, sound);
        });
      }
    },
    trackSoundFailure: function trackSoundFailure(_ref) {
      var _this3 = this;

      var message = _ref.message,
          failures = _ref.failures;

      this._pushToDataLayer({ type: 'audioError', errorType: "Sound Error", errorDetails: message });

      if (failures && failures.length) {
        failures.forEach(function (failed) {
          return _this3._trackCodecFailure(failed);
        });
      }
    },


    /* Tracking helpers ---------------------------------------------------------
      --------------------------------------------------------------------------*/

    _sendListenAction: function _sendListenAction(sound, type) {
      var position = sound.get('position');

      if (sound.get('metadata.analytics')) {
        var analyticsData = Object.assign({
          current_audio_position: position
        }, sound.get('metadata.analytics'));

        if (type === 'close') {
          // window is closing, no time to waste
          this.get('dataPipeline').reportListenAction(type, analyticsData);
        } else {
          var queue = Ember.get(this, 'listenActionQueue');
          queue.push({ sound: sound, type: type, analyticsData: analyticsData });
          Ember.run.debounce(this, '_flushListenActions', 100);
        }
      }
    },
    _flushListenActions: function _flushListenActions() {
      var _this4 = this;

      var queue = Ember.get(this, 'listenActionQueue');

      if (queue.length === 0) {
        return;
      }

      queue.forEach(function (_ref2, index) {
        var sound = _ref2.sound,
            type = _ref2.type,
            analyticsData = _ref2.analyticsData;

        if (type === 'pause' && queue.slice(index).find(function (info) {
          return info.sound.url === sound.url && info.type === 'finish';
        })) {
          // ignore pause if it's followed by a finish
        } else {
          _this4.get('dataPipeline').reportListenAction(type, analyticsData);
        }
      });

      if (!Ember.get(this, 'isDestroying')) {
        Ember.set(this, 'listenActionQueue', Ember.A());
      }
    },
    _trackCodecFailure: function _trackCodecFailure(_ref3, sound) {
      var connectionName = _ref3.connectionName,
          error = _ref3.error,
          url = _ref3.url;

      var action = 'Codec Failure | ' + connectionName;
      var label = 'reason: ' + error + ' | bad url: ' + url + ' | ' + (sound ? 'good url: ' + Ember.get(sound, 'url') : 'no successful url');

      this._pushToDataLayer({ type: 'audioError', errorType: action, errorDetails: label });
    },
    _pushToDataLayer: function _pushToDataLayer(data) {
      var queue = Ember.get(this, 'dataLayerQueue');
      queue.push(data);
      Ember.run.debounce(this, '_flushDataLayer', 100);
    },
    _flushDataLayer: function _flushDataLayer() {
      var _this5 = this;

      var queue = Ember.get(this, 'dataLayerQueue');

      if (queue.length === 0) {
        return;
      }
      queue.forEach(function (_ref4, index) {
        var type = _ref4.type,
            sound = _ref4.sound,
            errorType = _ref4.errorType,
            errorDetails = _ref4.errorDetails,
            bumper = _ref4.bumper;

        if (type === 'pause' && queue.slice(index).find(function (info) {
          return info.type === 'end';
        })) {
          return;
        } else if (type === 'audioError') {
          Ember.get(_this5, 'dataLayer').audioErrorTracking(errorType, errorDetails);
        } else if (bumper) {
          Ember.get(_this5, 'dataLayer').push({
            event: 'Audio Bumper',
            'Audio Playback State': type
          });
        } else {
          Ember.get(_this5, 'dataLayer').audioTracking(type, sound);
        }
      });

      if (!Ember.get(this, 'isDestroying')) {
        Ember.set(this, 'dataLayerQueue', Ember.A());
      }
    }
  });
});