define('nypr-ui/helpers/serial-links', ['exports', 'nypr-ui/helpers/list-separator', 'nypr-ui/helpers/link-or-text'], function (exports, _listSeparator, _linkOrText) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.serialLinks = serialLinks;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  //this helper is expecting an array of objects with text and url keys
  function serialLinks(_ref) {
    var _ref3 = _slicedToArray(_ref, 1),
        links = _ref3[0];

    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$unlinked = _ref2.unlinked,
        unlinked = _ref2$unlinked === undefined ? false : _ref2$unlinked,
        _ref2$textKey = _ref2.textKey,
        textKey = _ref2$textKey === undefined ? 'name' : _ref2$textKey,
        _ref2$urlKey = _ref2.urlKey,
        urlKey = _ref2$urlKey === undefined ? 'url' : _ref2$urlKey;

    var finalString = '';

    var separators = links.map(function (val, idx, arr) {
      return (0, _listSeparator.listSeparator)([arr, idx]);
    });

    links.forEach(function (link, idx) {
      var line = '';
      line += separators[idx];

      if (unlinked) {
        line += Ember.get(link, textKey);
      } else {
        line += (0, _linkOrText.linkOrText)([link], { textKey: textKey, urlKey: urlKey });
      }

      finalString += line;
    });

    return Ember.String.htmlSafe('' + finalString);
  }

  exports.default = Ember.Helper.helper(serialLinks);
});