define('ember-lifeline/index', ['exports', 'ember-lifeline/run-task', 'ember-lifeline/poll-task', 'ember-lifeline/debounce-task', 'ember-lifeline/dom-event-listeners', 'ember-lifeline/utils/disposable', 'ember-lifeline/mixins/run', 'ember-lifeline/mixins/dom', 'ember-lifeline/mixins/disposable'], function (exports, _runTask, _pollTask, _debounceTask, _domEventListeners, _disposable, _run, _dom, _disposable2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'runTask', {
    enumerable: true,
    get: function () {
      return _runTask.runTask;
    }
  });
  Object.defineProperty(exports, 'scheduleTask', {
    enumerable: true,
    get: function () {
      return _runTask.scheduleTask;
    }
  });
  Object.defineProperty(exports, 'throttleTask', {
    enumerable: true,
    get: function () {
      return _runTask.throttleTask;
    }
  });
  Object.defineProperty(exports, 'cancelTask', {
    enumerable: true,
    get: function () {
      return _runTask.cancelTask;
    }
  });
  Object.defineProperty(exports, '_setRegisteredTimers', {
    enumerable: true,
    get: function () {
      return _runTask._setRegisteredTimers;
    }
  });
  Object.defineProperty(exports, 'pollTask', {
    enumerable: true,
    get: function () {
      return _pollTask.pollTask;
    }
  });
  Object.defineProperty(exports, 'pollTaskFor', {
    enumerable: true,
    get: function () {
      return _pollTask.pollTaskFor;
    }
  });
  Object.defineProperty(exports, 'setShouldPoll', {
    enumerable: true,
    get: function () {
      return _pollTask.setShouldPoll;
    }
  });
  Object.defineProperty(exports, 'cancelPoll', {
    enumerable: true,
    get: function () {
      return _pollTask.cancelPoll;
    }
  });
  Object.defineProperty(exports, '_setRegisteredPollers', {
    enumerable: true,
    get: function () {
      return _pollTask._setRegisteredPollers;
    }
  });
  Object.defineProperty(exports, 'debounceTask', {
    enumerable: true,
    get: function () {
      return _debounceTask.debounceTask;
    }
  });
  Object.defineProperty(exports, 'cancelDebounce', {
    enumerable: true,
    get: function () {
      return _debounceTask.cancelDebounce;
    }
  });
  Object.defineProperty(exports, 'addEventListener', {
    enumerable: true,
    get: function () {
      return _domEventListeners.addEventListener;
    }
  });
  Object.defineProperty(exports, 'removeEventListener', {
    enumerable: true,
    get: function () {
      return _domEventListeners.removeEventListener;
    }
  });
  Object.defineProperty(exports, 'registerDisposable', {
    enumerable: true,
    get: function () {
      return _disposable.registerDisposable;
    }
  });
  Object.defineProperty(exports, 'runDisposables', {
    enumerable: true,
    get: function () {
      return _disposable.runDisposables;
    }
  });
  Object.defineProperty(exports, '_setRegisteredDisposables', {
    enumerable: true,
    get: function () {
      return _disposable._setRegisteredDisposables;
    }
  });
  Object.defineProperty(exports, 'ContextBoundTasksMixin', {
    enumerable: true,
    get: function () {
      return _run.default;
    }
  });
  Object.defineProperty(exports, 'ContextBoundEventListenersMixin', {
    enumerable: true,
    get: function () {
      return _dom.default;
    }
  });
  Object.defineProperty(exports, 'DisposableMixin', {
    enumerable: true,
    get: function () {
      return _disposable2.default;
    }
  });
});