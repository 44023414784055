define('nypr-publisher-lib/components/embed-button', ['exports', 'nypr-publisher-lib/templates/components/embed-button'], function (exports, _embedButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _embedButton.default,
    classNames: ['embed-button'],
    icon: 'embed',
    buttonText: 'Embed'
  });
});