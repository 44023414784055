define('nypr-publisher-lib/serializers/api-response', ['exports', 'ember-data', 'nypr-publisher-lib/serializers/story'], function (exports, _emberData, _story) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.serializeApiResponseRelationships = serializeApiResponseRelationships;
  function serializeApiResponseRelationships() {
    var relationships = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var included = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (Ember.get(relationships, 'tease-list.data.length')) {
      relationships['tease-list'].data.forEach(function (item) {
        if (item.type === 'story') {
          var _Ember$A$findBy = Ember.A(included).findBy('attributes.cms-pk', Number(item.id)),
              attributes = _Ember$A$findBy.attributes;

          item.id = attributes.slug;
          (0, _story.serializeStoryAttributes)(attributes);
        }
      });
    }
    if (Ember.get(relationships, 'story.data')) {
      var _Ember$A$findBy2 = Ember.A(included).findBy('attributes.cms-pk', Number(relationships.story.data.id)),
          attributes = _Ember$A$findBy2.attributes;

      relationships.story.data.id = attributes.slug;
      (0, _story.serializeStoryAttributes)(attributes);
    }
    return relationships;
  }

  exports.default = _emberData.default.JSONAPISerializer.extend({
    normalizeResponse: function normalizeResponse(store, typeClass, _ref, id, requestType) {
      var _ref$included = _ref.included,
          included = _ref$included === undefined ? [] : _ref$included,
          data = _ref.data;

      data.relationships = serializeApiResponseRelationships(data.relationships, included);
      return this._super(store, typeClass, { included: included, data: data }, id, requestType);
    }
  });
});