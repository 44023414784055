define('nypr-publisher-lib/helpers/truncate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncate = truncate;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  // Truncate a string but end with a full word.

  function truncate(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 3),
        text = _ref2[0],
        _ref2$ = _ref2[1],
        maxLength = _ref2$ === undefined ? 1000 : _ref2$,
        _ref2$2 = _ref2[2],
        ellipsis = _ref2$2 === undefined ? '' : _ref2$2;

    if (text.length <= maxLength) {
      return text;
    } else {
      var sliced = text.slice(0, maxLength);
      if (text.charAt(maxLength) !== ' ') {
        var lastSpace = sliced.lastIndexOf(' ');
        sliced = text.slice(0, lastSpace);
      }
      return '' + sliced + ellipsis;
    }
  }

  exports.default = Ember.Helper.helper(truncate);
});