define('nypr-audio-services/components/listen-button/embedded', ['exports', 'nypr-audio-services/components/listen-button'], function (exports, _listenButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function wnycEmbeddedAttr() {
    return Ember.computed('embeddedAttrs', {
      get: function get(k) {
        return Ember.get(this, 'embeddedAttrs.' + k);
      },
      set: function set(k, v) {
        return v;
      }
    });
  }

  exports.default = _listenButton.default.extend({
    itemPK: wnycEmbeddedAttr(),
    itemTitle: wnycEmbeddedAttr(),
    itemShow: wnycEmbeddedAttr(),
    duration: wnycEmbeddedAttr(),
    playContext: wnycEmbeddedAttr(),
    type: wnycEmbeddedAttr(),
    content: wnycEmbeddedAttr(),

    layout: Ember.HTMLBars.template({
      "id": "1bicnAb2",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[25,\"listen-button/ui\",null,[[\"type\"],[[20,[\"type\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[20,[\"content\"]]],null,{\"statements\":[[0,\"      \"],[4,\"if\",[[25,\"eq\",[[20,[\"type\"]],\"blue-boss\"],null]],null,{\"statements\":[[0,\"Listen\"],[4,\"if\",[[20,[\"duration\"]]],null,{\"statements\":[[0,\" \"],[6,\"span\"],[9,\"class\",\"text--small dimmed\"],[7],[1,[18,\"duration\"],false],[8]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[18,\"content\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
      "meta": {}
    })
  });
});