define('nypr-ui/components/nypr-ui/brand-header/header-left', ['exports', 'nypr-ui/templates/components/nypr-ui/brand-header/header-left'], function (exports, _headerLeft) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _headerLeft.default,

    classNames: ['nypr-brand-header__left']
  });
});