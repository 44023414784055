define('nypr-player/components/nypr-player/volume-control', ['exports', 'nypr-player/templates/components/nypr-player/volume-control'], function (exports, _volumeControl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _volumeControl.default,
    classNames: ['nypr-player-volume'],
    classNameBindings: ['isMuted'],
    volumeInPercent: Ember.computed('volume', 'isMuted', {
      get: function get() {
        if (Ember.get(this, 'isMuted')) {
          return 0;
        }
        return Ember.get(this, 'volume');
      },
      set: function set(k, v) {
        return v;
      }
    }),
    trackWidth: Ember.computed('volumeInPercent', function () {
      return Ember.String.htmlSafe('width: ' + Ember.get(this, 'volumeInPercent') + '%;');
    }),
    handlePosition: Ember.computed('volumeInPercent', function () {
      return Ember.String.htmlSafe('left : ' + Ember.get(this, 'volumeInPercent') + '%;');
    }),
    click: function click(_ref) {
      var target = _ref.target,
          pageX = _ref.pageX;

      this._setVolume(target, pageX);
    },
    mouseDown: function mouseDown(e) {
      if (window.getSelection && window.getSelection().removeAllRanges) {
        window.getSelection().removeAllRanges();
      }
      if (e.target.classList.contains('js-volume-slider-handle')) {
        this.$().on('mousemove', '.js-volume-slider', this.click.bind(this));
      }
    },
    mouseUp: function mouseUp() {
      this.$().off('mousemove', '.js-volume-slider');
    },
    mouseLeave: function mouseLeave() {
      this.$().off('mousemove', '.js-volume-slider');
    },
    _setVolume: function _setVolume(target, x) {
      if (!Ember.isEmpty(this.$(target).closest('.js-volume-slider'))) {
        var $controls = this.$('.js-volume-slider');
        var offset = $controls.offset();
        var leftLimit = offset.left;
        var rightLimit = offset.left + $controls.width();
        var p = void 0;
        if (x < leftLimit) {
          p = 0;
        } else if (x > rightLimit) {
          p = 1;
        } else {
          p = (x - leftLimit) / $controls.width();
        }
        Ember.get(this, 'setVolume')(p * 100);
      }
    },

    actions: {
      toggleMute: function toggleMute() {
        Ember.get(this, 'toggleMute')();
      }
    }
  });
});