define('nypr-ui/components/nypr-story/share-buttons', ['exports', 'nypr-ui/templates/components/nypr-story/share-buttons', 'nypr-ui/helpers/share-url'], function (exports, _shareButtons, _shareUrl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _shareButtons.default,
    classNames: ['btn-group'],
    actions: {
      popupShareWindow: function popupShareWindow(destination) {
        var shareText = this.get('story.title');
        var url = this.get('story.url');
        var via = this.get('story.twitterHandle') || this.get('via');
        var twitterHeadline = this.get('story.twitterHeadline') || shareText;

        var href = (0, _shareUrl.shareUrl)([destination, url, shareText, via, twitterHeadline]);
        var heights = {
          'Twitter': 443,
          'Facebook': 620
        };

        if (href) {
          var features = 'titlebar=no,close=no,width=600,height=' + heights[destination];
          window.open(href, '_blank', features);
        }
      }
    }
  });
});