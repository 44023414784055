define('nypr-publisher-lib/models/person', ['exports', 'ember-data', 'ember-data/attr', 'nypr-publisher-lib/utils/to-social-links'], function (exports, _emberData, _attr, _toSocialLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // BEGIN-SNIPPET person-fields
    slug: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    jobTitle: (0, _attr.default)('string'),
    bio: (0, _attr.default)('string'),
    lede: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    image: (0, _attr.default)(),
    social: (0, _attr.default)(),
    socialLinks: Ember.computed('social', function () {
      var social = Ember.get(this, 'social') || [];
      return (0, _toSocialLinks.default)(social);
    })
    // END-SNIPPET
  });
});