define('nypr-ui/components/nypr-card/button', ['exports', 'nypr-ui/templates/components/nypr-card/button'], function (exports, _button) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _button.default,
    tagName: 'button',
    classNames: ['nypr-card-button'],
    attributeBindings: ['data-category', 'data-action', 'data-label', 'data-value', 'data-test-selector'],
    'data-test-selector': 'nypr-card-button'
  });
});