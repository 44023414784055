define('nypr-ui/components/nypr-story/header-content/buttons', ['exports', 'nypr-ui/templates/components/nypr-story/header-content/buttons'], function (exports, _buttons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _buttons.default,
    classNames: ['btn-group', 'btn-group--withvr', 'btn-group--storyheader']
  });
});