define('nypr-player/components/nypr-player', ['exports', 'nypr-player/templates/components/nypr-player'], function (exports, _nyprPlayer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprPlayer.default,
    hifi: Ember.inject.service(),
    classNames: ['nypr-player'],
    classNameBindings: ['isAudiostream', 'freestanding:nypr-player__freestanding'],

    isReady: Ember.computed.reads('hifi.isReady'),
    isPlaying: Ember.computed.reads('hifi.isPlaying'),
    isLoading: Ember.computed.reads('hifi.isLoading'),
    isAudiostream: Ember.computed.reads('hifi.isStream'),

    currentTitle: null,

    playState: Ember.computed('isPlaying', 'isLoading', function () {
      if (Ember.get(this, 'isLoading')) {
        return 'is-loading';
      } else if (Ember.get(this, 'isPlaying')) {
        return 'is-playing';
      } else {
        return 'is-paused';
      }
    }),

    // EVENT HANDLERS
    onPlay: function onPlay() {},
    onPause: function onPause() {},
    onSetPosition: function onSetPosition() {},
    onRewind: function onRewind() {},
    onFastForward: function onFastForward() {},
    init: function init() {
      this._super.apply(this, arguments);

      var audioToLoad = this.get('sound');
      if (audioToLoad) {
        var hifi = Ember.get(this, 'hifi');
        hifi.load(audioToLoad).then(function (_ref) {
          var sound = _ref.sound;
          return hifi.setCurrentSound(sound);
        });
      }
    },


    actions: {
      playOrPause: function playOrPause() {
        if (Ember.get(this, 'isPlaying')) {
          this.onPause();
          Ember.get(this, 'hifi').togglePause();
        } else {
          this.onPlay();
          Ember.get(this, 'hifi').togglePause();
        }
      },
      setPosition: function setPosition(p) {
        this.onSetPosition();
        Ember.get(this, 'hifi').set('position', p * Ember.get(this, 'hifi.currentSound.duration'));
      },
      rewind: function rewind() {
        this.onRewind();
        Ember.get(this, 'hifi').rewind(15000);
      },
      fastForward: function fastForward() {
        this.onFastForward();
        Ember.get(this, 'hifi').fastForward(15000);
      },
      setVolume: function setVolume(vol) {
        Ember.get(this, 'hifi').set('volume', vol);
      },
      toggleMute: function toggleMute() {
        Ember.get(this, 'hifi').toggleMute();
      }
    }
  });
});