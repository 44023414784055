define('nypr-publisher-lib/models/playlist', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // BEGIN-SNIPPET playlist-fields
    stream: _emberData.default.belongsTo('stream'),
    items: _emberData.default.attr()
    // END-SNIPPET
  });
});