define('nypr-publisher-lib/components/about-page', ['exports', 'nypr-publisher-lib/templates/components/about-page'], function (exports, _aboutPage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _aboutPage.default,
    tagName: 'section',
    classNames: ['about-page'],
    hidePeople: false
  });
});