define('nypr-audio-services/components/player-notification', ['exports', 'nypr-audio-services/templates/components/player-notification'], function (exports, _playerNotification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _playerNotification.default,
    didAnimate: false,
    classNames: ['player-notification', 'js-player-notification']
  });
});