define('nypr-ads/components/expandable-responsive-leaderboard', ['exports', 'nypr-ads/templates/components/expandable-responsive-leaderboard'], function (exports, _expandableResponsiveLeaderboard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _expandableResponsiveLeaderboard.default,

    network: '6483581',
    unitSuffix: 'leaderboard',
    slot: Ember.computed('network', 'site', function () {
      return '/' + this.get('network') + '/leaderboard/' + this.get('site') + '_' + this.get('unitSuffix');
    })
  });
});