define('nypr-account-settings/components/nypr-accounts/password-card', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/password-card', 'nypr-account-settings/validations/nypr-accounts/password-change', 'ember-changeset-validations', 'ember-changeset'], function (exports, _passwordCard, _passwordChange, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _passwordCard.default,
    tagName: '',

    init: function init() {
      this._super.apply(this, arguments);
      this.changeset = new _emberChangeset.default({
        currentPassword: '',
        newPassword: ''
      }, (0, _emberChangesetValidations.default)(_passwordChange.default), _passwordChange.default);
    },


    actions: {
      save: function save(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            _this.changePassword(changeset).then(function () {
              changeset.rollback();
              _this.set('isEditing', false);
            }).catch(function (e) {
              if (e && Ember.get(e, 'errors.message')) {
                changeset.pushErrors('currentPassword', e.errors.message);
              } else {
                changeset.pushErrors('currentPassword', 'This password is incorrect.');
              }
            });
          } else {
            return changeset.get('errors');
          }
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.set('isEditing', false);
      },
      toggleEdit: function toggleEdit() {
        if (this.get('isEditing')) {
          this.send('rollback', this.get('changeset'));
        } else {
          this.set('isEditing', true);
        }
      }
    }
  });
});