define('nypr-publisher-lib/adapters/stream', ['exports', 'ember-get-config', 'ember-data', 'fetch'], function (exports, _emberGetConfig, _emberData, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var json = function json(r) {
    return r.json();
  };

  exports.default = _emberData.default.JSONAPIAdapter.extend({
    host: _emberGetConfig.default.publisherAPI,
    namespace: 'v1',
    findAll: function findAll() {
      var base = this.host + '/' + this.namespace;
      return Ember.RSVP.hash({
        streams: (0, _fetch.default)(base + '/list/streams/').then(json),
        whatsOn: (0, _fetch.default)(base + '/whats_on/').then(json)
      });
    },

    // BEGIN-SNIPPET stream-find-record
    findRecord: function findRecord(store, type, id /*, snapshot*/) {
      var base = this.host + '/' + this.namespace;
      return Ember.RSVP.hash({
        stream: (0, _fetch.default)(base + '/list/streams/' + id + '/').then(json),
        whatsOn: (0, _fetch.default)(base + '/whats_on/' + id + '/').then(json)
      });
    }
    // END-SNIPPET

  });
});