define('nypr-ui/components/nypr-brick-card-row', ['exports', 'nypr-ui/templates/components/nypr-brick-card-row'], function (exports, _nyprBrickCardRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _nyprBrickCardRow.default,
    classNames: ['brick-row', 'brick-row--cards']
  });
});