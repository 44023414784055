define('nypr-player/components/nypr-player/listen-button', ['exports', 'nypr-player/templates/components/nypr-player/listen-button'], function (exports, _listenButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _listenButton.default,
    hifi: Ember.inject.service(),
    disabled: Ember.computed.not('ready'),
    'aria-label': Ember.computed.readOnly('title'),
    'data-test-selector': 'listen-button',
    'data-action': Ember.computed('hifi.isStream', function () {
      return 'Clicked Play/Pause ' + (this.get('hifi.isStream') ? 'Stream' : 'On Demand') + ': persistent-player';
    }),

    tagName: 'button',
    classNames: ['nypr-player-button', 'mod-listen', 'gtm__click-tracking'],
    classNameBindings: ['isHovering', 'playState'],
    attributeBindings: ['aria-label', 'title', 'disabled', 'data-test-selector', 'data-action'],

    title: Ember.computed('currentTitle', function () {
      if (this.get('currentTitle')) {
        return 'Listen to ' + Ember.get(this, 'currentTitle');
      }
    }),

    mouseLeave: function mouseLeave() {
      Ember.set(this, 'isHovering', false);
    },
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'isHovering', true);
    }
  });
});