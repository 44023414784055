define('nypr-django-for-ember/instance-initializers/link-handler', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.normalizeHref = normalizeHref;
  exports.shouldHandleLink = shouldHandleLink;


  function findParent(target, selector) {
    while (target.parentNode) {
      if (!target.matches) {
        target.matches = target.msMatchesSelector; // ie 11 shim
      }
      if (target.matches(selector)) {
        return target;
      }
      target = target.parentNode;
    }
  } // based on https://github.com/intercom/ember-href-to/blob/master/app/instance-initializers/browser/ember-href-to.js
  function normalizeHref(node) {
    var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : location;

    var regex = /^https?:/i;
    var href = node.getAttribute('href') || '';
    var url = new URL(href, base).toString();
    var isExternal = false;
    var protocolFreeWebRoot = _emberGetConfig.default.webRoot.replace(regex, '');
    var protocolFreeUrl = url.replace(regex, '');

    if (href.startsWith('#') || href.startsWith('mailto:')) {
      return { url: url, href: href, isExternal: isExternal };
    } else if (protocolFreeUrl.startsWith(protocolFreeWebRoot)) {
      href = protocolFreeUrl.replace(protocolFreeWebRoot, '').replace(/^\//, '') || '/';
    } else if (!href.startsWith('/') || href.startsWith('//')) {
      // ^^^^ we shouldn't get here if href = current domain
      // and if it doesn't we want to open in a new tab
      href = '';
      isExternal = true;
    }
    return { url: url, href: href, isExternal: isExternal };
  }

  function shouldHandleLink(node) {
    var base = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : location;

    var _normalizeHref = normalizeHref(node, base),
        href = _normalizeHref.href;

    if (node.getAttribute('target') === '_blank') {
      // ignore links bound for a new window
      return false;
    } else if (Array.from(node.classList).includes('ember-view')) {
      // ignore clicks from ember components
      return false;
    } else if (node.getAttribute('data-ember-action')) {
      // ignore clicks from ember actions
      return false;
    } else if (node.getAttribute('data-skip-link-handler')) {
      // ignore previous handling methods and opens as a normal link
      return false;
    } else if (!href || href.startsWith('#') || href.startsWith('?') || href.startsWith('mailto:')) {
      // ignore href-less or otherwise implemented links
      return false;
    } else if (href.split('.').length > 1) {
      // ignore hrefs with a file extension
      return false;
    }
    return true;
  }

  function listener(router, instance, event) {
    var target = event.target;

    var anchorTag = findParent(target, 'a');

    if (!anchorTag) {
      return; // not a link click
    }

    var _normalizeHref2 = normalizeHref(anchorTag),
        url = _normalizeHref2.url,
        href = _normalizeHref2.href,
        isExternal = _normalizeHref2.isExternal;

    var validLink = shouldHandleLink(anchorTag);

    if (validLink) {

      if (url === location.toString()) {
        // could be a valid link, but we still want to short circuit if we'll
        // route to the current page
        return false;
      }

      var _router$recognize = router.recognize(href),
          routeName = _router$recognize.routeName,
          params = _router$recognize.params,
          queryParams = _router$recognize.queryParams;

      router.transitionTo(routeName, params, queryParams);
      event.preventDefault();
      return false;
    } else if (isExternal && !Ember.testing && !href.startsWith('mailto:')) {
      //^^^ don't add _blank to mailto links
      target.setAttribute('target', '_blank');
    }
    return true;
  }

  exports.default = {
    name: 'link-handler',
    initialize: function initialize(instance) {
      if (typeof FastBoot === 'undefined') {
        var router = instance.lookup('service:wnyc-routing');
        var boundListener = listener.bind(null, router, instance);
        var root = document.querySelector(instance.rootElement);

        root.addEventListener('click', boundListener);
      }
    }
  };
});