define('ember-body-class/instance-initializers/body-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(instance) {
    if (!Ember.$) {
      // No jquery in fastboot
      return;
    }

    var config;
    if (instance.resolveRegistration) {
      // Ember 2.1+
      // http://emberjs.com/blog/2015/08/16/ember-2-1-beta-released.html#toc_registry-and-container-reform
      config = instance.resolveRegistration('config:environment');
    } else {
      config = instance.container.lookupFactory('config:environment');
    }

    // Default to true when not set
    var _includeRouteName = true;
    if (config['ember-body-class'] && config['ember-body-class'].includeRouteName === false) {
      _includeRouteName = false;
    }

    Ember.Route.reopen({
      classNames: [],
      bodyClasses: [], _getRouteDepthClasses: function _getRouteDepthClasses() {
        var routeParts = this.get('routeName').split('.');
        var routeDepthClasses = routeParts.slice(0);
        var currentSelector = [];

        routeParts.forEach(function (part) {
          currentSelector.push(part);

          routeDepthClasses.push(currentSelector.join('-'));
        });

        return routeDepthClasses;
      },


      addClasses: Ember.on('activate', function () {
        var _this = this;

        var $body = Ember.$('body');
        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this.get(classes).forEach(function (klass) {
            $body.addClass(klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(function (depthClass) {
            $body.addClass(depthClass);
          });
        }
      }),

      removeClasses: Ember.on('deactivate', function () {
        var _this2 = this;

        var $body = Ember.$('body');
        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this2.get(classes).forEach(function (klass) {
            $body.removeClass(klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(function (depthClass) {
            $body.removeClass(depthClass);
          });
        }
      })
    });
  }

  exports.default = {
    name: 'body-class',
    initialize: initialize
  };
});