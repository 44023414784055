define('nypr-ui/components/nypr-story/producing-org', ['exports', 'nypr-ui/templates/components/nypr-story/producing-org', 'nypr-ui/mixins/image-loader'], function (exports, _producingOrg, _imageLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_imageLoader.default, {
    layout: _producingOrg.default,
    classNames: ['producing-org'],
    tagName: "span",
    url: Ember.computed.or('sourceUrl', 'org.url')
  });
});