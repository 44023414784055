define('nypr-player/components/nypr-player/queue-button', ['exports', 'nypr-player/templates/components/nypr-player/queue-button'], function (exports, _queueButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _queueButton.default,
    tagName: 'button',
    classNames: ['nypr-player-queue-button'],
    classNameBindings: ['isOpenModal:is-open', 'isFloating'],
    attributeBindings: ['data-category', 'data-action', 'data-label', 'data-value'],
    queueLength: null,
    showModal: null,
    closeModal: null,
    isOpenModal: false,
    isFloating: false,

    click: function click() {
      if (Ember.get(this, 'isOpenModal')) {
        Ember.get(this, 'closeModal')();
      } else {
        Ember.get(this, 'showModal')();
      }
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      var newLength = Ember.get(this, 'queueLength');
      var oldLength = Ember.get(this, '_oldLength') || 0;

      if (newLength <= oldLength) {
        return;
      }
      Ember.set(this, '_oldLength', newLength);

      this.$().addClass('animate').one('animationend', function () {
        return _this.$().removeClass('animate');
      });
    }
  });
});