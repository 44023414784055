define('nypr-audio-services/components/listen-button/ui', ['exports', 'nypr-audio-services/templates/components/listen-button/ui'], function (exports, _ui) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ICON_SUFFIXES = {
    'red-hollow': '-circle',
    'blue-hollow': '-circle',
    'white-hollow': '-circle',
    'white-hollow-small': '-hollow-small',
    'red-hollow-small': '-hollow-small'
  };

  exports.default = Ember.Component.extend({
    layout: _ui.default,
    classNames: ['listen-ui'],
    iconSuffix: Ember.computed('type', function () {
      return ICON_SUFFIXES[Ember.get(this, 'type')] || '';
    }),
    playIcon: Ember.computed('iconSuffix', function () {
      return 'play' + Ember.get(this, 'iconSuffix');
    }),
    pauseIcon: Ember.computed('iconSuffix', function () {
      return 'pause' + Ember.get(this, 'iconSuffix');
    })
  });
});