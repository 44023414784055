define('moment/lib', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var moment = void 0; /* globals self */

  var localeOutputPath = _emberGetConfig.default.moment && _emberGetConfig.default.moment.includeTimezone;

  if (typeof self.FastBoot === 'undefined') {
    moment = self.moment;
  } else if (localeOutputPath) {
    moment = self.FastBoot.require('moment-timezone');
  } else {
    moment = self.FastBoot.require('moment');
  }

  exports.default = moment;
});