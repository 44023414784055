define('nypr-ui/components/banner-with-close-button', ['exports', 'nypr-ui/templates/components/banner-with-close-button'], function (exports, _bannerWithCloseButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _bannerWithCloseButton.default,
    cookies: Ember.inject.service(),
    classNames: ['banner-with-close-button'],

    isBannerCookieSet: Ember.computed('closed', function () {
      var cookieService = this.get('cookies');
      return cookieService.read('hasSeenBanner');
    }),

    actions: {
      setBannerCookie: function setBannerCookie() {
        var cookieService = this.get('cookies');
        var future = new Date();
        future.setDate(future.getDate() + 30);
        cookieService.write('hasSeenBanner', true, { path: '/', expires: future });
        this.set('closed', true);
      },
      elementClicked: function elementClicked(_ref) {
        var target = _ref.target;

        if (target.tagName.toLowerCase() === 'a') {
          var cookieService = this.get('cookies');
          var future = new Date();
          future.setDate(future.getDate() + 30);
          cookieService.write('hasSeenBanner', true, { path: '/', expires: future });
          this.set('closed', true);
        }
      }
    }
  });
});