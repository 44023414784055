define('nypr-ui/components/nypr-ui/brand-header/header-right', ['exports', 'nypr-ui/templates/components/nypr-ui/brand-header/header-right'], function (exports, _headerRight) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _headerRight.default,

    classNames: ['nypr-brand-header__right']
  });
});