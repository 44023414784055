define('nypr-publisher-lib/components/active-html', ['exports', 'nypr-publisher-lib/templates/components/active-html'], function (exports, _activeHtml) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _activeHtml.default,
    html: "",
    unescapedHTML: Ember.computed('html', function () {
      return this.get('html').replace(/\\x3C\/script>/g, '</script>');
    }),
    didInsertElement: function didInsertElement() {
      var html = this.get('unescapedHTML');
      var element = this.$('.html');
      Ember.run.schedule('render', function () {
        element.append(html);
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var html = this.get('unescapedHTML');
      var element = this.$('.html');
      element.empty();
      Ember.run.schedule('render', function () {
        element.append(html);
      });
    }
  });
});