define('nypr-ads/components/dfp-ad', ['exports', 'googletag', 'nypr-ads/templates/components/dfp-ad'], function (exports, _googletag, _dfpAd) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function wnycEmbeddedAttr() {
    return Ember.computed('embeddedAttrs', {
      get: function get(k) {
        return Ember.get(this, 'embeddedAttrs.' + k);
      },
      set: function set(k, v) {
        return v;
      }
    });
  }

  exports.default = Ember.Component.extend({
    layout: _dfpAd.default,

    slot: wnycEmbeddedAttr(),
    target: wnycEmbeddedAttr(),
    sizes: wnycEmbeddedAttr(),
    mapping: wnycEmbeddedAttr(),
    slotClassNames: wnycEmbeddedAttr(),

    refresh: function refresh() {
      var ad = this.get('ad');
      _googletag.default.cmd.push(function () {
        return _googletag.default.pubads().refresh([ad]);
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var _getProperties = this.getProperties('slot', 'sizes', 'target', 'mapping'),
          slot = _getProperties.slot,
          sizes = _getProperties.sizes,
          target = _getProperties.target,
          mapping = _getProperties.mapping;

      _googletag.default.cmd.push(function () {
        var ad = _googletag.default.defineSlot(slot, sizes, target);
        if (!ad) {
          return;
        }
        _this.set('ad', ad);

        if (mapping) {
          var mqList = [];
          var sizeMapping = _googletag.default.sizeMapping();
          mapping.forEach(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 2),
                width = _ref2[0],
                unit = _ref2[1];

            sizeMapping.addSize([width, 0], unit);
            var mq = window.matchMedia('(min-width: ' + width + 'px)');
            mq.addListener(Ember.run.bind(_this, 'refresh'));
            mqList.push(mq);
          });
          ad.defineSizeMapping(sizeMapping.build());
          _this.set('mqList', mqList);
        }
        ad.addService(_googletag.default.pubads());
        Ember.run.schedule('afterRender', function () {
          return _googletag.default.display(target);
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var _getProperties2 = this.getProperties('mqList', 'ad'),
          mqList = _getProperties2.mqList,
          ad = _getProperties2.ad;

      if (mqList && mqList.length) {
        mqList.forEach(function (mq) {
          return mq.removeListener(Ember.run.bind(_this2, 'refresh'));
        });
      }
      _googletag.default.cmd.push(function () {
        return _googletag.default.destroySlots([ad]);
      });
    }
  });
});