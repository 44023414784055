define('nypr-account-settings/components/nypr-accounts/social-card', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/social-card'], function (exports, _socialCard) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _socialCard.default,
    tagName: ''
  });
});