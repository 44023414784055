define("nypr-player/breakpoints", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var medium = 801;

  exports.default = {
    nyprPlayerMediumAndUp: "(min-width : " + medium + "px)",
    nyprPlayerSmallOnly: "(max-width : " + (medium - 1) + "px)"
  };
});