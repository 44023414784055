define('nypr-ui/components/nypr-mini-chrome', ['exports', 'nypr-ui/templates/components/nypr-mini-chrome'], function (exports, _nyprMiniChrome) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['nypr-mini-chrome'],
    layout: _nyprMiniChrome.default
  });
});