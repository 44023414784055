define('nypr-audio-services/components/stream-banner/details', ['exports', 'nypr-audio-services/templates/components/stream-banner/details'], function (exports, _details) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _details.default,
    classNames: ['stream-banner-details']
  });
});