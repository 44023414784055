define('nypr-django-for-ember/models/django-page', ['exports', 'ember-data', 'nypr-django-for-ember/utils/compat-hooks', 'nypr-django-for-ember/utils/is-js'], function (exports, _emberData, _compatHooks, _isJs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var scriptCounter = 0;

  exports.default = _emberData.default.Model.extend({
    htmlParser: Ember.inject.service(),
    scriptLoader: Ember.inject.service(),
    inlineDocument: _emberData.default.attr(),
    text: _emberData.default.attr(),

    // BEGIN-SNIPPET django-page-document
    document: Ember.computed('inlineDocument', 'text', function () {
      var inlineDoc = this.get('inlineDocument');
      var text = this.get('text');
      if (inlineDoc) {
        return inlineDoc;
      } else {
        return this.get('htmlParser').parse(text);
      }
    }),
    // END-SNIPPET

    title: Ember.computed('document', function () {
      var titleTag = this.get('document').querySelector('title');
      if (titleTag) {
        return titleTag.textContent;
      }
    }),

    wnycChannel: Ember.computed('document', function () {
      var channel = this.get('document').querySelector('#wnyc-channel-jsonapi');
      var channelSerializer = this.store.serializerFor('channel');
      var channelModel = this.store.modelFor('channel');
      var id = this.get('id');
      var json = void 0;

      if (channel) {
        try {
          json = JSON.parse(channel.textContent);
        } catch (err) {
          // noop
        }
        if (json) {
          return this.store.push(channelSerializer.normalizeResponse(this.store, channelModel, json, id, 'findRecord'));
        }
      }
    }),

    embeddedEmberComponents: Ember.computed('pieces', function () {
      var doc = this.get('pieces.body');
      var instance = Ember.getOwner(this);
      return Array.from(doc.querySelectorAll('[data-ember-component]')).map(function (el) {
        var componentName = el.getAttribute('data-ember-component');
        if (!instance.lookup('component:' + componentName)) {
          console.warn(componentName + ' is not available in this app. Will not render embedded component ' + componentName + '.'); // eslint-disable-line
          return false;
        }
        var id = el.id;
        var args = void 0;
        try {
          args = JSON.parse(el.getAttribute('data-ember-args'));
          // TODO: ideally we'd decode the value of all the keys, but we'd need
          // to traverse an arbitrarily nested Object to do it right.
          // Since we know that only itemTitle is encoded server-side, we can just
          // target it here.
          args.itemTitle = args.itemTitle ? decodeURIComponent(args.itemTitle) : '';
          args.itemShow = args.itemShow ? decodeURIComponent(args.itemShow) : '';
          args.content = el.getAttribute('data-text-content');
        } catch (e) {
          if (!Ember.testing) {
            /* eslint-disable */
            console.warn('could not parse', el.getAttribute('data-ember-args'));
            /* eslint-enable */
          }
          args = { error: e };
        }
        return {
          id: id,
          componentName: componentName,
          args: args
        };
      }).filter(function (i) {
        return i !== false;
      });
    }),

    appendStyles: function appendStyles($element, styles) {
      var stylesLoaded = styles.map(function (s) {
        return styleLoaded(s);
      });
      $element.append(styles);
      return Ember.RSVP.allSettled(stylesLoaded);
    },


    pieces: Ember.computed('document', function () {
      return this._separateScripts();
    }),

    // BEGIN-SNIPPET separate-scripts
    _separateScripts: function _separateScripts() {
      var doc = this.get('document');
      var body = importNode(doc.querySelector('body'));
      var scripts = [];

      // First handle <script> in the <head>
      Array.from(doc.querySelectorAll('head script')).forEach(function (script) {
        if ((0, _isJs.default)(script)) {
          // Save for later evaluation
          scripts.push(script);
        } else {
          // Non-javascript script tags (templates, for example) get
          // moved from head to body so they will get added to our
          // rendered output (we only output the contents of body, since
          // it doesn't make sense to add a new head to the existing
          // page).
          body.appendChild(importNode(script));
        }
      });

      // Then handle <script> in <body>
      Array.from(body.querySelectorAll('script')).forEach(function (script) {
        if ((0, _isJs.default)(script)) {
          // Pull out of body and save for evaluation, leaving a marker
          // in the original spot in case we need to direct any
          // document.writes back there.
          var marker = document.createElement('script');
          marker.type = 'text/x-original-location';
          var id = scriptCounter++;
          marker.setAttribute('data-script-id', id);
          script.parentElement.insertBefore(marker, script);
          script.parentNode.removeChild(script);
          script.setAttribute('data-script-id', id);
          scripts.push(script);
        }
      });

      // Embedded Ember components require an ID for ember-wormwhole to use them as a
      // destination. They will be parsed out later when the `django-page` component renders them out.
      Array.from(body.querySelectorAll('[data-ember-component]')).forEach(function (el) {
        el.id = el.id || Ember.guidFor(el);
        el.setAttribute('data-text-content', el.textContent.trim());
        el.textContent = '';
      });

      // Styles, both inline and external, with their relative order maintained.
      var styles = Array.from(doc.querySelectorAll('style, link[rel=stylesheet]')).map(function (element) {
        return importNode(element);
      });

      // Remove the style tags from our imported body, because they will be handled separately.
      Array.from(body.querySelectorAll('style, link[rel=stylesheet]')).forEach(function (e) {
        return e.parentNode.removeChild(e);
      });

      body = (0, _compatHooks.beforeAppend)(body, this);

      return { body: body, scripts: scripts, styles: styles };
    },

    // END-SNIPPET

    // BEGIN-SNIPPET append-to
    appendTo: function appendTo($element) {
      var _this = this;

      var loader = this.get('scriptLoader');
      return this.appendStyles($element, this.get('pieces.styles')).finally(function () {
        Array.from(_this.get('pieces.body').childNodes).forEach(function (child) {
          $element[0].appendChild(importNode(child));
        });
        loader.load(_this.get('pieces.scripts'), $element[0]);
      });
    }
    // END-SNIPPET

  });


  // <link> tags do not reliably produce load events, particularly if
  // the CSS is already cached.
  function styleLoaded(element) {
    if (element.tagName !== 'LINK') {
      return Ember.RSVP.Promise.resolve();
    }
    return new Ember.RSVP.Promise(function (resolve, reject) {
      Ember.$(element).on('load', resolve).on('error', reject);
      var started = Date.now();
      var interval = setInterval(function () {
        if (Date.now() - started > 1000) {
          clearInterval(interval);
          reject();
        } else if (Array.from(document.styleSheets).find(function (s) {
          return s.ownerNode === element;
        })) {
          clearInterval(interval);
          resolve();
        }
      }, 20);
    });
  }

  function importNode(node) {
    var DEEP_COPY = true;
    return window.document.importNode(node, DEEP_COPY);
  }
});