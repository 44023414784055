define('nypr-audio-services/components/queue-button', ['exports', 'liquid-fire', 'nypr-audio-services/templates/components/queue-button'], function (exports, _liquidFire, _queueButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _queueButton.default,
    queue: Ember.inject.service('listen-queue'),
    dj: Ember.inject.service(),
    disabled: Ember.computed.not('dj.isReady'),
    'aria-label': Ember.computed.readOnly('title'),

    tagName: 'button',
    classNames: ['queue-button', 'gtm__click-tracking'],
    classNameBindings: ['type', 'isHovering'],
    attributeBindings: ['aria-label', 'title', 'disabled', 'data-state', 'data-action', 'data-label'],

    'data-action': Ember.computed('inQueue', function () {
      return Ember.get(this, 'inQueue') ? 'Remove Story from Queue' : 'Add Story to Queue';
    }),
    'data-label': Ember.computed(function () {
      return this.get('itemTitle') + ' | ' + this.get('itemShow') + ' | ' + this.get('playContext');
    }),
    inQueue: Ember.computed('queue.items.[]', {
      get: function get() {
        var queue = Ember.A(this.getWithDefault('queue.items', []));
        var inQueue = queue.findBy('id', Ember.get(this, 'itemPK'));
        return inQueue ? true : false;
      },
      set: function set(k, v) {
        return v;
      }
    }),
    'data-state': Ember.computed('inQueue', function () {
      return Ember.get(this, 'inQueue') ? 'in-queue' : null;
    }),

    title: Ember.computed('inQueue', function () {
      if (Ember.get(this, 'inQueue')) {
        return 'Remove ' + Ember.get(this, 'itemTitle') + ' from Your Queue';
      } else {
        return 'Add ' + Ember.get(this, 'itemTitle') + ' to Your Queue';
      }
    }),

    click: function click() {
      if (Ember.get(this, 'isErrored')) {
        return;
      }
      var itemPK = Ember.get(this, 'itemPK');
      var newWidth = void 0;
      var oldWidth = void 0;

      if (Ember.get(this, 'inQueue')) {
        Ember.get(this, 'queue').removeFromQueueById(itemPK);
        newWidth = 98;
        oldWidth = 106;
      } else {
        // TODO: addToQueue is potentially async, so we update UI synchronously,
        // but there must be a better/embery way
        Ember.set(this, 'inQueue', true);
        Ember.get(this, 'queue').addToQueueById(itemPK);
        newWidth = 106;
        oldWidth = 98;
      }
      if (Ember.get(this, 'type') !== 'small-blue') {
        (0, _liquidFire.animate)(this.$(), {
          width: [newWidth, oldWidth]
        }, {
          easing: [0.17, 0.89, 0.39, 1.25],
          duration: 200
        });
      }
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering', false);
    },
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    }
  });
});