define('nypr-ui/components/nypr-brick-row-blowout/column', ['exports', 'nypr-ui/templates/components/nypr-brick-row-blowout/column'], function (exports, _column) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _column.default,
    classNames: ['row__col'],
    classNameBindings: ['flipped']
  });
});