define('nypr-publisher-lib/models/appearance', ['exports', 'ember-data', 'ember-data/attr', 'nypr-publisher-lib/utils/to-social-links'], function (exports, _emberData, _attr, _toSocialLinks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // BEGIN-SNIPPET appearance-fields
    appearanceType: (0, _attr.default)('string'),
    person: (0, _attr.default)(),
    socialLinks: Ember.computed('person.social', function () {
      var social = Ember.get(this, 'person.social') || [];
      return (0, _toSocialLinks.default)(social);
    })
    // END-SNIPPET
  });
});