define('nypr-ui/components/nypr-story/show-sidebar-top', ['exports', 'nypr-ui/templates/components/nypr-story/show-sidebar-top'], function (exports, _showSidebarTop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _showSidebarTop.default,
    classNames: ['nypr-story-show-sidebar-top']
  });
});