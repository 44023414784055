define('nypr-account-settings/helpers/cents-or-whole-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.centsOrWholeNumber = centsOrWholeNumber;
  function centsOrWholeNumber(params /*, hash*/) {
    var number = params[0];
    return number.toFixed(2).split('.00')[0];
  }

  exports.default = Ember.Helper.helper(centsOrWholeNumber);
});