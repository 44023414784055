define('nypr-player/components/nypr-player/fast-forward-button', ['exports', 'nypr-player/templates/components/nypr-player/fast-forward-button'], function (exports, _fastForwardButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _fastForwardButton.default,
    tagName: 'button',
    classNames: ['nypr-player-button', 'mod-fastforward', 'gtm__click-tracking'],
    attributeBindings: ['aria-text', 'aria-labelledby', 'data-action'],
    'aria-text': "skip forward 15 seconds",
    'aria-labelledby': 'fastforward-label',
    'data-action': 'Clicked Skip Forward: persistent-player'
  });
});