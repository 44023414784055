define("ember-hifi/helpers/json-stringify", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function jsonStringify(params /*, hash*/) {
    if (!params || !params[0] || params[0] == undefined) {
      return "";
    }
    return JSON.stringify(params[0]);
  });
});