define('nypr-audio-services/components/nypr-player-integration/stream-info', ['exports', 'nypr-audio-services/templates/components/nypr-player-integration/stream-info'], function (exports, _streamInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _streamInfo.default,
    tagName: '',
    streamScheduleUrl: null,
    streamPlaylistUrl: null,
    streamName: null
  });
});