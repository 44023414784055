define('ember-body-class/mixins/body-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      loading: function loading() /* transition, route */{
        if (!Ember.$) {
          // No jquery in fastboot
          return true;
        }

        Ember.$('body').addClass("loading");

        this.router.on('didTransition', function () {
          Ember.$('body').removeClass("loading");
        });

        return true;
      },


      error: function error() /* error, transition */{
        if (!Ember.$) {
          // No jquery in fastboot
          return true;
        }

        Ember.$('body').addClass("error");

        this.router.on('didTransition', function () {
          Ember.$('body').removeClass("error");
        });

        return true;
      }
    }
  });
});