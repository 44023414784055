define('nypr-publisher-lib/components/image-caption', ['exports', 'nypr-publisher-lib/templates/components/image-caption'], function (exports, _imageCaption) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _imageCaption.default,
    tagName: 'span'
  });
});