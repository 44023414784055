define('nypr-auth/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        var user = this.get('store').queryRecord('user', { me: true });
        this.set('user', user);
        return user.catch(function () {
          // this access token has since been revoked
          _this.get('session').invalidate();
        });
      }
    }
  });
});