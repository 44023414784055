define('nypr-ui/components/nypr-ui/brand-header/menu-button', ['exports', 'nypr-ui/templates/components/nypr-ui/brand-header/menu-button'], function (exports, _menuButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    layout: _menuButton.default
  });
});