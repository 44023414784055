define('nypr-publisher-lib/serializers/playlist', ['exports', 'ember-data/serializers/json-api'], function (exports, _jsonApi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id) {
      return {
        data: {
          type: 'playlist',
          id: id,
          attributes: {
            items: payload.results.map(function (result) {
              var data = {};
              Object.keys(result).forEach(function (key) {
                data[Ember.String.camelize(key)] = result[key];
              });
              return data;
            })
          }
        }
      };
    }
  });
});