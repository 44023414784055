define('nypr-account-settings/components/nypr-account-forms/login', ['exports', 'nypr-account-settings/templates/components/nypr-account-forms/login', 'ember-changeset', 'nypr-account-settings/validations/nypr-accounts/login', 'nypr-account-settings/validations/nypr-accounts/custom-messages', 'ember-changeset-validations'], function (exports, _login, _emberChangeset, _login2, _customMessages, _emberChangesetValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _login.default,
    messages: _customMessages.default,
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    authAPI: null,
    showSocialLogin: false,
    resendUrl: Ember.computed('authAPI', function () {
      return Ember.get(this, 'authAPI') + '/v1/confirm/resend';
    }),
    allowedKeys: null,
    triedUnverifiedAccount: false,
    loginError: null,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'fields', {
        email: '',
        password: ''
      });
      Ember.set(this, 'allowedKeys', ['email', 'password']);
      Ember.set(this, 'changeset', new _emberChangeset.default(Ember.get(this, 'fields'), (0, _emberChangesetValidations.default)(_login2.default), _login2.default));
      Ember.get(this, 'changeset').validate();
    },
    click: function click() {
      Ember.get(this, 'flashMessages').clearMessages();
    },

    actions: {
      onSubmit: function onSubmit() {
        Ember.set(this, 'loginError', null);
        return this.authenticate(Ember.get(this, 'fields.email'), Ember.get(this, 'fields.password'));
      },
      onFailure: function onFailure(e) {
        if (e) {
          var errorCode = Ember.get(e, 'errors.code');
          switch (errorCode) {
            case 'AccountNotConfirmed':
              Ember.set(this, 'triedUnconfirmedAccount', true);
              break;
            case 'UnauthorizedAccess':
              Ember.set(this, 'loginError', _customMessages.default.genericLoginError);
              break;
            case 'UserNoPassword':
              Ember.set(this, 'loginError', _customMessages.default.noPasswordLoginError);
              break;
            case 'UserNotFoundException':
              Ember.set(this, 'loginError', _customMessages.default.emailNotFound(Ember.get(this, 'changeset.email')));
              break;
            default:
              this.applyErrorToChangeset(e.errors, Ember.get(this, 'changeset'));
          }
        }
      },
      loginWithFacebook: function loginWithFacebook() {
        var _this = this;

        var authOptions = {};
        if (Ember.get(this, 'emailWasDeclined')) {
          //we need to send this option with the fb login request to re-request the email permission
          authOptions = { authType: 'rerequest' };
          //reset emailWasDeclined on a new attempt
          Ember.set(this, 'emailWasDeclined', false);
        }
        Ember.get(this, 'session').authenticate('authenticator:torii', 'facebook-connect', authOptions).catch(function (e) {
          if (e && Ember.get(e, 'errors.code') === 'MissingAttributeException') {
            Ember.set(_this, 'emailWasDeclined', true);
            return _this.onFacebookLoginFailure(_customMessages.default.socialAuthNoEmail);
          } else {
            return _this.onFacebookLoginFailure(_customMessages.default.socialAuthCancelled);
          }
        });
      }
    },
    onFacebookLoginFailure: function onFacebookLoginFailure(message) {
      var _this2 = this;

      // because we clear flash messages when clicking this form,
      // wait a tick when we add one in an action that can
      // be triggered with a click
      Ember.run.next(function () {
        if (message) {
          _this2.get('flashMessages').add({
            message: message,
            type: 'warning'
          });
        }
      });
    },
    authenticate: function authenticate(email, password) {
      return Ember.get(this, 'session').authenticate('authenticator:nypr', email, password);
    },
    applyErrorToChangeset: function applyErrorToChangeset(error, changeset) {
      if (error && error.code) {
        changeset.rollback(); // so errors don't stack up
        if (error.message === 'User is disabled') {
          changeset.pushErrors('email', _customMessages.default.userDisabled);
        }
      }
    }
  });
});