define('nypr-publisher-lib/serializers/bucket', ['exports', 'ember-data/serializers/json-api', 'nypr-publisher-lib/serializers/story'], function (exports, _jsonApi, _story) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    extractId: function extractId(modelClass, _ref) {
      var attributes = _ref.attributes;
      return attributes.slug || "none";
    },
    // BEGIN-SNIPPET bucket-serializer
    normalizeResponse: function normalizeResponse(store, klass, payload /*, id, requestType*/) {
      // these are not actual ember models; need to camelize for consumption by components
      if (payload.data.attributes['bucket-items']) {
        payload.data.attributes['bucket-items'].forEach(function (item) {
          var attributes = Ember.getWithDefault(item, 'attributes', {});
          item.attributes = {};
          Object.keys(attributes).forEach(function (k) {
            return item.attributes[Ember.String.camelize(k)] = attributes[k];
          });

          if (item.type === 'story') {
            (0, _story.serializeStoryAttributes)(item.attributes);
          }
        });
      }
      return this._super.apply(this, arguments);
    }
    // END-SNIPPET

  });
});