define('nypr-ads/instance-initializers/google-ads', ['exports', 'googletag'], function (exports, _googletag) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(_ref) {
    var application = _ref.application;

    if (application.testing) {
      _googletag.default.apiReady = true;
    }

    _googletag.default.cmd.push(function () {
      _googletag.default.pubads().enableSingleRequest();
      _googletag.default.pubads().collapseEmptyDivs();
      _googletag.default.enableServices();
    });
  }

  exports.default = {
    name: 'google-ad',
    initialize: initialize
  };
});