define('nypr-publisher-lib/models/about-page', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // BEGIN-SNIPPET about-page-fields
    body: _emberData.default.attr('string'),
    people: _emberData.default.attr(),
    social: _emberData.default.attr(),
    escapedBody: Ember.computed('body', {
      get: function get() {
        var body = Ember.get(this, 'body');
        return body.replace(/\\x3C\/script>/g, '</script>');
      }
    })
    // END-SNIPPET
  });
});