define('nypr-account-settings/components/nypr-accounts/membership-card/current-status-detail', ['exports', 'nypr-account-settings/templates/components/nypr-accounts/membership-card/current-status-detail'], function (exports, _currentStatusDetail) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _currentStatusDetail.default,
    cookies: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('orderDateSorting', ["orderDate:desc"]);
    },
    willRender: function willRender() {
      var _this = this;

      // When the tab or window gains focus, re-run the hasMadeRecentPledge
      // computed function to check if, while the tab was unfocused, the user
      // completed a pledge.
      window.onfocus = function () {
        return _this.notifyPropertyChange("hasMadeRecentPledge");
      };

      this._super.apply(this, arguments);
    },
    willClearRender: function willClearRender() {
      // Clear the pledge noficiation function from onfocus when component is
      // destroyed.
      window.onfocus = null;
      this._super.apply(this, arguments);
    },

    pledgePrefix: Ember.computed(function () {
      var _Ember$getOwner$resol = Ember.getOwner(this).resolveRegistration("config:environment"),
          environment = _Ember$getOwner$resol.environment;

      return environment === "development" ? "pledge-demo" : "pledge3";
    }),
    orderDateSorting: null,
    sortedPledges: Ember.computed.sort("pledges", "orderDateSorting"),
    sortedSustainingPledges: Ember.computed.filterBy("sortedPledges", "orderType", "sustainer"),
    sortedOneTimePledges: Ember.computed.filterBy("sortedPledges", "orderType", "onetime"),
    mostRecentPledge: Ember.computed.reads("sortedPledges.firstObject"),
    activePledges: Ember.computed.filterBy("sortedPledges", "isActiveMember", true),
    activeSustainingPledges: Ember.computed("activePledges", function () {
      return this.get("activePledges").filter(function (pledge) {
        return Ember.get(pledge, "orderType") === "sustainer" && Ember.get(pledge, "isSustainer") === true;
      });
    }),
    activeOneTimePledges: Ember.computed.filterBy("activePledges", "orderType", "onetime"),
    isLapsedOneTimeMember: Ember.computed("sortedPledges", "activePledges", function () {
      return this.get("sortedPledges.length") > 0 && this.get("activePledges.length") === 0;
    }),
    isLapsedSustainer: Ember.computed("sortedSustainingPledges", "activeSustainingPledges", function () {
      return this.get("sortedSustainingPledges.length") > 0 && this.get("activeSustainingPledges.length") === 0;
    }),
    showPaymentHistory: false,
    mostRecentSustainingPledgesPerOrderCode: Ember.computed.uniqBy("activeSustainingPledges", "orderCode"),
    hasMadeRecentPledge: Ember.computed(function () {
      var cookieService = this.get('cookies');
      var cookies = cookieService.read();
      return cookies["recentPledge"] === "true";
    })
  });
});