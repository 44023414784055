define('ember-hifi/hifi-connections/base', ['exports', 'ember-hifi/utils/mime-types', 'ember-hifi/mixins/debug-logging'], function (exports, _mimeTypes, _debugLogging) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /**
  * This is the base sound object from which other sound objects are derived.
  *
  * @class BaseSound
  * @constructor
  */

  var ClassMethods = Ember.Mixin.create({
    setup: function setup(config) {
      this.config = config || {};
      this.debugEnabled = this.config.debugEnabled || false;
    },
    canPlay: function canPlay(url) {
      var usablePlatform = this.canUseConnection(url);
      if (!usablePlatform) {
        return false;
      }
      if (typeof url === 'string') {
        var mimeType = (0, _mimeTypes.getMimeType)(url);

        if (!mimeType) {
          /* eslint-disable no-console */
          console.warn('Could not determine mime type for ' + url);
          console.warn('Attempting to play urls with an unknown mime type can be bad for performance. See documentation for more info.');
          /* eslint-enable no-console */
          return true;
        } else {
          return this.canPlayMimeType(mimeType);
        }
      } else if (url.mimeType) {
        return this.canPlayMimeType(url.mimeType);
      } else {
        throw new Error('URL must be a string or object with a mimeType property');
      }
    },
    canUseConnection: function canUseConnection() {
      return true;
    },
    canPlayMimeType: function canPlayMimeType(mimeType) {
      var mimeTypeWhiteList = this.acceptMimeTypes;
      var mimeTypeBlackList = this.rejectMimeTypes;

      if (mimeTypeWhiteList) {
        return Ember.A(mimeTypeWhiteList).includes(mimeType);
      } else if (mimeTypeBlackList) {
        return !Ember.A(mimeTypeBlackList).includes(mimeType);
      } else {
        return true; // assume true
      }
    }
  });

  var Sound = Ember.Object.extend(Ember.Evented, _debugLogging.default, {
    debugName: Ember.computed('url', 'connectionName', function () {
      var parser = document.createElement('a');
      parser.href = this.get('url');
      var parts = parser.pathname.split('/');

      return this.get('connectionName') + ' (' + parts[parts.length - 1] + ')';
    }),

    pollInterval: 1000,
    timeout: 30000,

    hasPlayed: false,
    isLoading: false,
    isPlaying: false,
    isErrored: Ember.computed('error', function () {
      return !!this.get('error');
    }),
    error: null,

    isStream: Ember.computed.equal('duration', Infinity),
    isFastForwardable: Ember.computed.not('isStream'),
    isRewindable: Ember.computed.not('isStream'),

    duration: 0,
    percentLoaded: 0,

    // _position is updated by the service on the currently playing sound
    position: Ember.computed('_position', {
      get: function get() {
        return this._currentPosition();
      },
      set: function set(k, v) {
        this.trigger('audio-position-will-change', this, { currentPosition: this._currentPosition(), newPosition: v });

        return this._setPosition(v);
      }
    }),

    init: function init() {
      var _this = this;

      var _getProperties = this.getProperties('audioLoading', 'audioLoaded', 'audioReady', 'audioPlayed', 'audioPaused', 'audioEnded', 'audioLoadError'),
          audioLoading = _getProperties.audioLoading,
          audioLoaded = _getProperties.audioLoaded,
          audioReady = _getProperties.audioReady,
          audioPlayed = _getProperties.audioPlayed,
          audioPaused = _getProperties.audioPaused,
          audioEnded = _getProperties.audioEnded,
          audioLoadError = _getProperties.audioLoadError;

      this.set('isLoading', true);

      this.on('audio-played', function () {
        _this.set('hasPlayed', true);
        _this.set('isLoading', false);
        _this.set('isPlaying', true);
        _this.set('error', null);

        if (audioPlayed) {
          audioLoading(_this);
        }

        // recover lost isLoading update
        _this.notifyPropertyChange('isLoading');
      });

      this.on('audio-paused', function () {
        _this.set('isPlaying', false);
        if (audioPaused) {
          audioPaused(_this);
        }
      });
      this.on('audio-ended', function () {
        _this.set('isPlaying', false);
        if (audioEnded) {
          audioEnded(_this);
        }
      });

      this.on('audio-ready', function () {
        _this.set('duration', _this._audioDuration());
        if (audioReady) {
          audioReady(_this);
        }
      });

      this.on('audio-load-error', function (e) {
        if (_this.get('hasPlayed')) {
          _this.set('isLoading', false);
          _this.set('isPlaying', false);
        }
        _this.set('error', e);
        if (audioLoadError) {
          audioLoadError(_this);
        }
      });

      this.on('audio-loaded', function () {
        _this.set('isLoading', false);
        if (audioLoaded) {
          audioLoaded(_this);
        }
      });

      this.on('audio-loading', function (info) {
        if (info && info.percentLoaded) {
          _this.set('percentLoaded', info.percentLoaded);
        }
        if (audioLoading) {
          audioLoading(_this, info && info.percentLoaded);
        }
      });

      this._detectTimeouts();

      try {
        this.setup();
      } catch (e) {
        Ember.run.next(function () {
          _this.trigger('audio-load-error', 'Error in setup ' + e.message);
          if (audioLoadError) {
            audioLoadError(_this);
          }
        });
      }
    },

    _detectTimeouts: function _detectTimeouts() {
      var _this2 = this;

      if (this.get('timeout')) {
        var timeout = Ember.run.later(function () {
          _this2.trigger('audio-load-error', "request timed out");
        }, this.get('timeout'));

        this.on('audio-ready', function () {
          return Ember.run.cancel(timeout);
        });
        this.on('audio-load-error', function () {
          return Ember.run.cancel(timeout);
        });
      }
    },
    fastForward: function fastForward(duration) {
      var audioLength = this._audioDuration();
      var currentPosition = this._currentPosition();
      var newPosition = Math.min(currentPosition + duration, audioLength);

      this.trigger('audio-will-fast-forward', this, { currentPosition: currentPosition, newPosition: newPosition });
      this._setPosition(newPosition);
    },
    rewind: function rewind(duration) {
      var currentPosition = this._currentPosition();
      var newPosition = Math.max(currentPosition - duration, 0);
      this.trigger('audio-will-rewind', this, { currentPosition: currentPosition, newPosition: newPosition });
      this._setPosition(newPosition);
    },
    togglePause: function togglePause() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },
    setup: function setup() {
      (false && !(false) && Ember.assert("[ember-hifi] #setup interface not implemented", false));
    },
    _setVolume: function _setVolume() {
      (false && !(false) && Ember.assert("[ember-hifi] #_setVolume interface not implemented", false));
    },
    _audioDuration: function _audioDuration() {
      (false && !(false) && Ember.assert("[ember-hifi] #_audioDuration interface not implemented", false));
    },
    _currentPosition: function _currentPosition() {
      (false && !(false) && Ember.assert("[ember-hifi] #_currentPosition interface not implemented", false));
    },
    _setPosition: function _setPosition() {
      (false && !(false) && Ember.assert("[ember-hifi] #_setPosition interface not implemented", false));
    },
    play: function play() {
      (false && !(false) && Ember.assert("[ember-hifi] #play interface not implemented", false));
    },
    pause: function pause() {
      (false && !(false) && Ember.assert("[ember-hifi] #pause interface not implemented", false));
    },
    stop: function stop() {
      (false && !(false) && Ember.assert("[ember-hifi] #stop interface not implemented", false));
    },
    teardown: function teardown() {
      // optionally implemented in subclasses
    },
    willDestroy: function willDestroy() {
      this.teardown();
    }
  });

  Sound.reopenClass(ClassMethods);

  exports.default = Sound;
});