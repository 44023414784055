define('nypr-account-settings/components/nypr-account-modal/body', ['exports', 'nypr-account-settings/templates/components/nypr-account-modal/body'], function (exports, _body) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['nypr-account-modal-body'],
    layout: _body.default
  });
});