define('nypr-ui/components/nypr-story/audio-options', ['exports', 'nypr-ui/templates/components/nypr-story/audio-options'], function (exports, _audioOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _audioOptions.default,
    classNames: ['nypr-story-audio-options'],

    actions: {
      selectEmbedCode: function selectEmbedCode() {
        this.$("textarea").select();
      }
    }
  });
});