define('nypr-audio-services/services/bumper-state', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var actionQueue = Ember.get(this, 'actionQueue');
      var hifi = Ember.get(this, 'hifi');
      actionQueue.addAction(hifi, 'audio-ended', { priority: 4, name: 'bumper-play' }, Ember.run.bind(this, this.playBumperAction));
      actionQueue.addAction(hifi, 'audio-ended', { priority: 5, name: 'continuous-play' }, Ember.run.bind(this, this.autoplayAction));

      Ember.run.next(function () {
        _this.cacheStreamsInStore();
      });
    },


    actionQueue: Ember.inject.service(),
    queue: Ember.inject.service('listen-queue'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    hifi: Ember.inject.service(),
    dj: Ember.inject.service(),

    _autoplayPref: Ember.computed.readOnly('session.data.user-prefs-active-autoplay'),
    _autoplaySlug: Ember.computed.readOnly('session.data.user-prefs-active-stream.slug'),

    _autoplayPrefDefault: 'default_stream',
    _autoplaySlugDefault: 'wnyc-fm939',

    autoplayPref: Ember.computed.or('_autoplayPref', '_autoplayPrefDefault'),
    autoplaySlug: Ember.computed.or('_autoplaySlug', '_autoplaySlugDefault'),

    durationLoaded: Ember.computed.gt('hifi.currentSound.duration', 0),
    audioLoaded: Ember.computed.not('hifi.isLoading'),
    bumperLoaded: Ember.computed.and('durationLoaded', 'audioLoaded'),
    bumperPlaying: Ember.computed.and('bumperLoaded', 'bumperStarted'),
    bumperDidPlay: false,
    bumperStarted: false,
    revealNotificationBar: Ember.computed.or('bumperPlaying', 'bumperDidPlay'),
    autoplayEnabled: Ember.computed('autoplayPref', 'queue.items.length', function () {
      var _Ember$getProperties = Ember.getProperties(this, 'autoplayPref', 'queue'),
          autoplayPref = _Ember$getProperties.autoplayPref,
          queue = _Ember$getProperties.queue;
      // only play the bumper and the continuous play if autoplay pref is enabled
      // and option is stream, or if auto play pref is enabled, option is queue,
      // and there are items in the queue

      if (autoplayPref === 'queue') {
        return !!(queue && queue.nextItem()); // only enable if there are items left
      } else {
        return autoplayPref !== 'no_autoplay';
      }
    }),

    autoplayChoice: Ember.computed('autoplayPref', 'autoplaySlug', function () {
      var autoplaySlug = Ember.get(this, 'autoplaySlug');
      var autoplayPref = Ember.get(this, 'autoplayPref');

      if (autoplayPref === 'default_stream') {
        var stream = this.getStream(autoplaySlug);
        return Ember.get(stream, 'name');
      } else {
        return 'Queue';
      }
    }),

    playBumperAction: function playBumperAction(sound) {
      var playContext = Ember.get(sound, 'metadata.playContext');
      var autoPlayChoice = Ember.get(this, 'autoplayChoice');
      var currentAudioIsStream = Ember.get(this, 'hifi.currentSound.isStream');

      if (this.get('autoplayEnabled') && playContext !== 'audio-bumper' && !currentAudioIsStream) {
        var bumperUrl = this.getBumperUrl();
        var _playContext = 'audio-bumper';
        this.set('bumperStarted', true);
        var bumper = Ember.Object.create({ modelName: 'bumper', urls: bumperUrl });
        Ember.get(this, 'dj').play(bumper, { playContext: _playContext, autoPlayChoice: autoPlayChoice });
        return true;
      }
    },
    autoplayAction: function autoplayAction(sound) {
      var playContext = Ember.get(sound, 'metadata.playContext');
      var autoplayPref = Ember.get(this, 'autoplayPref');

      if (this.get('autoplayEnabled') && playContext === 'audio-bumper') {
        this.set('bumperDidPlay', true);
        var _playContext2 = autoplayPref === 'default_stream' ? 'continuous-stream' : 'continuous-queue';
        var nextItem = this.getAutoplayItem();
        Ember.get(this, 'dj').play(nextItem, { playContext: _playContext2 });
        return true;
      }
    },
    getAutoplayItem: function getAutoplayItem() {
      var autoplaySlug = Ember.get(this, 'autoplaySlug');
      var autoplayPref = Ember.get(this, 'autoplayPref');

      if (autoplayPref === 'default_stream') {
        return autoplaySlug;
      } else {
        var queue = Ember.get(this, 'queue');
        return queue.nextItem();
      }
    },
    getBumperUrl: function getBumperUrl() {
      var autoplaySlug = Ember.get(this, 'autoplaySlug');
      var autoplayPref = Ember.get(this, 'autoplayPref');

      var nextItem = void 0;
      if (autoplayPref === 'default_stream') {
        var stream = this.getStream(autoplaySlug);
        if (stream) {
          nextItem = Ember.get(stream, 'audioBumper');
          return nextItem;
        }
      }

      // default
      return _emberGetConfig.default.queueAudioBumperURL;
    },
    getStream: function getStream(slug) {
      return Ember.get(this, 'store').peekRecord('stream', slug);
    },
    cacheStreamsInStore: function cacheStreamsInStore() {
      Ember.get(this, 'store').findAll('stream');
    }
  });
});