define('nypr-ui/helpers/share-url', ['exports', 'nypr-ui/helpers/url-encode'], function (exports, _urlEncode) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.shareUrl = shareUrl;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function shareUrl(_ref) {
    var _ref2 = _slicedToArray(_ref, 5),
        destination = _ref2[0],
        shareUrl = _ref2[1],
        shareText = _ref2[2],
        via = _ref2[3],
        twitterHeadline = _ref2[4];

    if (typeof document === 'undefined') {
      return;
    }
    var a = document.createElement('a');
    a.href = shareUrl;

    var urls = {
      'Facebook': 'https://www.facebook.com/sharer/sharer.php?u=' + (0, _urlEncode.urlEncode)(a.href),
      'Twitter': 'https://twitter.com/intent/tweet?url=' + (0, _urlEncode.urlEncode)(a.href) + '&text=' + (0, _urlEncode.urlEncode)(twitterHeadline || shareText) + '&via=' + via,
      'Email': 'mailto:?subject=' + (0, _urlEncode.urlEncode)(shareText) + '&body=' + (0, _urlEncode.urlEncode)(a.href)
    };
    return urls[destination] || '';
  }

  exports.default = Ember.Helper.helper(shareUrl);
});