define('nypr-auth/authenticators/torii', ['exports', 'ember-simple-auth/authenticators/torii', 'fetch', 'ember-get-config', 'nypr-auth/helpers/decamelize-keys'], function (exports, _torii, _fetch, _emberGetConfig, _decamelizeKeys) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _torii.default.extend({
    torii: Ember.inject.service(),

    authenticate: function authenticate() {
      var _this = this,
          _arguments = arguments;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return _this._super.apply(_this, _arguments).then(function (data) {
          return Ember.RSVP.all([data, _this.getSession(data.provider, data.accessToken)]);
        }).then(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
              data = _ref2[0],
              response = _ref2[1];

          if (response) {
            if (response.ok) {
              resolve((0, _decamelizeKeys.decamelizeKeys)([data]));
            } else if (response.status < 500) {
              response.json().then(reject);
            } else {
              reject({ "errors": { "code": "serverError" } });
            }
          }
        }).catch(reject);
      });
    },
    getSession: function getSession(provider, accessToken) {
      return (0, _fetch.default)(_emberGetConfig.default.authAPI + '/v1/session', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + accessToken,
          'X-Provider': provider
        }
      });
    }
  });
});