define('nypr-audio-services/components/clear-history', ['exports', 'nypr-audio-services/templates/components/clear-history'], function (exports, _clearHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _clearHistory.default,
    listens: Ember.inject.service('listen-history'),

    isConfirming: false,

    classNames: ['clearhistory'],
    classNameBindings: ['isConfirming'],

    actions: {
      showConfirmation: function showConfirmation() {
        Ember.set(this, 'isConfirming', true);
      },
      cancel: function cancel() {
        Ember.set(this, 'isConfirming', false);
      },
      clearHistory: function clearHistory() {
        Ember.get(this, 'listens').clearHistory();
        Ember.set(this, 'isConfirming', false);
      }
    }
  });
});