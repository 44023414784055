define('nypr-ui/components/site-chrome/footer', ['exports', 'nypr-ui/templates/components/site-chrome/footer'], function (exports, _footer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _footer.default,
    tagName: 'footer',
    classNames: ['site-chrome__footer']
  });
});