define('nypr-publisher-lib/components/playlist-item', ['exports', 'nypr-publisher-lib/templates/components/playlist-item'], function (exports, _playlistItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _playlistItem.default,
    /* entry comes in as seconds, convert to ms */
    entryLengthMilliseconds: Ember.computed('item.catalogEntry.length', function () {
      return this.get('item.catalogEntry.length') * 1000;
    })

  });
});