define('nypr-ui/components/nypr-card/alert', ['exports', 'nypr-ui/templates/components/nypr-card/alert'], function (exports, _alert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _alert.default,
    classNames: ['nypr-card-alert']
  });
});