define('nypr-account-settings/validations/nypr-accounts/custom-messages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    passwordRequired: 'please enter a password',
    emailRequired: 'please enter your email',
    confirmEmailRequired: 'please enter a confirmation email',
    firstNameRequired: 'please enter your first name',
    firstNameMaxLength: 'your first name must be {max} characters or less',
    lastNameRequired: 'please enter your last name',
    lastNameMaxLength: 'your last name must be {max} characters or less',
    noLeadingSpace: 'cannot start with a space',
    noTrailingSpace: 'cannot end with a space',
    emailFormat: 'oops! looks like an invalid email address',
    emailConfirmation: "oops! this email doesn't match the other one",
    passwordRules: 'must be at least 8 characters and 1 number',
    userDisabled: 'This email is associated with a disabled account. If you would like to re-enable it, please contact listener services at <a href="http://www.wnyc.org/feedback" target="_blank">http://www.wnyc.org/feedback</a>.',
    genericLoginError: "There's an error logging in.</br><a href='/forgot'>Forget your password</a>? Need to <a href='/signup'>create an account</a>?",
    noPasswordLoginError: "It looks like you previously signed up through Facebook. You can log in via the Facebook button above or <a href='/signup'>sign up for an account</a> using this email.",
    emailNotFound: function emailNotFound(email) {
      return 'We can\'t find an account for the email ' + email + '.</br>Please note that email addresses are case sensitive.</br>Need to <a href=\'/signup\'>create an account</a>?';
    },
    publicHandleRequired: 'public handle cannot be blank',
    publicHandleExists: 'public handle already exists',
    emailExists: 'an account with this email address already exists',
    signupAccountExists: function signupAccountExists(email) {
      return 'An account already exists for the email ' + email + '.<br/> <a href="/login">Log in?</a> <a href="/forgot">Forgot password?</a>';
    },
    signupNoPassword: 'It looks like you previously signed up through Facebook. You can log in via the <a href="/login">Facebook button here</a>.',
    genericVerificationError: 'There was a problem verifying your email address. Please try again later.',
    socialAuthCancelled: "We're sorry, but we weren't able to log you in through Facebook.",
    socialAuthNoEmail: "Unfortunately, we can't authorize your account without permission to view your email address.",
    passwordIncorrect: "This password is incorrect.",
    badAccess: "There was a problem with your signup. Please refresh the page and try again."
  };
});