define('nypr-publisher-lib/models/story', ['exports', 'nypr-publisher-lib/models/bucket-item', 'ember-data', 'ember-get-config', 'moment'], function (exports, _bucketItem, _emberData, _emberGetConfig, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _bucketItem.default.extend({
    // BEGIN-SNIPPET story-fields
    appearances: attr(),
    audio: attr(),
    audioType: 'on_demand',
    audioAvailable: attr('boolean'),
    audioDurationReadable: attr('string'),
    audioEventually: attr('boolean'),
    audioMayDownload: attr('boolean'),
    audioMayEmbed: attr('boolean'),
    audioMayStream: attr('boolean'),
    audioShowOptions: attr('boolean'),
    channel: attr('string'),
    channelTitle: attr('string'),
    chunks: attr(),
    commentsCount: attr('number'),
    cmsPK: attr('string'),
    correctionText: attr('string'),
    newsdate: attr('string'),
    editLink: attr('string'),
    embedCode: attr('string'),
    enableComments: attr('boolean'),
    estimatedDuration: attr('number'),
    headers: attr(),
    headerDonateChunk: attr('string'),
    // TODO: make this a relationship when stories come in only over /api/v3
    // right now they're still consumed from HTML and as part of listing pages
    // imageMain: DS.belongsTo('image'),
    imageMain: attr(),
    itemType: attr('string'),
    itemTypeId: attr('number'),
    isLatest: attr('boolean'),
    largeTeaseLayout: attr('boolean'),
    playlist: attr(),
    podcastLinks: attr(),
    producingOrganizations: attr(),
    publishAt: attr('string'),
    publishStatus: attr('string'),
    segments: attr(),
    series: attr(),
    show: attr('string'),
    showTitle: attr('string'),
    showTease: attr('string'),
    showProducingOrgs: attr(),
    slug: attr('string'),
    slideshow: attr(),
    tease: attr('string'),
    template: attr('string'),
    tags: attr(),
    title: attr('string'),
    transcript: attr('string'),
    twitterHeadline: attr('string'),
    twitterHandle: attr('string'),
    url: attr('string'),
    video: attr('string'),
    body: attr('string'),
    bodyDjango: Ember.computed('body', function () {
      var text = Ember.get(this, 'body');
      return this.store.createRecord('django-page', { text: text });
    }),
    mainImageEligible: Ember.computed('template', 'imageMain', function () {
      var template = Ember.get(this, 'template');
      var imageWidth = Ember.get(this, 'imageMain.w');
      var imageDisplayFlag = Ember.get(this, 'imageMain.isDisplay');
      if (["story_video", "story_interactive", "story_noimage"].includes(template)) {
        return false;
      } else if (template === 'story_archives') {
        return true;
      } else if (imageWidth >= 800 && imageDisplayFlag === true) {
        return true;
      }
    }),
    videoTemplate: Ember.computed.equal('template', 'story_video'),
    interactiveTemplate: Ember.computed.equal('template', 'story_interactive'),
    flushHeader: Ember.computed.or('mainImageEligible', 'videoTemplate', 'segments'),
    escapedBody: Ember.computed('body', {
      get: function get() {
        var body = Ember.get(this, 'body');
        if (!body) {
          return '';
        }
        return body.replace(/\\x3C\/script>/g, '</script>');
      }
    }),
    futureArticle: Ember.computed('publishStatus', 'publishAt', function () {
      var publishStatus = this.get('publishStatus');
      var publishAt = this.get('publishAt');
      var currentDate = (0, _moment.default)();
      if (publishStatus === 'draft' || publishStatus === 'published' && currentDate.isBefore(publishAt)) {
        return true;
      }
      return false;
    }),
    pageChunks: Ember.computed('chunks', function () {
      //process the raw chunks into django-page records, if they are present
      var processedChunks = {};
      var chunksObj = Ember.get(this, 'chunks');
      for (var key in chunksObj) {
        var text = chunksObj[key];
        if (text) {
          var content = this.store.createRecord('django-page', { text: text });
          processedChunks[key] = content;
        }
      }
      return processedChunks;
    }),
    segmentedAudio: Ember.computed('audio', function () {
      return Array.isArray(Ember.get(this, 'audio'));
    }),
    commentSecurityURL: function commentSecurityURL(browserId) {
      var data = {
        content_type: 'cms.' + Ember.get(this, 'itemType'),
        object_pk: Ember.get(this, 'cmsPK'),
        bust_cache: Math.random()
      };
      if (browserId) {
        data.id = browserId;
      }
      return _emberGetConfig.default.adminRoot + '/comments/security_info/?' + Ember.$.param(data);
    },

    nprAnalyticsDimensions: attr(),
    allProducingOrgs: Ember.computed('producingOrganizations', 'showProducingOrgs', function () {
      var prodOrgs = Ember.get(this, 'producingOrganizations') || [];
      var showProdOrgs = Ember.get(this, 'showProducingOrgs') || [];
      var allProdOrgs = [];

      //combine show and story prod orgs into one array without dupes
      if (showProdOrgs.length) {
        for (var i in showProdOrgs) {
          var shared = false;
          for (var j in prodOrgs) {
            if (prodOrgs[j].name === showProdOrgs[i].name) {
              shared = true;
              break;
            }
          }
          if (!shared) {
            allProdOrgs.push(showProdOrgs[i]);
          }
        }
        allProdOrgs = allProdOrgs.concat(prodOrgs);
      } else {
        allProdOrgs = prodOrgs;
      }

      return allProdOrgs;
    }),

    // so Ember Simple Auth inludes a records ID when it saves
    toJSON: function toJSON() {
      var serializer = this.store.serializerFor('story');
      var snapshot = this._internalModel.createSnapshot();
      return serializer.serialize(snapshot, { includeId: true });
    },
    getNextSegment: function getNextSegment() {
      if (!Ember.get(this, 'segmentedAudio')) {
        return null;
      } else if (!this.hasNextSegment()) {
        // wrap around
        this.resetSegments();
        return Ember.get(this, 'audio')[0];
      } else {
        return Ember.get(this, 'audio')[this.incrementProperty('_currentSegment')];
      }
    },
    getCurrentSegment: function getCurrentSegment() {
      if (!Ember.get(this, 'segmentedAudio')) {
        return Ember.get(this, 'audio');
      } else {
        return Ember.get(this, 'audio')[Ember.get(this, '_currentSegment') || 0];
      }
    },
    hasNextSegment: function hasNextSegment() {
      if (!Ember.get(this, 'segmentedAudio')) {
        return false;
      } else {
        return this.getCurrentSegment() !== Ember.get(this, 'audio')[Ember.get(this, 'audio').length - 1];
      }
    },
    resetSegments: function resetSegments() {
      if (!Ember.get(this, 'segmentedAudio')) {
        return Ember.get(this, 'audio');
      } else {
        this._currentSegment = 0;
        return this.getCurrentSegment();
      }
    },
    forListenAction: function forListenAction() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      return Ember.RSVP.Promise.resolve(Object.assign({
        audio_type: 'on_demand',
        cms_id: Ember.get(this, 'cmsPK'),
        item_type: Ember.get(this, 'itemType') // episode, article, segment
      }, data));
    },
    forDfp: function forDfp() {
      return Ember.getProperties(this, 'tags', 'show', 'channel', 'series');
    }
    // END-SNIPPET

  });
});